export default {
    "name": "UserConfigurationData",
    "kind": "HoudiniQuery",
    "hash": "495c8f0d60f24b560cd66df3dea0c944102f533e3e1ac0da1714e89eaf2fec66",

    "raw": `query UserConfigurationData($userAccountsFilter: UserAccountFilter) {
  userAccounts(filter: $userAccountsFilter) {
    id
    name
    status
    lastLoginDate: lastAccess
    authorizedStores {
      id
    }
  }
  permissions {
    id
    displayName
    description
    codeName
    category
  }
  groups {
    groupId: id
    name
    groupPermissions: permissions {
      permissionId: id
      value: level
      id
    }
    id
  }
  stores {
    id
    name
    code
    phone
    addressService {
      service
      apiKey
    }
    taxService {
      apiKey
      service
    }
  }
  settingValues {
    security {
      administratorsCanSetOtherUsersPasswords
    }
  }
  wageRates {
    id
    name
    overTimeHours
    overTimeMultiplier
    overTimeType
    startDate
    wage
    code
    glCategoryId
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts(filter: $userAccountsFilter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastLoginDate": {
                            "type": "DateTime",
                            "keyRaw": "lastLoginDate",
                            "nullable": true,
                            "visible": true
                        },

                        "authorizedStores": {
                            "type": "Store",
                            "keyRaw": "authorizedStores",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "permissions": {
                "type": "Permission",
                "keyRaw": "permissions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "displayName": {
                            "type": "String",
                            "keyRaw": "displayName",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "codeName": {
                            "type": "String",
                            "keyRaw": "codeName",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "Group",
                "keyRaw": "groups",

                "selection": {
                    "fields": {
                        "groupId": {
                            "type": "UInt",
                            "keyRaw": "groupId",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        },

                        "groupPermissions": {
                            "type": "GroupPermission",
                            "keyRaw": "groupPermissions",

                            "selection": {
                                "fields": {
                                    "permissionId": {
                                        "type": "UInt",
                                        "keyRaw": "permissionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionLevel",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "stores": {
                "type": "Store",
                "keyRaw": "stores",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "phone": {
                            "type": "String",
                            "keyRaw": "phone",
                            "nullable": true,
                            "visible": true
                        },

                        "addressService": {
                            "type": "AddressService",
                            "keyRaw": "addressService",

                            "selection": {
                                "fields": {
                                    "service": {
                                        "type": "AddressServiceEnum",
                                        "keyRaw": "service",
                                        "visible": true
                                    },

                                    "apiKey": {
                                        "type": "String",
                                        "keyRaw": "apiKey",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "taxService": {
                            "type": "TaxService",
                            "keyRaw": "taxService",

                            "selection": {
                                "fields": {
                                    "apiKey": {
                                        "type": "String",
                                        "keyRaw": "apiKey",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "TaxServiceEnum",
                                        "keyRaw": "service",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "security": {
                            "type": "SecuritySettingValues",
                            "keyRaw": "security",

                            "selection": {
                                "fields": {
                                    "administratorsCanSetOtherUsersPasswords": {
                                        "type": "Boolean",
                                        "keyRaw": "administratorsCanSetOtherUsersPasswords",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "wageRates": {
                "type": "WageRate",
                "keyRaw": "wageRates",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "overTimeHours": {
                            "type": "Decimal2",
                            "keyRaw": "overTimeHours",
                            "visible": true
                        },

                        "overTimeMultiplier": {
                            "type": "Decimal2",
                            "keyRaw": "overTimeMultiplier",
                            "visible": true
                        },

                        "overTimeType": {
                            "type": "OverTimeTypeEnum",
                            "keyRaw": "overTimeType",
                            "visible": true
                        },

                        "startDate": {
                            "type": "Date",
                            "keyRaw": "startDate",
                            "nullable": true,
                            "visible": true
                        },

                        "wage": {
                            "type": "Decimal2",
                            "keyRaw": "wage",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "glCategoryId": {
                            "type": "UInt",
                            "keyRaw": "glCategoryId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountsFilter": "UserAccountFilter"
        },

        "types": {
            "UserAccountFilter": {
                "authorizedStores": "UInt",
                "isSalesPerson": "Boolean",
                "name": "String",
                "status": "UserAccountStatusEnum"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ad2fc2d63d473a39c0cbb5fb0dd51f5572ea70184994a9705abdbf86e71b38ff";