<script lang="ts">
	import type { Merge } from 'type-fest'
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { GLCategories, SalesOrderTerms, States, Vendor, VendorAttachment, VendorTypes, VendorPaymentMethods, PurchaseAgents } from './vendor'
	import type { NewVendor, VendorUpdate, VendorAttachmentsUpdate, NewVendorAddress, VendorAddressUpdate } from '$houdini'
	import type { AlternateAddress } from 'components/BillingAndAlternateAddress.svelte'

	import { getContext, onMount, setContext, type ComponentProps } from 'svelte'
	import userLocalWritable from 'stores/user-local-writable'
	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import { hasPermission } from 'utility/permission'
	import session from 'stores/session'
	import { graphql } from '$houdini'

	import Address from './Address.svelte'
	import Additional from './Additional.svelte'
	import { Attachments } from '@isoftdata/svelte-attachments'
	import Button from '@isoftdata/svelte-button'
	import Dropdown from '@isoftdata/svelte-dropdown'
	import NavTabBar from '@isoftdata/svelte-nav-tab-bar'
	import { DropdownCheckboxItem } from '@isoftdata/svelte-context-menu'
	import StateCardHeader from 'components/StateCardHeader.svelte'
	import SearchModal from 'components/SearchModal.svelte'
	import PurchaseOrders from './PurchaseOrders.svelte'

	type DeepNonNullRequired<T> = Required<T> & {
		[K in keyof Required<T>]: Exclude<Required<T>[K], null>
	}
	type AttachmentsForSave = DeepNonNullRequired<VendorAttachmentsUpdate>

	export let tab: string
	export let asr: SvelteAsr
	export let vendor: Vendor
	export let states: States = []
	export let glCategories: GLCategories = []
	export let vendorTypeList: VendorTypes = []
	export let salesOrderTerms: SalesOrderTerms = []
	export let attachments: Array<VendorAttachment> = []
	export let vendorPaymentMethods: VendorPaymentMethods = []
	export let purchaseAgents: PurchaseAgents = []

	let isSaving: boolean = false
	let vendorChanged: boolean = false
	let vendorSearchModal: SearchModal | undefined = undefined
	// the permission will limit user to add or edit the vendor
	let viewOnlyMode: boolean = !hasPermission('VENDORS_CAN_EDIT_VENDORS')

	const alternateAddressCrudStore = makeCrudStore<AlternateAddress, 'uuid'>('uuid')
	setContext('alternateAddressCrudStore', alternateAddressCrudStore)

	// TODO: This should get a user setting, but it doesn't have one right now, so I'm just doing this for the time being.
	const clearScreenOnSave = userLocalWritable($session.userAccountId, 'clear-vendor-screen-on-save', false)

	const mediator = getContext<Mediator>('mediator')
	const tabs: ComponentProps<NavTabBar>['tabs'] = [
		{
			name: 'address',
			title: 'Address Info',
		},
		{
			name: 'additional',
			title: 'Additional Info',
		},
		{
			name: 'attachments',
			title: 'Attachments',
		},
		{
			name: 'purchaseOrders',
			title: 'Purchase Orders',
		},
	]

	$: missingRequiredFieldsList = getMissingRequiredFields(vendor)

	export function canLeaveState(): boolean {
		// If the vendor has no changes, we can leave the state
		return !vendorChanged
	}

	function getMissingRequiredFields(vendor: Vendor) {
		const missingFields: string[] = []

		const requiredFieldsToCheck: Array<{
			name: string
			missingRequiredField: boolean | null | undefined
		}> = [
			{
				name: 'Company Name',
				missingRequiredField: !vendor.companyName && !vendor.contactName,
			},
			{
				name: 'Contact Name',
				missingRequiredField: !vendor.contactName && !vendor.companyName,
			},
		]

		for (const field of requiredFieldsToCheck) {
			if (field.missingRequiredField) {
				missingFields.push(field.name)
			}
		}

		return missingFields.join(', ')
	}

	function formatAlternateAddressForCreate(address: AlternateAddress): NewVendorAddress {
		return {
			city: address.addressData.city,
			company: address.addressData.companyName,
			contact: address.addressData.contactName,
			country: address.addressData.country,
			department: address.addressData.department,
			email: address.addressData.email,
			fax: address.addressData.faxNumber,
			primary: address.primary,
			title: address.label,
			mobile: address.addressData.mobilePhoneNumber,
			notes: address.addressData.notes,
			phone: address.addressData.phoneNumber,
			state: address.addressData.state,
			street: address.addressData.street,
			zip: address.addressData.zip,
			validated: address.addressData.validated,
		}
	}

	function getGenericVendorForSave(theVendor = vendor): Omit<VendorUpdate, 'id' | 'companyName' | 'contactName'> {
		const optionValuesToSave = theVendor.customFields
			.filter(customField => customField.value)
			.map(customField => ({
				optionId: customField.option.id,
				value: customField.value,
			}))

		return {
			accountNumber: theVendor.accountNumber,
			active: theVendor.active,
			addresses: {
				create: alternateAddressCrudStore.createdValues.reduce((acc: NewVendorAddress[], address) => {
					if (address.label) {
						acc.push(formatAlternateAddressForCreate(address))
					}
					return acc
				}, []),
				remove: alternateAddressCrudStore.deletedValues.reduce((acc: number[], address) => {
					if (address.id) {
						acc.push(address.id)
					}
					return acc
				}, []),
				update: alternateAddressCrudStore.updatedValues.reduce((acc: VendorAddressUpdate[], address) => {
					if (address.id) {
						acc.push({
							id: address.id,
							...formatAlternateAddressForCreate(address),
						})
					}
					return acc
				}, []),
			},
			attachments: attachments.reduce(
				({ create, remove, update }: AttachmentsForSave, attachment) => {
					if (attachment.action === 'CREATE' && attachment.File) {
						create.push({
							file: attachment.File,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'CREATE' && attachment.fileId) {
						create.push({
							fileId: attachment.fileId,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'UPDATE' && attachment.vendorFileId) {
						update.push({
							id: attachment.vendorFileId,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'DELETE' && attachment.vendorFileId) {
						remove.push(attachment.vendorFileId)
					}

					return { create, remove, update }
				},
				{ create: [], remove: [], update: [] },
			),
			billingAddress: {
				address: theVendor.billingAddress.address1,
				city: theVendor.billingAddress.city,
				state: theVendor.billingAddress.state,
				zip: theVendor.billingAddress.zip,
				country: theVendor.billingAddress.country,
				validated: theVendor.billingAddressValidated,
			},
			code: theVendor.code,
			dunsNumber: theVendor.dunsNumber,
			email: theVendor.email,
			faxNumber: theVendor.faxNumber,
			glCategoryId: theVendor.defaultGLCategory?.id,
			mobileNumber: theVendor.mobilePhoneNumber,
			notes: theVendor.notes,
			optionValues: optionValuesToSave,
			phoneNumber: theVendor.phoneNumber,
			paymentMethodId: theVendor.defaultPaymentMethod?.id,
			purchaseAgentId: theVendor.purchaseAgent?.id,
			type: theVendor.type,
			termId: theVendor.defaultTerm?.id,
			webAddress: theVendor.webAddress,
		}
	}

	function getNewVendorForSave(vendorToSave: Vendor): NewVendor {
		const vendorForSave = getGenericVendorForSave(vendorToSave)

		return {
			...vendorForSave,
			companyName: vendorToSave.companyName,
			contactName: vendorToSave.contactName,
			attachments: vendorForSave.attachments?.create,
			accountNumber: vendorToSave.accountNumber,
			orderingGroup: vendorToSave.orderingGroup,
			addresses: vendorForSave.addresses?.create,
		}
	}

	function getUpdateVendorForSave(vendorToSave: Merge<Vendor, { id: number }>): VendorUpdate {
		const vendorForSave = getGenericVendorForSave(vendorToSave)

		return {
			...vendorForSave,
			id: vendorToSave.id,
			companyName: vendorToSave.companyName,
			contactName: vendorToSave.contactName,
		}
	}

	async function saveVendor(saveAndNew = $clearScreenOnSave) {
		isSaving = true
		const vendorId = vendor.id
		try {
			let savedVendor: { id: number } | null = null
			if (vendorId) {
				const vendorToSave = {
					...vendor,
					id: vendorId,
				}
				const vendorInputToSave = getUpdateVendorForSave(vendorToSave)
				const { data } = await updateVendorMutation.mutate({ input: vendorInputToSave })
				savedVendor = data?.updateVendor ?? null
			} else {
				const vendorInputToSave = getNewVendorForSave(vendor)
				const { data } = await createVendorMutation.mutate({ input: vendorInputToSave })
				savedVendor = data?.createVendor ?? null
			}

			if (!savedVendor) {
				throw new Error('No data returned from the server')
			}

			mediator.publish('showMessage', {
				type: 'success',
				heading: 'Saved!',
				message: 'Vendor has been saved successfully',
				time: 10,
			})
			vendorChanged = false
			asr.go(
				null,
				{
					vendorId: saveAndNew ? null : savedVendor.id,
					lastResetTime: Date.now(),
					tab,
				},
				{ replace: true },
			)
		} catch (error: any) {
			console.error(error)
			return mediator.publish('showMessage', {
				type: 'danger',
				heading: 'Failed To Save Vendor',
				message: error.message,
				time: false,
			})
		} finally {
			isSaving = false
		}
	}

	const createVendorMutation = graphql(`
		mutation CreateVendor($input: NewVendor!) {
			createVendor(input: $input) {
				id
			}
		}
	`)

	const updateVendorMutation = graphql(`
		mutation UpdateVendor($input: VendorUpdate!) {
			updateVendor(input: $input) {
				id
			}
		}
	`)

	onMount(() => {
		if (vendor.id) {
			mediator.publish('activity', {
				title: vendor.companyName ?? vendor.contactName,
				type: 'VENDOR',
				route: 'app.vendor',
				name: 'Vendor',
				parameters: {
					vendorId: vendor.id?.toString(),
				},
				icon: 'user',
			})
		}
	})
</script>

<div class="card">
	<StateCardHeader
		icon="user"
		title={vendor.companyName || 'New Vendor'}
	>
		<div class="mt-2">
			<NavTabBar
				{tabs}
				bind:selectedTab={tab}
				on:tabChange={event => asr.go(null, { tab: event.detail }, { inherit: true })}
			>
				<svelte:fragment slot="append">
					{#if missingRequiredFieldsList}
						<span class="text-danger mb-2 mr-2 ml-auto mt-auto">Missing required fields: {missingRequiredFieldsList}</span>
					{/if}
				</svelte:fragment>
			</NavTabBar>
		</div>
		<svelte:fragment slot="title">
			{#if vendor.contactName}
				<small class="text-muted"> ({vendor.contactName})</small>
			{/if}
		</svelte:fragment>
		<svelte:fragment slot="right">
			<div
				class="d-flex"
				style="gap: 0.25rem;"
			>
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					disabled={isSaving || viewOnlyMode || !vendor.id}
					on:click={() => asr.go(null, { lastResetTime: Date.now() })}
				>
					New Vendor
				</Button>
				<Button
					outline
					size="sm"
					iconClass="magnifying-glass"
					on:click={() => vendorSearchModal?.show()}
				></Button>
				<Dropdown
					split
					menuItemClickCloses={false}
					size="sm"
					iconClass="floppy-disk"
					isLoading={isSaving}
					disabled={isSaving || !vendorChanged || !!missingRequiredFieldsList}
					on:click={() => saveVendor()}
				>
					Save Vendor
					<svelte:fragment slot="dropdownItems">
						<h6 class="dropdown-header">Save and...</h6>
						<DropdownCheckboxItem bind:checked={$clearScreenOnSave}>Start New Vendor (Clear Screen)</DropdownCheckboxItem>
					</svelte:fragment>
				</Dropdown>
			</div>
		</svelte:fragment>
	</StateCardHeader>
	<div class="card-body">
		{#if tab === 'address'}
			<Address
				{states}
				{viewOnlyMode}
				{purchaseAgents}
				bind:vendor
				bind:vendorChanged
			/>
		{:else if tab === 'additional'}
			<Additional
				{viewOnlyMode}
				{glCategories}
				{vendorTypeList}
				{salesOrderTerms}
				{vendorPaymentMethods}
				bind:vendor
				bind:vendorChanged
			/>
		{:else if tab === 'attachments'}
			<Attachments
				bind:fileList={attachments}
				modificationDisabled={viewOnlyMode}
				on:filesAdded={() => (vendorChanged = true)}
				on:filesDeleted={() => (vendorChanged = true)}
				on:publicChange={() => (vendorChanged = true)}
				on:rankChange={() => (vendorChanged = true)}
			/>
		{:else if tab === 'purchaseOrders'}
			<PurchaseOrders vendorId={vendor.id} />
		{/if}
	</div>
</div>

<SearchModal
	searchEntity="vendor"
	chooseItem={itemId => asr.go(null, { vendorId: itemId })}
	bind:this={vendorSearchModal}
></SearchModal>
