export default {
    "name": "UpdateVendor",
    "kind": "HoudiniMutation",
    "hash": "51c163af9565b3f95a8068896af07f6f26a67867ac446894646dce32a718a5c4",

    "raw": `mutation UpdateVendor($input: VendorUpdate!) {
  updateVendor(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateVendor": {
                "type": "Vendor",
                "keyRaw": "updateVendor(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "VendorUpdate"
        },

        "types": {
            "NewVendorAddress": {
                "city": "String",
                "company": "String",
                "contact": "String",
                "country": "String",
                "department": "String",
                "email": "String",
                "fax": "String",
                "mailing": "String",
                "mobile": "String",
                "notes": "String",
                "phone": "String",
                "primary": "Boolean",
                "state": "String",
                "street": "String",
                "title": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "VendorAddressUpdate": {
                "city": "String",
                "company": "String",
                "contact": "String",
                "country": "String",
                "department": "String",
                "email": "String",
                "fax": "String",
                "id": "UInt",
                "mailing": "String",
                "mobile": "String",
                "notes": "String",
                "phone": "String",
                "primary": "Boolean",
                "state": "String",
                "street": "String",
                "title": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "VendorAddressesUpdate": {
                "create": "NewVendorAddress",
                "remove": "UInt",
                "update": "VendorAddressUpdate"
            },

            "NewVendorAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "VendorAttachmentUpdate": {
                "id": "Int",
                "public": "Boolean",
                "rank": "Int"
            },

            "VendorAttachmentsUpdate": {
                "create": "NewVendorAttachment",
                "remove": "Int",
                "update": "VendorAttachmentUpdate"
            },

            "AddressInput": {
                "address": "String",
                "city": "String",
                "country": "String",
                "state": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "NestedVendorOptionValueUpdate": {
                "optionId": "Int",
                "value": "String"
            },

            "PurchaseClassUpdate": {
                "global": "Boolean",
                "id": "UInt",
                "monthHistory": "Int",
                "purchaseClass": "String",
                "safetyStock": "Int",
                "seasonal": "Boolean",
                "stockMethod": "StockMethodEnum",
                "stockingDays": "Int",
                "storeId": "UInt"
            },

            "VendorUpdate": {
                "accountNumber": "String",
                "active": "Boolean",
                "addresses": "VendorAddressesUpdate",
                "attachments": "VendorAttachmentsUpdate",
                "billingAddress": "AddressInput",
                "code": "String",
                "companyName": "String",
                "contactName": "String",
                "dunsNumber": "String",
                "email": "String",
                "faxNumber": "String",
                "glCategoryId": "Int",
                "id": "UInt",
                "isStockVendor": "Boolean",
                "minimumOrderPrice": "Decimal2",
                "minimumOrderQuantity": "Decimal6",
                "minimumOrderWeight": "Decimal2",
                "minimumPrepaidPrice": "Decimal2",
                "minimumPrepaidQuantity": "Decimal6",
                "minimumPrepaidWeight": "Decimal2",
                "mobileNumber": "String",
                "notes": "String",
                "optionValues": "NestedVendorOptionValueUpdate",
                "orderingGroup": "String",
                "paymentMethodId": "Int",
                "phoneNumber": "String",
                "purchaseAgentId": "Int",
                "purchaseClass": "PurchaseClassUpdate",
                "termId": "Int",
                "type": "String",
                "webAddress": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=e2d1d215dd58b4fad87de061a4062bf0556fcae1a14d5abf6836c1d20adf8e35";