<script
	lang="ts"
	context="module"
>
	export type AddressData = {
		companyName: string
		contactName: string
		street: string | null
		city: string | null
		state: string | null
		zip: string | null
		country: string | null
		email: string
		faxNumber: string
		phoneNumber: string
		mobilePhoneNumber: string
		validated: boolean
	}

	export type State = {
		country: string | null
		stateAbbreviation: string | null
		stateCode: number | null
		stateName: string | null
	}

	//end of module section
</script>

<script lang="ts">
	import { graphql } from '$houdini'

	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import TextArea from '@isoftdata/svelte-textarea'

	export let addressData: AddressData
	export let states: State[] = []
	export let disableAllFields: boolean = false
	export let addressType: 'BILLING' | 'ALTERNATE' | 'VALIDATION' = 'BILLING'
	export let addressChange: (() => void) | undefined

	async function lookupByZipcode(zipcode: string) {
		const { data } = await autofillByZipcodeQuery.fetch({
			variables: { zipcode },
		})

		if (data?.autofillByZip) {
			addressData.city = data.autofillByZip.city
			addressData.state = data.autofillByZip.stateAbbreviation
			addressData.country = data.autofillByZip.country
		}
	}

	const autofillByZipcodeQuery = graphql(`
		query AutofillByZip($zipcode: String) {
			autofillByZip(zipcode: $zipcode) {
				city
				country
				stateAbbreviation
			}
		}
	`)
</script>

<div class="form-row">
	<div class="col-12 col-md-6">
		<Input
			label="Company Name"
			required={!addressData.contactName && addressType === 'BILLING'}
			validation={{
				validator: value => {
					if (!value && !addressData.contactName && addressType === 'BILLING') {
						return false
					}
					return true
				},
			}}
			disabled={disableAllFields || addressType === 'VALIDATION'}
			bind:value={addressData.companyName}
			on:change={() => addressChange?.()}
		/>
	</div>
	<div class="col-12 col-md-6">
		<Input
			label="Contact Name"
			required={!addressData.companyName && addressType === 'BILLING'}
			validation={{
				validator: value => {
					if (!value && !addressData.contactName && addressType === 'BILLING') {
						return false
					}
					return true
				},
			}}
			disabled={disableAllFields || addressType === 'VALIDATION'}
			bind:value={addressData.contactName}
			on:change={() => addressChange?.()}
		/>
	</div>
	<div class="col-12 col-md-12">
		<TextArea
			label="Street Address"
			disabled={disableAllFields}
			bind:value={addressData.street}
			on:change={() => addressChange?.()}
		/>
	</div>
	<div class="col-12 col-md-4">
		<Input
			label="City"
			disabled={disableAllFields}
			bind:value={addressData.city}
			on:change={() => addressChange?.()}
		/>
	</div>
	<div class="col-12 col-md-2">
		<Select
			label="State"
			disabled={disableAllFields}
			bind:value={addressData.state}
			on:change={() => addressChange?.()}
		>
			{#each states as state}
				<option value={state.stateAbbreviation}>{state.stateAbbreviation}</option>
			{/each}
		</Select>
	</div>
	<div class="col-12 col-md-3">
		<Input
			label="Zip Code"
			placeholder="Autofills City, State, & Country"
			disabled={disableAllFields}
			bind:value={addressData.zip}
			on:change={() => {
				if (!(addressData.city || addressData.state || addressData.country) && addressData.zip) {
					lookupByZipcode(addressData.zip)
				}
				addressChange?.()
			}}
		/>
	</div>
	<div class="col-12 col-md-3">
		<Input
			label="Country"
			disabled={disableAllFields}
			bind:value={addressData.country}
			on:change={() => addressChange?.()}
		/>
	</div>
	{#if addressType !== 'VALIDATION'}
		<hr class="w-100 mb-1" />
		<div class="col-12 col-md-6">
			<Input
				label="Email"
				disabled={disableAllFields}
				bind:value={addressData.email}
				on:change={() => addressChange?.()}
			/>
		</div>

		<div class="col-12 col-md-6">
			<Input
				label="Fax"
				disabled={disableAllFields}
				bind:value={addressData.faxNumber}
				on:change={() => addressChange?.()}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				label="Phone Number"
				disabled={disableAllFields}
				bind:value={addressData.phoneNumber}
				on:change={() => addressChange?.()}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				label="Mobile Phone Number"
				disabled={disableAllFields}
				bind:value={addressData.mobilePhoneNumber}
				on:change={() => addressChange?.()}
			/>
		</div>
		<hr class="w-100 mb-1" />
		<slot name="additionalFields" />
	{/if}
</div>
