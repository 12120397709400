import type { CustomerData$result, CustomerSalesOrders$result, CustomerSearch$result, InitialCustomerPageLoad$result, BillDeliveryMethod$options, PriceType$options } from '$houdini'
import type { AlternateAddress } from 'components/BillingAndAlternateAddress.svelte'
import type { BaseAttachmentFile } from '@isoftdata/svelte-attachments'
import type { WritableDeep, Merge } from 'type-fest'
import { format as formatCurrency } from '@isoftdata/utility-currency'

type BillDeliveryMethod = BillDeliveryMethod$options
type PriceType = PriceType$options
type SalesOrder = WritableDeep<Merge<CustomerSalesOrders$result['customer']['salesOrders']['items'][number], { storeId: number; status: 'Void' | 'Open' | 'Closed' }>>
type TaxExemption = WritableDeep<
	Merge<
		CustomerData$result['customer']['taxExemptions'][0],
		{
			id: number | null
			uuid: string
		}
	>
>

export type CustomerBalanceStatus = 'ZERO_BALANCE' | 'CREDIT_BALANCE' | 'OWE_AND_OVER_LIMIT' | 'OWE_AND_UNDER_LIMIT' | 'INACTIVE' | 'NO_STATUS'
export type Customer = WritableDeep<
	Omit<CustomerData$result['customer'], 'id' | 'taxExemption' | 'salesOrders'> & {
		id: number | null
		balanceStatus: CustomerBalanceStatus
		salesOrders: SalesOrder[]
		taxExemptions: TaxExemption[]
		billingAddressValidated: boolean
	}
>
export type CustomerAttachment = BaseAttachmentFile & { customerFileId: number }
export type CustomerSearchResult = CustomerSearch$result['customers']['items'][number] & { balanceStatus: CustomerBalanceStatus }
export type State = InitialCustomerPageLoad$result['states'][0]
export type PaymentMethod = InitialCustomerPageLoad$result['paymentMethods'][0]
export type SalesPerson = InitialCustomerPageLoad$result['userAccounts'][0]
export type TaxItem = InitialCustomerPageLoad$result['taxItems'][0]
export type SalesOrderTerm = InitialCustomerPageLoad$result['salesOrderTerms'][0]
export type CustomerType = InitialCustomerPageLoad$result['customerTypes'][0]
export type Store = InitialCustomerPageLoad$result['stores'][0]

export type CustomerAlternateAddress = AlternateAddress & {
	storeId?: number | null
	salesPersonId?: number | null
}

export const billDeliveryMethodOptions: { label: string; value: BillDeliveryMethod }[] = [
	{ label: 'Email', value: 'EMAIL' },
	{ label: 'Print', value: 'PRINT' },
	{ label: 'Print & Email', value: 'PRINT_AND_EMAIL' },
]

export const priceTypeOptions: { label: string; value: PriceType }[] = [
	{ label: 'Average Cost', value: 'AVERAGE_COST' },
	{ label: 'Distributor', value: 'DISTRIBUTOR' },
	{ label: 'Jobber', value: 'JOBBER' },
	{ label: 'List', value: 'LIST' },
	{ label: 'Wholesale', value: 'WHOLESALE' },
	{ label: 'Cost', value: 'COST' },
	{ label: 'Retail', value: 'RETAIL' },
]

export const customerBalanceStatusMap: Record<CustomerBalanceStatus, { textColor: string }> = {
	ZERO_BALANCE: { textColor: '' },
	CREDIT_BALANCE: { textColor: 'text-primary' },
	OWE_AND_OVER_LIMIT: { textColor: 'text-danger' },
	OWE_AND_UNDER_LIMIT: { textColor: 'text-dark-goldenrod' },
	INACTIVE: { textColor: 'text-black-50' },
	NO_STATUS: { textColor: '' },
}

export const defaultCustomer = Object.freeze<Customer>({
	acceptsBackOrders: true,
	acceptsPartialShipments: true,
	accountLimit: '0.00',
	active: true,
	addresses: [],
	aging: {
		ninetyOneToOneHundredTwentyDays: '0.00',
		overOneHundredTwentyDays: '0.00',
		sixtyOneToNinetyDays: '0.00',
		thirtyOneToSixtyDays: '0.00',
		zeroToThirtyDays: '0.00',
	},
	allowedPaymentMethods: [],
	attachments: [],
	balance: '0.00',
	balanceStatus: 'NO_STATUS',
	billDeliveryMethod: 'EMAIL',
	billingAddress: {
		lastValidated: null,
		address1: '',
		address2: '',
		city: '',
		country: '',
		county: '',
		email: '',
		phone: '',
		state: null,
		zip: '',
	},
	billingAddressValidated: false,
	blanketPurchaseOrderNumber: '',
	blanketPurchaseOrderNumberExpiration: null,
	cashOnly: false,
	companyName: '',
	contactName: '',
	customLaborRate: '0.00',
	dateEntered: null,
	defaultPaymentMethod: null,
	defaultPricePercentage: '100',
	defaultPriceType: 'RETAIL',
	defaultTaxItem: null,
	defaultTerm: null,
	driverLicenseNumber: '',
	email: '',
	faxNumber: '',
	id: null,
	lastBillDate: null,
	laborRateType: 'FIXED',
	mobilePhoneNumber: '',
	notes: '',
	optionValues: [],
	phoneNumber: '',
	requirePurchaseOrderNumber: false,
	salesPerson: null,
	store: null,
	taxExemptions: [],
	type: '',
	webAddress: '',
	salesRegion: null,
	storeRegion: null,
	salesOrders: [],
})

export function formatCustomerCurrency(balance: string, displayWithParentheses = true): string {
	const parsedBalance = parseFloat(balance)

	if (isNaN(parsedBalance)) {
		return '$0.00'
	}

	const formattedBalance = parsedBalance.toFixed(2)

	if (formattedBalance.startsWith('-')) {
		// Remove the negative sign and format the number with parentheses
		const balanceNumber = formatCurrency(formattedBalance.slice(1))
		return displayWithParentheses ? `(${balanceNumber})` : `${balanceNumber} Credit`
	}

	return `${formatCurrency(formattedBalance)}`
}
