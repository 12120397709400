export default {
    "name": "InitialVendorPageLoad",
    "kind": "HoudiniQuery",
    "hash": "f27f7e1eb0e5b7cc0813df42bfb691a5ea3fc945da97dddf907c79890e0deb11",

    "raw": `query InitialVendorPageLoad($filter: PaymentMethodFilter) {
  states {
    country
    stateAbbreviation
    stateCode
    stateName
  }
  glCategories {
    id
    name
  }
  salesOrderTerms {
    id
    name
  }
  paymentMethods(filter: $filter) {
    id
    name
  }
  userAccounts {
    id
    name
  }
  vendorTypes
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "states": {
                "type": "State",
                "keyRaw": "states",

                "selection": {
                    "fields": {
                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "nullable": true,
                            "visible": true
                        },

                        "stateAbbreviation": {
                            "type": "String",
                            "keyRaw": "stateAbbreviation",
                            "nullable": true,
                            "visible": true
                        },

                        "stateCode": {
                            "type": "Int",
                            "keyRaw": "stateCode",
                            "nullable": true,
                            "visible": true
                        },

                        "stateName": {
                            "type": "String",
                            "keyRaw": "stateName",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "glCategories": {
                "type": "GlCategory",
                "keyRaw": "glCategories",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "salesOrderTerms": {
                "type": "Term",
                "keyRaw": "salesOrderTerms",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "paymentMethods": {
                "type": "PaymentMethod",
                "keyRaw": "paymentMethods(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "vendorTypes": {
                "type": "String",
                "keyRaw": "vendorTypes",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "PaymentMethodFilter"
        },

        "types": {
            "PaymentMethodFilter": {
                "customerPaymentMethod": "Boolean",
                "requiresDocumentNumber": "Boolean",
                "vendorPaymentMethod": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7ed89b1e67d213674db381183e41aa18b82e8a9e3b7c549efc41f084766b9e08";