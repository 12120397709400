<script lang="ts">
	import BillingAndAlternateAddress, { type BillingAddress, type AlternateAddress } from 'components/BillingAndAlternateAddress.svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { States, Vendor, PurchaseAgents } from './vendor'
	import { getContext } from 'svelte'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import { getEventValue } from '@isoftdata/browser-event'

	export let vendor: Vendor
	export let states: States = []
	export let purchaseAgents: PurchaseAgents = []
	export let vendorChanged: boolean
	export let viewOnlyMode: boolean = false

	let alternateAddressCrudStore = getContext<CrudStore<AlternateAddress, 'uuid'>>('alternateAddressCrudStore')
	let billingAddress: BillingAddress = formatVendorBillingAddress(vendor)
	let alternateAddresses: AlternateAddress[] = formatVendorAlternateAddresses(vendor.addresses)

	function formatVendorBillingAddress(vendor: Vendor): BillingAddress {
		return {
			companyName: vendor.companyName,
			contactName: vendor.contactName,
			street: vendor.billingAddress.address1,
			city: vendor.billingAddress.city,
			state: vendor.billingAddress.state,
			zip: vendor.billingAddress.zip,
			country: vendor.billingAddress.country,
			email: vendor.email,
			faxNumber: vendor.faxNumber,
			phoneNumber: vendor.phoneNumber,
			mobilePhoneNumber: vendor.mobilePhoneNumber,
			validated: vendor.billingAddressValidated,
		}
	}

	function formatVendorAlternateAddresses(addresses: Vendor['addresses']): AlternateAddress[] {
		return addresses.map(address => {
			return {
				id: address.vendorAddressId,
				uuid: uuid(),
				label: address.title,
				primary: address.primary,
				addressData: {
					companyName: address.company,
					contactName: address.contact,
					street: address.address1,
					city: address.city,
					state: address.state,
					zip: address.zip,
					country: address.country,
					email: address.email ?? '',
					faxNumber: address.faxNumber,
					phoneNumber: address.phone,
					mobilePhoneNumber: address.mobilePhoneNumber,
					department: address.department,
					notes: address.notes ?? '',
					purchaseAgent: null,
					purchaseRegion: null,
					validated: !!address.lastValidated,
				},
			}
		})
	}

	function updateVendorBillingAddress(updatedBillingAddress: BillingAddress) {
		vendor.companyName = updatedBillingAddress.companyName
		vendor.contactName = updatedBillingAddress.contactName
		vendor.billingAddress = {
			...vendor.billingAddress,
			address1: updatedBillingAddress.street,
			city: updatedBillingAddress.city,
			state: updatedBillingAddress.state,
			zip: updatedBillingAddress.zip,
			country: updatedBillingAddress.country,
		}
		vendor.faxNumber = updatedBillingAddress.faxNumber
		vendor.mobilePhoneNumber = updatedBillingAddress.mobilePhoneNumber
		vendor.billingAddressValidated = updatedBillingAddress.validated
	}
</script>

<BillingAndAlternateAddress
	{states}
	{viewOnlyMode}
	bind:billingAddress
	bind:alternateAddresses
	bind:alternateAddressCrudStore
	bind:hasChanges={vendorChanged}
	billingAddressChange={billingAddress => updateVendorBillingAddress(billingAddress)}
>
	<svelte:fragment slot="billingTopFields">
		<div class="col-12 col-md-6">
			<Input
				label="Vendor Code"
				value={vendor.code ?? ''}
				disabled={viewOnlyMode}
				on:change={event => {
					vendor.code = getEventValue(event)
					vendorChanged = true
				}}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				label="Account Number"
				value={vendor.accountNumber || ''}
				disabled={viewOnlyMode}
				on:change={event => {
					vendor.accountNumber = getEventValue(event)
					vendorChanged = true
				}}
			/>
		</div>
	</svelte:fragment>
	<svelte:fragment slot="billingAdditionalFields">
		<div class="col-12 col-md-6">
			<Input
				readonly
				label="Purchase Region"
				value={vendor.purchaseRegion?.name ?? ''}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Select
				label="Purchase Agent"
				emptyText="No Purchase Agent"
				options={purchaseAgents.map(agent => agent.name)}
				disabled={viewOnlyMode}
				value={vendor.purchaseAgent?.name ?? null}
				on:change={event => {
					const value = getEventValue(event)
					const selectedPurchaseAgent = purchaseAgents.find(agent => agent.name === value)
					if (selectedPurchaseAgent) {
						vendor.purchaseAgent = selectedPurchaseAgent
					}
					vendorChanged = true
				}}
			/>
		</div>
	</svelte:fragment>
</BillingAndAlternateAddress>
