import type { ComponentProps } from 'svelte'
import type { AppContext } from 'types/common'
export type AsrTabs = ComponentProps<AsrNavTabBar>['tabs']

import component from './Configuration.svelte'
import { checkSessionPermission } from 'utility/permission'
import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

interface User {
	settings: {
		preferences: {
			optInToTestingFeatures: boolean
		}
	}
}

function getChildStates(): AsrTabs {
	const user = JSON.parse(sessionStorage.getItem('user') ?? '{}') as User
	return [
		{
			iconClass: 'chart-line',
			title: 'Dashboard',
			name: 'app.configuration.dashboard',
			parameters: {},
			hidden: !checkSessionPermission('DASHBOARD_REPORT_EDIT_ALL') && !checkSessionPermission('DASHBOARD_REPORT_EDIT_OWN'),
		},
		{
			iconClass: 'engine',
			title: 'Inventory Types',
			name: 'app.configuration.inventory-type',
			parameters: {},
			hidden: !checkSessionPermission('PART_CONFIGURATION_CAN_EDIT_PART_TYPES'),
		},
		{
			iconClass: 'address-card',
			title: 'My Account',
			name: 'app.configuration.my-account',
		},
		{
			iconClass: 'barcode-alt',
			title: 'Scanner',
			name: 'app.configuration.scanner',
			parameters: {},
		},
		{
			iconClass: 'sliders',
			title: 'Settings',
			name: 'app.configuration.settings',
			parameters: {},
			hidden: false, // All users have permission to edit their own settings the Screen itself needs to handle hiding global and Store settings
		},
		{
			iconClass: 'store-alt',
			title: 'Store',
			name: 'app.configuration.store',
			parameters: {},
			hidden: !user.settings.preferences.optInToTestingFeatures && !checkSessionPermission('CONFIGURATION_CAN_CONFIGURE_STORES'),
		},

		{
			iconClass: 'user',
			title: 'User',
			name: 'app.configuration.user',
			parameters: {},
			hidden: !checkSessionPermission('GLOBAL_USER_ADMINISTRATOR'),
		},
	]
}

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration',
		route: 'configuration',
		defaultChild: () => {
			const childStates = getChildStates()
			const activeChildState = childStates.find(childState => !childState.hidden)
			return activeChildState?.name ? activeChildState.name.replace('app.configuration.', '') : undefined // return just the name of the child state
		},
		template: {
			component,
			svelte: true,
		},
		resolve(_data, _parameters) {
			const childStates = getChildStates()
			return Promise.resolve({
				childStates,
				hasVisibleChildState: childStates.some(childState => !childState.hidden),
			})
		},
	})
}
