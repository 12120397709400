export default {
    "name": "Permissions",
    "kind": "HoudiniFragment",
    "hash": "30dabd815a0af5284697ba90d78f5b0240370ac731adff3a7693265e76c1b931",

    "raw": `fragment Permissions on Session {
  permissions {
    WEB_CAN_LOGIN
    VEHICLES_CAN_EDIT_STOCK_NUMBER
    VEHICLES_CAN_EDIT_STATUS
    VEHICLES_CAN_EDIT_BASIC
    VEHICLES_CAN_EDIT_WHOLE_UNIT_ADVANCED
    VEHICLES_CAN_EDIT_ADDITIONAL_INFORMATION
    VEHICLES_CAN_EDIT_QANDA
    VEHICLES_CAN_EDIT_WHOLE_UNITS
    VEHICLES_CAN_EDIT_GL_CATEGORY
    VEHICLES_CAN_EDIT_ATTACHMENTS
    VEHICLES_CAN_EDIT_BIDS
    VEHICLES_CAN_EDIT_NON_BID_UNITS
    VEHICLES_CAN_VIEW_VEHICLE_PERFORMANCE
    VEHICLES_CAN_CHANGE_STOCK_QUANTITY
    SEARCH_CAN_SEARCH_VEHICLES
    DASHBOARD_REPORT_VIEW
    DASHBOARD_REPORT_EDIT_OWN
    DASHBOARD_REPORT_EDIT_ALL
    CONFIGURATION_CAN_CONFIGURE_STORES
    CUSTOMERS_CAN_ADD
    CUSTOMERS_CAN_EDIT_ADDRESSES
    CUSTOMERS_CAN_EDIT_ADVANCED
    CUSTOMERS_CAN_EDIT_BILLING_ADDRESS
    CUSTOMERS_CAN_EDIT_CREDIT_LIMIT
    CUSTOMERS_CAN_EDIT_CUSTOMERS
    CUSTOMERS_CAN_EDIT_PRICING
    CUSTOMERS_CAN_EDIT_SALESMAN_STORE
    CUSTOMERS_CAN_EDIT_TERMS
    CUSTOMERS_CAN_MANAGE_MESSAGES
    CUSTOMERS_CAN_MANAGE_OPTIONS
    CUSTOMERS_CAN_MANAGE_UNITS
    CUSTOMERS_CAN_MERGE
    PARTS_CAN_CLONE_PARTS
    PARTS_CAN_CREATE_CONDITIONS
    PARTS_CAN_EDIT_ATTACHMENTS
    PARTS_CAN_EDIT_COST
    PARTS_CAN_EDIT_GLCATEGORY
    PARTS_CAN_EDIT_INFO
    PARTS_CAN_EDIT_LOCATION
    PARTS_CAN_EDIT_PRICES
    PARTS_CAN_EDIT_QUANTITY
    PARTS_CAN_EDIT_STATUS
    PARTS_CAN_EDIT_STOCKING_DATA
    PARTS_CAN_REPLICATE_PARTS
    PARTS_CAN_SAVE_PARTS
    PARTS_CAN_VIEW_COST
    PARTS_CAN_VIEW_PARTS
    PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES
    PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR
    PART_CONFIGURATION_CAN_EDIT_PART_TYPES
    PURCHASE_ORDERS_CAN_VIEW
    CONFIGURATION_CAN_CONFIGURE_STORES
    GLOBAL_USER_ADMINISTRATOR
    SALES_ORDERS_CAN_VIEW
    VENDORS_CAN_EDIT_VENDORS
  }
  id
  __typename
}
`,

    "rootType": "Session",
    "stripVariables": [],

    "selection": {
        "fields": {
            "permissions": {
                "type": "SessionPermissions",
                "keyRaw": "permissions",
                "nullable": true,

                "selection": {
                    "fields": {
                        "WEB_CAN_LOGIN": {
                            "type": "Boolean",
                            "keyRaw": "WEB_CAN_LOGIN",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_STOCK_NUMBER": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_STOCK_NUMBER",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_STATUS": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_STATUS",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_BASIC": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_BASIC",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_WHOLE_UNIT_ADVANCED": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_WHOLE_UNIT_ADVANCED",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_ADDITIONAL_INFORMATION": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_ADDITIONAL_INFORMATION",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_QANDA": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_QANDA",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_WHOLE_UNITS": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_WHOLE_UNITS",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_GL_CATEGORY": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_GL_CATEGORY",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_ATTACHMENTS": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_ATTACHMENTS",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_BIDS": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_BIDS",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_EDIT_NON_BID_UNITS": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_EDIT_NON_BID_UNITS",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_VIEW_VEHICLE_PERFORMANCE": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_VIEW_VEHICLE_PERFORMANCE",
                            "nullable": true,
                            "visible": true
                        },

                        "VEHICLES_CAN_CHANGE_STOCK_QUANTITY": {
                            "type": "Boolean",
                            "keyRaw": "VEHICLES_CAN_CHANGE_STOCK_QUANTITY",
                            "nullable": true,
                            "visible": true
                        },

                        "SEARCH_CAN_SEARCH_VEHICLES": {
                            "type": "Boolean",
                            "keyRaw": "SEARCH_CAN_SEARCH_VEHICLES",
                            "nullable": true,
                            "visible": true
                        },

                        "DASHBOARD_REPORT_VIEW": {
                            "type": "Boolean",
                            "keyRaw": "DASHBOARD_REPORT_VIEW",
                            "nullable": true,
                            "visible": true
                        },

                        "DASHBOARD_REPORT_EDIT_OWN": {
                            "type": "Boolean",
                            "keyRaw": "DASHBOARD_REPORT_EDIT_OWN",
                            "nullable": true,
                            "visible": true
                        },

                        "DASHBOARD_REPORT_EDIT_ALL": {
                            "type": "Boolean",
                            "keyRaw": "DASHBOARD_REPORT_EDIT_ALL",
                            "nullable": true,
                            "visible": true
                        },

                        "CONFIGURATION_CAN_CONFIGURE_STORES": {
                            "type": "Boolean",
                            "keyRaw": "CONFIGURATION_CAN_CONFIGURE_STORES",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_ADD": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_ADD",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_ADDRESSES": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_ADDRESSES",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_ADVANCED": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_ADVANCED",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_BILLING_ADDRESS": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_BILLING_ADDRESS",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_CREDIT_LIMIT": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_CREDIT_LIMIT",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_CUSTOMERS": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_CUSTOMERS",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_PRICING": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_PRICING",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_SALESMAN_STORE": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_SALESMAN_STORE",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_EDIT_TERMS": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_EDIT_TERMS",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_MANAGE_MESSAGES": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_MANAGE_MESSAGES",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_MANAGE_OPTIONS": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_MANAGE_OPTIONS",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_MANAGE_UNITS": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_MANAGE_UNITS",
                            "nullable": true,
                            "visible": true
                        },

                        "CUSTOMERS_CAN_MERGE": {
                            "type": "Boolean",
                            "keyRaw": "CUSTOMERS_CAN_MERGE",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_CLONE_PARTS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_CLONE_PARTS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_CREATE_CONDITIONS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_CREATE_CONDITIONS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_ATTACHMENTS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_ATTACHMENTS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_COST": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_COST",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_GLCATEGORY": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_GLCATEGORY",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_INFO": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_INFO",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_LOCATION": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_LOCATION",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_PRICES": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_PRICES",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_QUANTITY": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_QUANTITY",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_STATUS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_STATUS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_EDIT_STOCKING_DATA": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_EDIT_STOCKING_DATA",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_REPLICATE_PARTS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_REPLICATE_PARTS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_SAVE_PARTS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_SAVE_PARTS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_VIEW_COST": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_VIEW_COST",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_CAN_VIEW_PARTS": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_CAN_VIEW_PARTS",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES",
                            "nullable": true,
                            "visible": true
                        },

                        "PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR": {
                            "type": "Boolean",
                            "keyRaw": "PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR",
                            "nullable": true,
                            "visible": true
                        },

                        "PART_CONFIGURATION_CAN_EDIT_PART_TYPES": {
                            "type": "Boolean",
                            "keyRaw": "PART_CONFIGURATION_CAN_EDIT_PART_TYPES",
                            "nullable": true,
                            "visible": true
                        },

                        "PURCHASE_ORDERS_CAN_VIEW": {
                            "type": "Boolean",
                            "keyRaw": "PURCHASE_ORDERS_CAN_VIEW",
                            "nullable": true,
                            "visible": true
                        },

                        "GLOBAL_USER_ADMINISTRATOR": {
                            "type": "Boolean",
                            "keyRaw": "GLOBAL_USER_ADMINISTRATOR",
                            "nullable": true,
                            "visible": true
                        },

                        "SALES_ORDERS_CAN_VIEW": {
                            "type": "Boolean",
                            "keyRaw": "SALES_ORDERS_CAN_VIEW",
                            "nullable": true,
                            "visible": true
                        },

                        "VENDORS_CAN_EDIT_VENDORS": {
                            "type": "Boolean",
                            "keyRaw": "VENDORS_CAN_EDIT_VENDORS",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "id": {
                "type": "UInt",
                "keyRaw": "id",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    }
};

"HoudiniHash=52ae360f05b53515c7994a171908a9e4830a93d34bc66b212438a9f2685748b5";