<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import { hasPermission } from 'utility/permission'

	import Button from '@isoftdata/svelte-button'
	import Input from '@isoftdata/svelte-input'
	import Popover from '@isoftdata/svelte-popover'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	export let asr: SvelteAsr
	let searchKeyword: string = ''
</script>

<div class="card">
	<StateCardHeader
		icon="user"
		title="Vendor Search"
	>
		<svelte:fragment slot="right">
			{#if hasPermission('VENDORS_CAN_EDIT_VENDORS')}
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					href={asr.makePath('app.vendor')}
				>
					New Vendor
				</Button>
			{/if}
		</svelte:fragment>
	</StateCardHeader>
	<div class="card-body">
		<form
			id="search-field-form"
			on:submit={event => {
				event.preventDefault()
				asr.go('app.vendor-search.results', { keyword: searchKeyword })
			}}
		>
			<Input
				size=""
				showLabel={false}
				labelParentClass="mb-0"
				placeholder="Keyword Search"
				bind:value={searchKeyword}
			>
				<svelte:fragment slot="append">
					<Popover
						outline
						autoUpdate
						color="secondary"
						iconClass="info-circle"
						style="border-top-right-radius: 0.2rem; border-bottom-right-radius: 0.2rem;"
					>
						<svelte:fragment slot="popover">
							<h6 class="popover-header">Search Filters By:</h6>
							<div class="popover-body">
								<ul class="mb-0 pl-3">
									<li class="text-nowrap">Company Name</li>
									<li class="text-nowrap">Contact Name</li>
									<li class="text-nowrap">Phone Number</li>
								</ul>
							</div>
						</svelte:fragment>
					</Popover>
				</svelte:fragment>
			</Input>
		</form>
	</div>

	<div class="card-footer d-flex justify-content-end border-bottom">
		<Button
			type="submit"
			form="search-field-form"
			iconClass="search"
			size="sm"
		>
			Search Vendors
		</Button>
	</div>
</div>

<uiView></uiView>
