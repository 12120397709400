import type { AppContext } from 'types/common'
import type { WritableDeep, Merge } from 'type-fest'
import type { VendorLookUp$result, InitialVendorPageLoad$result } from '$houdini'
import type { BaseAttachmentFile } from '@isoftdata/svelte-attachments'

import component from './Vendor.svelte'
// import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import { v4 as uuid } from '@lukeed/uuid'
import { klona } from 'klona'

export type Vendor = WritableDeep<Merge<VendorLookUp$result['vendor'], { id: number | null; billingAddressValidated: boolean }>>
export type GLCategories = InitialVendorPageLoad$result['glCategories']
export type SalesOrderTerms = InitialVendorPageLoad$result['salesOrderTerms']
export type States = InitialVendorPageLoad$result['states']
export type VendorTypes = InitialVendorPageLoad$result['vendorTypes']
export type VendorPaymentMethods = InitialVendorPageLoad$result['paymentMethods']
export type PurchaseAgents = InitialVendorPageLoad$result['userAccounts']
export type VendorAttachment = BaseAttachmentFile & { vendorFileId: number }

const defaultVendor = Object.freeze<Vendor>({
	accountNumber: '',
	active: true,
	addresses: [],
	attachments: [],
	billingAddress: {
		address1: '',
		city: '',
		company: '',
		contact: '',
		country: '',
		email: '',
		lastValidated: null,
		phone: '',
		state: '',
		zip: '',
	},
	billingAddressValidated: false,
	code: '',
	companyName: '',
	contactName: '',
	dateEntered: null,
	defaultGLCategory: null,
	defaultPaymentMethod: null,
	defaultTerm: null,
	dunsNumber: '',
	email: '',
	faxNumber: '',
	id: null,
	mobilePhoneNumber: '',
	name: '',
	notes: '',
	orderingGroup: '',
	phoneNumber: '',
	type: '',
	webAddress: '',
	customFields: [],
	purchaseAgent: null,
	purchaseRegion: null,
})

export default function createState({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.vendor',
		route: 'vendor/:vendorId',
		querystringParameters: ['vendorId', 'lastResetTime'],
		defaultParameters: {
			vendorId: null,
			tab: 'address',
			lastResetTime: null,
		},
		canLeaveState(domApi) {
			// @ts-expect-error it's fine
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			if (!domApi?.canLeaveState()) {
				// \n\nYour changes have been saved for later, click "Unsaved Customer" in "Recent Activity" to resume your work.
				return confirm('You have unsaved changes. Are you sure you want to leave?')
			}
			return true
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters) {
			// const session = getSession()
			const vendorId = parseInt(parameters.vendorId, 10)

			const { data: initialPageData } = await initialPageLoadQuery.fetch({
				variables: {
					filter: {
						vendorPaymentMethod: true,
					},
				},
				policy: 'CacheOrNetwork',
			})

			if (!initialPageData) {
				throw new Error('Failed to load initial page data')
			}

			let vendor: Vendor
			let attachments: Array<VendorAttachment> = []

			if (vendorId) {
				const { data } = await vendorLookUpQuery.fetch({
					variables: {
						vendorId,
					},
				})

				if (!data?.vendor) {
					throw new Error('Failed to load vendor data')
				}

				attachments =
					data.vendor?.attachments.map(attachment => ({
						...attachment.file,
						fileId: attachment.file.id,
						vendorFileId: attachment.id,
						public: attachment.public,
						mimeType: attachment.file.mimetype,
						rank: attachment.rank ?? 0,
						createdDate: attachment.file.created.toISOString(),
						uuid: uuid(),
					})) ?? []

				vendor = {
					...data.vendor,
					billingAddressValidated: !!data.vendor?.billingAddress?.lastValidated,
				}
			} else {
				const newVendor = klona(defaultVendor)
				const { data } = await vendorCustomFieldsQuery.fetch()

				if (!data) {
					throw new Error('Failed to load custom fields')
				}

				vendor = {
					...newVendor,
					customFields: data.vendorOptions.map(option => ({
						option,
						value: '',
					})),
				}
			}
			return {
				vendor,
				attachments,
				states: initialPageData.states,
				glCategories: initialPageData.glCategories,
				salesOrderTerms: initialPageData.salesOrderTerms,
				vendorTypeList: initialPageData.vendorTypes,
				vendorPaymentMethods: initialPageData.paymentMethods,
				purchaseAgents: initialPageData.userAccounts,
				tab: parameters.tab,
			}
		},
	})
}

const initialPageLoadQuery = graphql(`
	query InitialVendorPageLoad($filter: PaymentMethodFilter) {
		states {
			country
			stateAbbreviation
			stateCode
			stateName
		}
		glCategories {
			id
			name
		}
		salesOrderTerms {
			id
			name
		}
		paymentMethods(filter: $filter) {
			id
			name
		}
		userAccounts {
			id
			name
		}
		vendorTypes
	}
`)

const vendorLookUpQuery = graphql(`
	query VendorLookUp($vendorId: UInt!) {
		vendor(id: $vendorId) {
			addresses {
				vendorAddressId
				address1
				city
				company
				contact
				country
				department
				email
				faxNumber
				lastValidated
				mobilePhoneNumber
				notes
				phone
				primary
				state
				title
				zip
			}
			attachments {
				id
				public
				rank
				file {
					id
					created
					extension
					mimetype
					name
					hash
					type
					updated
					path
					size
					host
					url
				}
			}
			billingAddress {
				address1
				city
				company
				contact
				country
				email
				lastValidated
				phone
				state
				zip
			}
			accountNumber
			active
			code
			companyName
			contactName
			dateEntered
			defaultGLCategory {
				id
				name
			}
			defaultPaymentMethod {
				id
				name
			}
			defaultTerm {
				id
				name
			}
			dunsNumber
			email
			faxNumber
			id
			mobilePhoneNumber
			name
			notes
			orderingGroup
			phoneNumber
			type
			webAddress
			customFields: optionValues {
				value
				option {
					id
					name
					showInList
					type
				}
			}
			purchaseAgent {
				id
				name
			}
			purchaseRegion {
				name
			}
		}
	}
`)

const vendorCustomFieldsQuery = graphql(`
	query VendorCustomFields {
		vendorOptions {
			id
			name
			showInList
			type
		}
	}
`)
