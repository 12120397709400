<script lang="ts">
	import { VendorSearchStore, CustomerSearchStore } from '$houdini'
	import type { Mediator } from 'types/common'
	import { getContext } from 'svelte'

	import LoadItemModal from '@isoftdata/svelte-load-item-modal'

	type SearchResult = {
		id: number
		companyName: string
		contactName: string
		phoneNumber: string
		billingAddress: {
			address1: string | null
			city: string | null
			state: string | null
			zip: string | null
		}
	}
	const mediator = getContext<Mediator>('mediator')

	export let chooseItem: (itemId: number) => void | Promise<void>
	export let searchEntity: 'customer' | 'vendor'

	let loadVendorModal: LoadItemModal<SearchResult>

	export function show() {
		loadVendorModal?.show()
	}
</script>

<LoadItemModal
	itemTitle={searchEntity === 'customer' ? 'Customers' : 'Vendors'}
	itemIdProp="id"
	itemDisplayProp="companyName"
	doSearch={async searchString => {
		if (searchEntity === 'customer') {
			const query = new CustomerSearchStore()
			const { data } = await query.fetch({
				variables: {
					filter: {
						keyword: searchString,
					},
				},
			})
			//TODO: remove the filter() when the API added the active filter
			return (
				data?.customers.items
					?.filter(item => item.active)
					.map(item => {
						return {
							id: item.id,
							companyName: item.companyName,
							contactName: item.contactName,
							phoneNumber: item.phoneNumber,
							billingAddress: {
								address1: item.billingAddress?.address1,
								city: item.billingAddress?.city,
								state: item.billingAddress?.state,
								zip: item.billingAddress?.zip,
							},
						}
					}) ?? []
			)
		} else {
			const query = new VendorSearchStore()
			const { data } = await query.fetch({
				variables: {
					filter: {
						keyword: searchString,
						active: true,
					},
				},
			})
			return (
				data?.vendors.items.map(item => {
					return {
						id: item.id,
						companyName: item.companyName,
						contactName: item.contactName,
						phoneNumber: item.phoneNumber,
						billingAddress: {
							address1: item.billingAddress?.address1,
							city: item.billingAddress?.city,
							state: item.billingAddress?.state,
							zip: item.billingAddress?.zip,
						},
					}
				}) ?? []
			)
		}
	}}
	on:lookupError={({ detail: { error } }) =>
		mediator.publish('showMessage', {
			heading: 'Error Fetching Vendors',
			message: error.message,
			type: 'danger',
			time: false,
		})}
	on:chooseItem={({ detail: { item } }) => chooseItem(item.id)}
	bind:this={loadVendorModal}
	let:item
>
	<div class="w-100 d-flex justify-content-between">
		<h5 class="mb-1">{item.companyName ?? item.contactName}</h5>
		<h5 class="mb-1">{item.phoneNumber}</h5>
	</div>

	{#if item.companyName && item.contactName}
		<h5 class="mb-1">{item.contactName}</h5>
	{/if}
	<div class="w-100 d-flex justify-content-between">
		<div class="mb-1">
			{item.billingAddress?.address1}
		</div>
		<div class="mb-1">
			{item.billingAddress?.city}{item.billingAddress.city && item.billingAddress.state ? ', ' : ''}{item.billingAddress?.state}
			{item.billingAddress?.zip}
		</div>
	</div>
</LoadItemModal>
