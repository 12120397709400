export default {
    "name": "CreateVendor",
    "kind": "HoudiniMutation",
    "hash": "dbafe6481b73f0a565e3990f68d879e1d455f4b665dc1deca8b8cfc178f4d08c",

    "raw": `mutation CreateVendor($input: NewVendor!) {
  createVendor(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createVendor": {
                "type": "Vendor",
                "keyRaw": "createVendor(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewVendor"
        },

        "types": {
            "NewVendorAddress": {
                "city": "String",
                "company": "String",
                "contact": "String",
                "country": "String",
                "department": "String",
                "email": "String",
                "fax": "String",
                "mailing": "String",
                "mobile": "String",
                "notes": "String",
                "phone": "String",
                "primary": "Boolean",
                "state": "String",
                "street": "String",
                "title": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "NewVendorAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "AddressInput": {
                "address": "String",
                "city": "String",
                "country": "String",
                "state": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "NestedVendorOptionValueUpdate": {
                "optionId": "Int",
                "value": "String"
            },

            "PurchaseClassUpdate": {
                "global": "Boolean",
                "id": "UInt",
                "monthHistory": "Int",
                "purchaseClass": "String",
                "safetyStock": "Int",
                "seasonal": "Boolean",
                "stockMethod": "StockMethodEnum",
                "stockingDays": "Int",
                "storeId": "UInt"
            },

            "NewVendor": {
                "accountNumber": "String",
                "active": "Boolean",
                "addresses": "NewVendorAddress",
                "attachments": "NewVendorAttachment",
                "billingAddress": "AddressInput",
                "code": "String",
                "companyName": "String",
                "contactName": "String",
                "dunsNumber": "String",
                "email": "String",
                "faxNumber": "String",
                "glCategoryId": "Int",
                "isStockVendor": "Boolean",
                "minimumOrderPrice": "Decimal2",
                "minimumOrderQuantity": "Decimal6",
                "minimumOrderWeight": "Decimal2",
                "minimumPrepaidPrice": "Decimal2",
                "minimumPrepaidQuantity": "Decimal6",
                "minimumPrepaidWeight": "Decimal2",
                "mobileNumber": "String",
                "notes": "String",
                "optionValues": "NestedVendorOptionValueUpdate",
                "orderingGroup": "String",
                "paymentMethodId": "Int",
                "phoneNumber": "String",
                "purchaseAgentId": "Int",
                "purchaseClass": "PurchaseClassUpdate",
                "termId": "Int",
                "type": "String",
                "webAddress": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ac28da11580c96e443f05587e9ab893585e13ac5c2b8d08d8d5d91c1dab33255";