export default {
    "name": "CustomerSearch",
    "kind": "HoudiniQuery",
    "hash": "07b4eabdb8efbfb86fb9a4c5195a318e6cda430826f24d9ef79199ed8e32f358",

    "raw": `query CustomerSearch($pagination: PaginationOptions, $filter: CustomerFilter) {
  customers(pagination: $pagination, filter: $filter) {
    items {
      id
      active
      contactName
      balance
      accountLimit
      dateEntered
      phoneNumber
      companyName
      store {
        code
        id
      }
      faxNumber
      mobilePhoneNumber
      type
      billingAddress {
        address1
        city
        state
        zip
        country
      }
      email
      webAddress
      defaultTerm {
        name
        id
      }
      salesPerson {
        name
        id
      }
      defaultPaymentMethod {
        name
        id
      }
      optionValues {
        option {
          id
          label
          dataType
          showInCustomerList
        }
        value
      }
    }
    pageInfo {
      pageNumber
      pageSize
      totalPages
    }
    totalItems
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "customers": {
                "type": "CustomerList",
                "keyRaw": "customers(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Customer",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "contactName": {
                                        "type": "String",
                                        "keyRaw": "contactName",
                                        "visible": true
                                    },

                                    "balance": {
                                        "type": "String",
                                        "keyRaw": "balance",
                                        "visible": true
                                    },

                                    "accountLimit": {
                                        "type": "Decimal2",
                                        "keyRaw": "accountLimit",
                                        "visible": true
                                    },

                                    "dateEntered": {
                                        "type": "DateTime",
                                        "keyRaw": "dateEntered",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "phoneNumber": {
                                        "type": "String",
                                        "keyRaw": "phoneNumber",
                                        "visible": true
                                    },

                                    "companyName": {
                                        "type": "String",
                                        "keyRaw": "companyName",
                                        "visible": true
                                    },

                                    "store": {
                                        "type": "Store",
                                        "keyRaw": "store",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "faxNumber": {
                                        "type": "String",
                                        "keyRaw": "faxNumber",
                                        "visible": true
                                    },

                                    "mobilePhoneNumber": {
                                        "type": "String",
                                        "keyRaw": "mobilePhoneNumber",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "billingAddress": {
                                        "type": "Address",
                                        "keyRaw": "billingAddress",

                                        "selection": {
                                            "fields": {
                                                "address1": {
                                                    "type": "String",
                                                    "keyRaw": "address1",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "city": {
                                                    "type": "String",
                                                    "keyRaw": "city",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "state": {
                                                    "type": "String",
                                                    "keyRaw": "state",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "zip": {
                                                    "type": "String",
                                                    "keyRaw": "zip",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "country": {
                                                    "type": "String",
                                                    "keyRaw": "country",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "webAddress": {
                                        "type": "String",
                                        "keyRaw": "webAddress",
                                        "visible": true
                                    },

                                    "defaultTerm": {
                                        "type": "Term",
                                        "keyRaw": "defaultTerm",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "salesPerson": {
                                        "type": "UserAccount",
                                        "keyRaw": "salesPerson",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "defaultPaymentMethod": {
                                        "type": "PaymentMethod",
                                        "keyRaw": "defaultPaymentMethod",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "optionValues": {
                                        "type": "CustomerOptionValue",
                                        "keyRaw": "optionValues",

                                        "selection": {
                                            "fields": {
                                                "option": {
                                                    "type": "CustomerOption",
                                                    "keyRaw": "option",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "label": {
                                                                "type": "String",
                                                                "keyRaw": "label",
                                                                "visible": true
                                                            },

                                                            "dataType": {
                                                                "type": "CustomerOptionDataType",
                                                                "keyRaw": "dataType",
                                                                "visible": true
                                                            },

                                                            "showInCustomerList": {
                                                                "type": "Boolean",
                                                                "keyRaw": "showInCustomerList",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "pageInfo": {
                            "type": "PageInfo",
                            "keyRaw": "pageInfo",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginationOptions",
            "filter": "CustomerFilter"
        },

        "types": {
            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            },

            "CustomerFilter": {
                "companyName": "String",
                "contactName": "String",
                "email": "String",
                "keyword": "String",
                "phoneNumber": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b67ca80821cc5d5eb36655dbb904eb7eda59ef23982fe0ad134f7b5aafe32070";