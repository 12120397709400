import type { AppContext } from 'types/common'
import type { Merge, WritableDeep } from 'type-fest'
import type { PermissionValueMap, PermissionValue } from '@isoftdata/svelte-user-configuration'
import type { UserAccount$result, UserConfigurationData$result } from '$houdini'

import { klona } from 'klona'
import { graphql } from '$houdini'
import component from './User.svelte'
import { getSession } from 'stores/session'
import { genericPermissionValueMapReversed } from 'utility/permission'

export type Group = UserConfigurationData$result['groups'][number]
export type GroupPermissionMap = Map<number, Array<Merge<Group['groupPermissions'][number], { value: 'NONE' | 'SITE' | 'GLOBAL' }>>>
export type UserAccount = WritableDeep<
	Omit<
		Merge<
			UserAccount$result['userAccount'],
			{
				id: number | null //Allow null for new user accounts
				newPassword?: string
			}
		>,
		' $fragments'
	>
>

const defaultNewUserAccount = Object.freeze<UserAccount>({
	id: null,
	status: 'ACTIVE',
	name: '',
	workEmail: '',
	recoveryEmail: null,
	firstName: '',
	lastName: '',
	lockNotes: '',
	lastPasswordResetDate: null,
	//userActivationData: null,
	lastAccessDate: null,
	authorizedStores: [],
	groups: [],
	userPermissions: [],
	wageRate: null,
	salesPerson: false,
	driver: false,
	worker: false,
})

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration.user',
		route: 'user',
		querystringParameters: ['storeId', 'userAccountId', 'lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			storeId: () => getSession().currentStore.toString(),
			userAccountId: () => getSession().id.toString(),
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			svelte: true,
			component,
		},
		canLeaveState: domApi => {
			// @ts-expect-error foo
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
			return domApi.canLeaveState()
		},
		async resolve(_data, parameters) {
			const session = getSession()

			const userAccountId = parameters.userAccountId === null ? null : parseInt(parameters.userAccountId, 10) || session.id
			const storeId = parameters.storeId === null ? null : parseInt(parameters.storeId, 10) || session.currentStore
			console.log(userAccountId)
			const { data } = await bigData.fetch({
				variables: { userAccountsFilter: { authorizedStores: storeId ? [storeId] : null } },
			})

			// There will be cases where the userAccountId is not in the list of userAccounts when we change the store filter
			// If that happens, we need to reset the userAccountId to null
			const selectedUserInSite = !!(userAccountId && data?.userAccounts.some(userAccount => userAccount.id === userAccountId))

			let userAccount = klona(defaultNewUserAccount)
			// Create maps with generic 'SITE' key for PLANT permissions
			const permissionValueMap: PermissionValueMap = new Map()
			const groupPermissionMap: GroupPermissionMap = new Map()
			let authorizedSitesSet = new Set<number>(storeId ? [storeId] : [])
			let groupMembershipSet = new Set<number>()

			if (userAccountId && selectedUserInSite) {
				const res = await userAccountQuery.fetch({ variables: { userAccountId } })
				userAccount = res.data?.userAccount ?? userAccount

				for (const userPermission of res.data?.userAccount?.userPermissions ?? []) {
					//The component accepts the generic permission value constants, so map to those
					permissionValueMap.set(userPermission.id, genericPermissionValueMapReversed[userPermission.value])
				}

				for (const group of data?.groups ?? []) {
					groupPermissionMap.set(
						group.groupId,
						group.groupPermissions.map(groupPermission => {
							return {
								...groupPermission,
								value: groupPermission.value === 'Store' ? 'SITE' : (groupPermission.value.toUpperCase() as PermissionValue),
							}
						})
					)
				}

				authorizedSitesSet = new Set(res.data?.userAccount?.authorizedStores.map(store => store.id))
				groupMembershipSet = new Set(res.data?.userAccount?.groups?.map(group => group.id))

				if (userAccount.id) {
					/* Regardless of filter, we need to ensure the userAccount is in the list
					That way if they create a new account with different store access than the selected filter,
					the new user account will still be in the list */
					data?.userAccounts.find(userAccount => userAccount.id === userAccountId) ??
						data?.userAccounts.push({
							id: userAccount.id,
							name: userAccount.name,
							status: userAccount.status,
							lastLoginDate: userAccount.lastAccessDate,
							authorizedStores: userAccount.authorizedStores,
						})
				}
			}

			return {
				stores: data?.stores ?? [],
				groups: data?.groups ?? [],
				permissions: data?.permissions ?? [],
				userAccounts: data?.userAccounts ?? [],
				selectedStoreId: storeId,
				userAccount: userAccount ?? klona(defaultNewUserAccount),
				defaultNewUserAccount,
				hasPermissionToChangePassword: data?.settingValues.security.administratorsCanSetOtherUsersPasswords ?? false,
				authorizedSitesSet,
				permissionValueMap,
				groupPermissionMap,
				groupMembershipSet,
				wageRates: data?.wageRates ?? [],
			}
		},
	})
}

graphql(`
	fragment UserAccountFields on UserAccount {
		id
		name
		firstName
		lastName
		status
		lastAccessDate: lastAccess
		workEmail
		recoveryEmail
		lockNotes
		#apiToken
		lastPasswordResetDate
		groups {
			id
		}
		authorizedStores {
			id
		}
		userPermissions {
			id
			value: level
		}
		wageRate {
			id
		}
		salesPerson
		driver
		worker
	}
`)

const userAccountQuery = graphql(`
	query UserAccount($userAccountId: Int!) {
		userAccount(id: $userAccountId) {
			...UserAccountFields
		}
	}
`)

const bigData = graphql(`
	query UserConfigurationData($userAccountsFilter: UserAccountFilter) {
		userAccounts(filter: $userAccountsFilter) {
			id
			name
			status
			lastLoginDate: lastAccess
			authorizedStores {
				id
			}
		}
		permissions {
			id
			displayName
			description
			codeName
			category
		}
		groups {
			groupId: id
			name
			groupPermissions: permissions {
				permissionId: id
				value: level
			}
		}
		stores {
			id
			name
			code
			phone
			addressService {
				service
				apiKey
			}
			taxService {
				apiKey
				service
			}
		}
		settingValues {
			security {
				administratorsCanSetOtherUsersPasswords
			}
		}
		wageRates {
			id
			name
			overTimeHours
			overTimeMultiplier
			overTimeType
			startDate
			wage
			code
			glCategoryId
		}
	}
`)
