import { sessionWritable } from '@macfja/svelte-persistent-store'
import { get } from 'svelte/store'
import type { LoginMutation$result, PermissionLevel$options } from '$houdini'

const session = sessionWritable<ClientSession | Record<string, never>>('user', {})

export default session

/** Runs `get` from `svelte/store` on the session store. You should only use this in the case where subscribing doesn't make sense. */
export function getSession(): ClientSession | Record<string, never> {
	return get(session)
}

export function isClientSession(session: ClientSession | Record<string, never>): session is ClientSession {
	return 'authToken' in session
}

export type ClientSession = LoginMutation$result['createSession']['userAccount'] & {
	authToken: string
	currentStore: number
	/** A map of permissions to booleans, indicating whether the user has the permission at the logged-in store */
	sessionPermissions: LoginMutation$result['createSession']['permissions']
	/** A map of permissions to permission level */
	userPermissions: Record<string, PermissionLevel$options>
}
