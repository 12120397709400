export default {
    "name": "CustomerCustomFields",
    "kind": "HoudiniQuery",
    "hash": "6058b40ef965e4b0d07bde3e78923ae9e1b0f40cc3300a57362d57b825e0c854",

    "raw": `query CustomerCustomFields {
  customerOptions {
    id
    label
    dataType
    showInCustomerList
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "customerOptions": {
                "type": "CustomerOption",
                "keyRaw": "customerOptions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "label": {
                            "type": "String",
                            "keyRaw": "label",
                            "visible": true
                        },

                        "dataType": {
                            "type": "CustomerOptionDataType",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "showInCustomerList": {
                            "type": "Boolean",
                            "keyRaw": "showInCustomerList",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5440846198be436c906be7e3d2a14606cc2870c992fd45219f3794a5eb3fc823";