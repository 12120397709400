export default {
    "name": "VendorSearch",
    "kind": "HoudiniQuery",
    "hash": "7a3f5b60fed09336092e284ee9181506fcf4f025d9c0e0d6516129ac93fcf466",

    "raw": `query VendorSearch($pagination: PaginationOptions, $filter: VendorFilter) {
  vendors(pagination: $pagination, filter: $filter) {
    pageInfo {
      pageNumber
      pageSize
      totalPages
    }
    totalItems
    items {
      companyName
      contactName
      code
      phoneNumber
      faxNumber
      mobilePhoneNumber
      type
      id
      dateEntered
      email
      webAddress
      active
      billingAddress {
        address1
        city
        country
        state
        zip
      }
      optionValues {
        option {
          id
          name
          type
          showInList
        }
        value
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vendors": {
                "type": "VendorList",
                "keyRaw": "vendors(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "pageInfo": {
                            "type": "PageInfo",
                            "keyRaw": "pageInfo",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        },

                        "items": {
                            "type": "Vendor",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "companyName": {
                                        "type": "String",
                                        "keyRaw": "companyName",
                                        "visible": true
                                    },

                                    "contactName": {
                                        "type": "String",
                                        "keyRaw": "contactName",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "phoneNumber": {
                                        "type": "String",
                                        "keyRaw": "phoneNumber",
                                        "visible": true
                                    },

                                    "faxNumber": {
                                        "type": "String",
                                        "keyRaw": "faxNumber",
                                        "visible": true
                                    },

                                    "mobilePhoneNumber": {
                                        "type": "String",
                                        "keyRaw": "mobilePhoneNumber",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "dateEntered": {
                                        "type": "DateTime",
                                        "keyRaw": "dateEntered",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "webAddress": {
                                        "type": "String",
                                        "keyRaw": "webAddress",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "billingAddress": {
                                        "type": "Address",
                                        "keyRaw": "billingAddress",

                                        "selection": {
                                            "fields": {
                                                "address1": {
                                                    "type": "String",
                                                    "keyRaw": "address1",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "city": {
                                                    "type": "String",
                                                    "keyRaw": "city",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "country": {
                                                    "type": "String",
                                                    "keyRaw": "country",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "state": {
                                                    "type": "String",
                                                    "keyRaw": "state",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "zip": {
                                                    "type": "String",
                                                    "keyRaw": "zip",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "optionValues": {
                                        "type": "VendorOptionValue",
                                        "keyRaw": "optionValues",

                                        "selection": {
                                            "fields": {
                                                "option": {
                                                    "type": "VendorOption",
                                                    "keyRaw": "option",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "type": {
                                                                "type": "VendorOptionType",
                                                                "keyRaw": "type",
                                                                "visible": true
                                                            },

                                                            "showInList": {
                                                                "type": "Boolean",
                                                                "keyRaw": "showInList",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginationOptions",
            "filter": "VendorFilter"
        },

        "types": {
            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "VendorFilter": {
                "active": "Boolean",
                "city": "String",
                "companyName": "String",
                "contactName": "String",
                "country": "String",
                "dateEntered": "DateFilter",
                "email": "String",
                "faxNumber": "String",
                "keyword": "String",
                "phoneNumber": "String",
                "state": "String",
                "stockVendor": "String",
                "streetAddress": "String",
                "type": "String",
                "webAddress": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=995fa26403ba2d07708166d748dc9953ec9d667973870e59c98b6e80739f8c28";