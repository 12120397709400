<script lang="ts">
	import type { PartForClient as Part, PartStoreLocations } from 'utility/load-part'

	import Table, { DraggableRows, Td, type Column, type UuidRowProps } from '@isoftdata/svelte-table'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import { hasPermission } from 'utility/permission'

	import { v4 as uuid } from '@lukeed/uuid'
	import { getEventValue, getEventChecked } from '@isoftdata/browser-event'
	import { tick } from 'svelte'

	export let partQuantity: number
	export let locations: Part['locations']
	export let serialized: boolean
	export let allowVirtualLocations: boolean
	export let partStoreLocations: PartStoreLocations
	export let varianceLocationName: string
	export let storeId: number

	let table: Table<(typeof locations)[number] & UuidRowProps>

	$: quantityAtAllLocations = getQuantityAtAllLocations(locations)
	$: quantityAtVariance = quantityAtAllLocations - partQuantity

	const columns: Array<Column> = [
		{
			name: 'Rank',
			property: 'rank',
			width: '1rem',
			align: 'center',
			defaultSortColumn: true,
			defaultSortDirection: 'ASC',
		},
		{
			property: 'name',
			name: 'Location',
			sortType: false,
			minWidth: '150px',
			width: '50%',
			title: 'The location this item is at.',
		},
		{
			property: 'permanent',
			name: 'Permanent',
			sortType: false,
			width: '1rem',
			title: "Permanent locations don't get removed from the part when the quantity reaches 0.",
		},
		{
			property: 'quantity',
			name: 'Quantity',
			numeric: true,
			sortType: false,
			width: '1rem',
			title: 'The quantity at this particular location.',
		},
		{
			property: 'availableQuantity',
			name: 'Available',
			numeric: true,
			sortType: false,
			width: '1rem',
			title: 'The quantity available at this location.',
		},
		{
			property: 'holdQuantity',
			name: 'Held',
			numeric: true,
			sortType: false,
			width: '1rem',
			title: 'The quantity reserved at this location.',
		},
		{
			name: '',
			property: '',
			icon: 'trash',
			class: 'text-center',
			sortType: false,
			width: '1rem',
			title: 'Mark this part for deletion. It will be deleted on save.',
		},
	]

	// will be called by serial table so we have to export it
	export function addLocation(name = '', locationId: number | null = null, rank?: number) {
		locations.push(newLocation(name, locationId, rank))
		locations = locations
		recalculateVariance()
	}

	export function normalizeRanks() {
		locations
			.sort((a, b) => (a.rank ?? 1) - (b.rank ?? 1))
			.forEach((location, index) => {
				location.rank = index + 1
			})
	}

	function newLocation(name = '', locationId: number | null = null, rank?: number) {
		const location = partStoreLocations.find(location => location.name === name)
		return {
			id: null,
			locationId,
			name,
			description: location?.description ?? '',
			allowInventory: true,
			deleted: false,
			quantity: 1,
			holdQuantity: 0,
			permanent: false,
			rank: rank ?? locations.length + 1,
			uuid: uuid(),
		}
	}

	function getQuantityAtAllLocations(locations: Part['locations']) {
		return locations.reduce((sum, location) => {
			if (location.name === varianceLocationName || location.deleted) {
				return sum
			}
			return sum + location.quantity
		}, 0)
	}

	function recalculateVariance() {
		const newQuantityAtVariance = partQuantity - getQuantityAtAllLocations(locations)

		if (newQuantityAtVariance && newQuantityAtVariance !== quantityAtVariance) {
			const varianceIndex = locations.findIndex(location => location.name === varianceLocationName)
			if (varianceIndex > -1) {
				locations[varianceIndex].quantity = newQuantityAtVariance
			} else {
				const newVarianceLocation = newLocation(varianceLocationName)
				newVarianceLocation.quantity = newQuantityAtVariance
				locations.push(newVarianceLocation)
			}
		} else if (newQuantityAtVariance === 0) {
			const varianceIndex = locations.findIndex(location => location.name === varianceLocationName)
			if (varianceIndex > -1 && !locations[varianceIndex].permanent) {
				locations.splice(varianceIndex, 1)
			} else if (varianceIndex > -1) {
				locations[varianceIndex].quantity = 0
			}
		}
		locations = locations
	}
</script>

<Table
	responsive
	parentClass="mb-1"
	tableId="locationTable"
	idProp="uuid"
	rowSelectionIdProp="uuid"
	rows={locations}
	{columns}
	bind:this={table}
>
	{#snippet body({ rows: currentPageRows, visibleColumnsCount })}
		<DraggableRows
			showRank
			dataType="application/location-table-row"
			{table}
			{columns}
			{currentPageRows}
			rankProperty="rank"
			bind:rows={locations}
		>
			{#snippet children({ row })}
				{@const isVariance = row.name === varianceLocationName}
				<Td
					property="name"
					title={row.description}
				>
					<Autocomplete
						id="location-autocomplete-{row.originalIndex}"
						placeholder="Select {allowVirtualLocations ? 'or create' : ''} a Location..."
						showLabel={false}
						options={partStoreLocations}
						canAddNew={allowVirtualLocations}
						createOption={name => newLocation(name)}
						disabled={row.deleted || isVariance || serialized || !hasPermission('PARTS_CAN_EDIT_LOCATION', storeId) || !hasPermission('PARTS_CAN_EDIT_INFO', storeId)}
						labelParentClass="form-group m-0"
						getLabel={location => location.name}
						value={row}
						on:change={async event => {
							const newLocation = event.detail
							const oldLocation = locations[row.originalIndex]

							locations[row.originalIndex] = {
								...oldLocation,
								locationId: newLocation?.id || null,
								name: newLocation?.name,
								description: newLocation?.description || null,
								id: null,
								uuid: uuid(),
							}
							if (oldLocation.quantity && oldLocation.id) {
								// Keep the old location, but set its quantity to 0 so it gets saved as such
								locations.push({
									...oldLocation,
									quantity: 0,
								})
							}

							locations = locations
							await tick()
							const input = document.getElementById(`location-autocomplete-${row.originalIndex}`)
							if (input && input instanceof HTMLInputElement) {
								input.focus()
							}
						}}
					/>
				</Td>
				<Td
					property="permanent"
					title="Permanent locations don't get removed from the part when the quantity reaches 0."
					class="text-center"
				>
					<Checkbox
						showLabel={false}
						disabled={row.deleted || serialized || !hasPermission('PARTS_CAN_EDIT_LOCATION', storeId) || !hasPermission('PARTS_CAN_EDIT_INFO', storeId)}
						checked={row.permanent}
						on:change={event => {
							locations[row.originalIndex].permanent = getEventChecked(event) ?? false
							recalculateVariance()
						}}
					/>
				</Td>
				<Td property="quantity">
					<Input
						id="quantityInput"
						class="text-right"
						type="number"
						showLabel={false}
						labelParentClass="form-group m-0"
						disabled={row.deleted || isVariance || serialized || !hasPermission('PARTS_CAN_EDIT_LOCATION', storeId) || !hasPermission('PARTS_CAN_EDIT_INFO', storeId)}
						value={row.quantity}
						on:change={event => {
							locations[row.originalIndex].quantity = parseInt(getEventValue(event) ?? '0', 10)
							recalculateVariance()
						}}
					/>
				</Td>
				<Td
					property="availableQuantity"
					class="text-right">{row.quantity - row.holdQuantity}</Td
				>
				<Td
					property="holdQuantity"
					class="text-right">{row.holdQuantity}</Td
				>
				<Td property="">
					<div class="d-flex justify-content-center">
						<Button
							size="sm"
							color="danger"
							outline={!row.deleted}
							disabled={isVariance || serialized || !hasPermission('PARTS_CAN_EDIT_LOCATION', storeId) || !hasPermission('PARTS_CAN_EDIT_INFO', storeId)}
							iconClass={row.deleted ? 'trash-undo' : 'trash'}
							title={isVariance ? 'To remove variance, adjust quantity at other locations.' : row.deleted ? 'Undo delete' : 'Mark this part for deletion. It will be deleted on save'}
							on:click={() => {
								if (locations[row.originalIndex].id) {
									locations[row.originalIndex].deleted = !row.deleted
								} else {
									locations = locations.toSpliced(row.originalIndex, 1)
								}
								recalculateVariance()
							}}
						/>
					</div>
				</Td>
			{/snippet}
		</DraggableRows>
		{#if !locations.length}
			<tr>
				<td
					colspan={visibleColumnsCount}
					class="text-center"
					>No locations, {#if serialized}
						select a location for each serial # for this part.{:else}
						click "New Location" to add a new one.{/if}</td
				>
			</tr>
		{/if}
	{/snippet}
</Table>

{#if !serialized && hasPermission('PARTS_CAN_EDIT_LOCATION', storeId)}
	<div>
		<Button
			outline
			size="sm"
			color="success"
			iconClass="plus"
			disabled={serialized || !hasPermission('PARTS_CAN_EDIT_LOCATION', storeId) || !hasPermission('PARTS_CAN_EDIT_INFO', storeId)}
			on:click={() => addLocation()}>New Location</Button
		>
	</div>
{/if}
