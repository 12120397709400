export default {
    "name": "FieldFlags",
    "kind": "HoudiniFragment",
    "hash": "50a80c986546b0eeb59f7406753299c313cb7027af4ba52cee9c2d8213173b0f",

    "raw": `fragment FieldFlags on InventoryField {
  editable
  global
  required: requiredToSave
  visible
  logChanges
  id
  __typename
}
`,

    "rootType": "InventoryField",
    "stripVariables": [],

    "selection": {
        "fields": {
            "editable": {
                "type": "Boolean",
                "keyRaw": "editable",
                "visible": true
            },

            "global": {
                "type": "Boolean",
                "keyRaw": "global",
                "visible": true
            },

            "required": {
                "type": "Boolean",
                "keyRaw": "required",
                "visible": true
            },

            "visible": {
                "type": "Boolean",
                "keyRaw": "visible",
                "visible": true
            },

            "logChanges": {
                "type": "Boolean",
                "keyRaw": "logChanges",
                "visible": true
            },

            "id": {
                "type": "UInt",
                "keyRaw": "id",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=369d15e16fa72c282ef2092350e26e9619d58c3e53dfe512e217483d1c9e48e5";