<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { WritableDeep } from 'type-fest'
	import type { UserAccount, GroupPermissionMap } from './user.ts'
	import type { PermissionValueMap } from '@isoftdata/svelte-user-configuration'
	import type { UserConfigurationData$result, UserAccount$result, CreateUserAccount$input } from '$houdini'

	type UserAccountList = UserConfigurationData$result['userAccounts']

	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { graphql } from '$houdini'
	import session from 'stores/session'
	import toTitleCase from 'to-title-case'
	import { getContext, onMount } from 'svelte'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Fieldset from '@isoftdata/svelte-fieldset'
	import { getEventValue } from '@isoftdata/browser-event'
	import { genericPermissionValueMap } from 'utility/permission'
	//TODO: eventually we should move the SaveResetButton component up a level to the configuration state(see Presage's Configuration state for an example)
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import { UserConfiguration, getGroupHighestPermissionValueMap } from '@isoftdata/svelte-user-configuration'

	const mediator = getContext<Mediator>('mediator')

	export let asr: SvelteAsr
	export let userAccounts: UserAccountList
	export let selectedStoreId: number | null
	export let hasPermissionToChangePassword = false
	export let stores: UserConfigurationData$result['stores']
	export let groups: UserConfigurationData$result['groups']
	export let permissionValueMap: PermissionValueMap = new Map()
	export let groupPermissionMap: GroupPermissionMap = new Map()
	export let authorizedSitesSet: Set<number> = new Set<number>()
	export let groupMembershipSet: Set<number> = new Set<number>()
	export let wageRates: UserConfigurationData$result['wageRates']
	export let permissions: UserConfigurationData$result['permissions']
	export let userAccount: WritableDeep<UserAccount$result['userAccount']>
	export function canLeaveState() {
		if (hasUnsavedChanges) {
			return confirm('You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.')
		}
		return true
	}

	let usernameInput: HTMLInputElement | undefined = undefined
	let sendPasswordRecoveryToken: boolean = false
	//Make a frozen deep copy of the mutable data so we can compare it later to see if there are any changes
	let originalData = Object.freeze(
		klona({
			userAccount,
			authorizedSitesSet,
			groupMembershipSet,
			permissionValueMap,
		}),
	)

	const absoluteDateTimeFormatter = (date: Date) => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)
	const sortUserAccountList = (userAccounts: UserAccountList) => userAccounts.slice().sort((a, b) => a.name.localeCompare(b.name))
	function getUserAccountsGroupedByStatus(userAccounts: UserAccountList) {
		const groupedUserAccount = sortUserAccountList(userAccounts).reduce((acc: Record<string, UserAccountList>, userAccount) => {
			const status = toTitleCase(userAccount.status)
			if (!acc[status]) {
				acc[status] = []
			}

			acc[status].push(userAccount)
			return acc
		}, {})

		// sort groupedUserAccount by status in ascending order
		return Object.keys(groupedUserAccount)
			.sort()
			.reduce(
				(acc, status) => {
					acc[status] = groupedUserAccount[status]
					return acc
				},
				{} as Record<string, UserAccountList>,
			)
	}
	function error({ heading, message }: { heading: string; message: string }) {
		mediator.publish('showMessage', { heading, type: 'danger', time: false, message })
	}

	function success({ heading, message }: { heading: string; message: string }) {
		mediator.publish('showMessage', { heading, type: 'success', time: 3, message })
	}

	const createUserAccountMutation = graphql(`
		mutation CreateUserAccount($input: NewUserAccount!) {
			createUserAccount(input: $input) {
				...UserAccountFields
			}
		}
	`)

	const updateUserAccountMutation = graphql(`
		mutation UpdateUserAccount($input: UserAccountUpdate!) {
			updateUserAccount(input: $input) {
				...UserAccountFields
			}
		}
	`)

	function getUserAccountForApi(userAccount: UserAccount): CreateUserAccount$input['input'] {
		return {
			firstName: userAccount.firstName ?? '',
			lastName: userAccount.lastName ?? '',
			name: userAccount.name,
			userGroupIds: Array.from(groupMembershipSet),
			authorizedStoreIds: Array.from(authorizedSitesSet),
			userPermissions: Array.from(permissionValueMap).map(([id, level]) => ({ id, level: genericPermissionValueMap[level] })),
			lockNotes: userAccount.lockNotes,
			workEmail: userAccount.workEmail,
			isWorker: userAccount.worker,
			isDriver: userAccount.driver,
			isSalesPerson: userAccount.salesPerson,
			wageRateId: userAccount.wageRate?.id ?? null,
		}
	}

	async function createNewUserAccount(userAccount: UserAccount, hasPermissionToChangePassword: boolean) {
		if (!userAccount.name) {
			throw new Error('You must set a username to create a new user account.')
		}
		if (hasPermissionToChangePassword && !userAccount.newPassword) {
			throw new Error('You must set a password to create a new user account.')
		}

		const res = await createUserAccountMutation.mutate({
			input: {
				...getUserAccountForApi(userAccount),
				newPassword: userAccount.newPassword ?? null,
			},
		})

		return res.data?.createUserAccount.id
	}

	async function updateUserAccount(userAccount: UserAccount, sendPasswordRecoveryToken: boolean) {
		if (userAccount.id) {
			await updateUserAccountMutation.mutate({
				input: {
					...getUserAccountForApi(userAccount),
					id: userAccount.id,
					sendPasswordRecoveryToken,
					status: userAccount.status,
				},
			})
		}
	}

	async function saveChanges() {
		try {
			let asrParams: { lastSavedTime: number | null; lastResetTime: number | null; userAccountId?: number } = { lastSavedTime: Date.now(), lastResetTime: null }
			if (userAccount.id === null) {
				const userAccountId = await createNewUserAccount(userAccount, hasPermissionToChangePassword)
				asrParams.userAccountId = userAccountId
			} else {
				await updateUserAccount(userAccount, sendPasswordRecoveryToken)
			}
			//Reset the originalData so we don't have unsaved changes before we call go on asr
			originalData = Object.freeze(
				klona({
					userAccount,
					authorizedSitesSet,
					groupMembershipSet,
					permissionValueMap,
				}),
			)
			asr.go(null, asrParams, { inherit: true })
		} catch (err) {
			if (err instanceof Error) {
				error({ heading: 'Error', message: err.message })
			} else {
				console.error(err)
			}
		}
	}

	$: userAccountsGroupedByStatus = getUserAccountsGroupedByStatus(userAccounts)
	$: userSites = stores.map(({ id, code, name }) => {
		return { id, code, name, isAuthorized: authorizedSitesSet.has(id) }
	})
	$: groupMembership = groups.map(({ groupId, name }) => {
		return { id: groupId, name: name ?? '', isMember: groupMembershipSet.has(groupId) }
	})
	$: groupPermissionValueMap = getGroupHighestPermissionValueMap(groupMembershipSet, groupPermissionMap)
	$: hasUnsavedChanges = !dequal(originalData, {
		userAccount,
		authorizedSitesSet,
		groupMembershipSet,
		permissionValueMap,
	})

	onMount(() => {
		if (userAccount.id === null) {
			usernameInput?.focus()
		}
	})

	$: console.log(userAccount)
</script>

<div class="form-row flex-wrap align-items-end mb-2">
	<div class="col-sm-6 col-md-6 col-lg-3 order-1">
		<Select
			label="Store Filter"
			value={selectedStoreId}
			on:change={e => asr.go(null, { storeId: getEventValue(e) }, { inherit: true })}
		>
			{#each stores as store}
				<option value={store.id}>{store.code} - {store.name}</option>
			{/each}
		</Select>
	</div>
	<div class="col-12 col-sm-6 col-md-6 col-lg-4 order-2">
		<Select
			label="User Account"
			value={userAccount.id}
			on:change={e => asr.go(null, { userAccountId: getEventValue(e) }, { inherit: true })}
			emptyText={userAccount.id === null ? (userAccount.name ? userAccount.name : 'New Account') : '-- Select User Account --'}
		>
			{#each Object.keys(userAccountsGroupedByStatus) as statusKey}
				<optgroup label={statusKey}>
					{#each userAccountsGroupedByStatus[statusKey] as { id, name, lastLoginDate }}
						<option value={id}>{name}{$session.userAccountId === id ? ' (You)' : ''}{lastLoginDate ? ` - Last Accessed on ${lastLoginDate}` : ''}</option>
					{/each}
				</optgroup>
			{/each}
		</Select>
	</div>
	<div class="col-12 col-sm-auto mt-2 mt-lg-0 mb-1 order-3">
		{#if userAccount.id !== null}
			<Button
				outline
				size="sm"
				color="success"
				iconClass="plus"
				on:click={() => {
					asr.go(null, { userAccountId: null }, { inherit: true })
				}}>New Account</Button
			>
		{/if}
	</div>
	<div class="mb-1 ml-md-auto order-0 order-md-4">
		<SaveResetButton
			disabled={!hasUnsavedChanges}
			resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSaveTime: null }, { inherit: true })}
			on:saveClick={saveChanges}
		/>
	</div>
</div>

<UserConfiguration
	siteLabel="Store"
	bind:userAccount
	bind:usernameInput
	bind:doSendPasswordRecoveryToken={sendPasswordRecoveryToken}
	{userSites}
	{groupMembership}
	{permissions}
	{permissionValueMap}
	{groupPermissionValueMap}
	{hasPermissionToChangePassword}
	{error}
	{success}
	siteAccessChange={async site => {
		site.isAuthorized ? authorizedSitesSet.add(site.id) : authorizedSitesSet.delete(site.id)
		authorizedSitesSet = new Set(authorizedSitesSet)
	}}
	groupMembershipChange={async ({ id, isMember }) => {
		//The user can't adjust the group permissions in this UI, and we've got the permissions for each group in a Map in memory, so we just need to update their groupMembershipSet here
		isMember ? groupMembershipSet.add(id) : groupMembershipSet.delete(id)
		//Just doing mySet = mySet doesn't seem to trigger a reactivity update, so we need to create a new Set. Ugh.
		//This was the only workaround I could find that worked. I also tried using SvelteSet, and writing groupMembershipSet to a tmp var and then assigning that tmp to groupMembershipSet, but neither worked.
		//But also I tried to reproduce this in a Svelte 5 playground and could not, so I'm not sure what's going on here.
		groupMembershipSet = new Set(groupMembershipSet)
	}}
	permissionValueChange={async ({ permissionIds, value }) => {
		for (const id of permissionIds) {
			permissionValueMap.set(id, value)
		}
		permissionValueMap = new Map(permissionValueMap)
	}}
>
	<svelte:fragment slot="userAccountInfo">
		<Select
			label="Wage Rate"
			labelClass="pt-1"
			value={userAccount.wageRate?.id ?? null}
			on:change={e => {
				const value = getEventValue(e)
				if (value) {
					userAccount.wageRate = {
						id: parseInt(value, 10),
					}
				} else {
					userAccount.wageRate = null
				}
			}}
		>
			{#each wageRates as { id, name }}
				<option value={id}>{name}</option>
			{/each}
		</Select>
		<Fieldset
			label="Roles"
			class="mt-3"
		>
			<div class="pl-2 d-flex flex-wrap flex-column">
				<div>
					<Checkbox
						label="Salesperson"
						bind:checked={userAccount.salesPerson}
					/>
				</div>
				<div>
					<Checkbox
						label="Delivery Driver"
						bind:checked={userAccount.driver}
					/>
				</div>
				<div>
					<Checkbox
						label="Work Order Mechanic"
						bind:checked={userAccount.worker}
					/>
				</div>
			</div>
		</Fieldset>
	</svelte:fragment>
</UserConfiguration>
