<script lang="ts">
	import { formatCustomerCurrency, type Customer } from 'utility/customer-helper'
	import type { SvelteAsr } from 'types/common'
	import { onMount, type ComponentProps } from 'svelte'
	import { datesFromRange } from '@isoftdata/utility-date-time'
	import { endOfDay, parseISO, startOfDay } from 'date-fns'

	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import DateRange from '@isoftdata/svelte-date-range'
	import Fieldset from '@isoftdata/svelte-fieldset'
	import NavTabBar from '@isoftdata/svelte-nav-tab-bar'
	import ReportJobModal from 'components/ReportJobModal.svelte'
	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import { hasPermission } from 'utility/permission'
	import { graphql } from '$houdini'

	const salesOrderColumn: Column[] = [
		{ property: 'id', name: 'Invoice #', align: 'right' },
		{ property: 'purchaseOrderNumber', name: 'PO #', align: 'right' },
		{ property: 'date', name: 'Date' },
		{ property: 'documentType[name]', name: 'Type' },
		{ property: 'subtotal', name: 'Subtotal', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'tax', name: 'Tax', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'total', name: 'Total', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'adjustmentTotal', name: 'Adjustments', align: 'right' },
		{ property: 'paid', name: 'Paid', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'balance', name: 'Balance', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'counterPerson[name]', name: 'Counterperson' },
		{ property: 'salesperson[name]', name: 'Salesperson' },
		{ property: 'shippingAddress[company]', name: 'Shipped To' },
		{ property: 'status', name: 'Status' },
	]

	const lineItemColumn: Column[] = [
		{ property: 'lookup', name: 'Lookup' },
		{ property: 'inventory[vehicle][stockNumber]', name: 'Stock #', align: 'right' },
		{ property: 'inventory[defaultVendor][code]', name: 'Inv. Vendor' },
		{ property: 'quantity', name: 'Qty', align: 'right' },
		{ property: 'description', name: 'Description' },
		{ property: 'price', name: 'Price', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'total', name: 'Total', align: 'right', sortType: 'ALPHA_NUM' },
	]

	const paymentColumn: Column[] = [
		{ property: 'payment[id]', name: 'Payment #' },
		{ property: 'payment[date]', name: 'Date' },
		{ property: 'payment[paymentMethod][name]', name: 'Method' },
		{ property: 'amount', name: 'Amount', align: 'right', sortType: 'ALPHA_NUM' },
		{ property: 'payment[documentNumber]', name: 'Check/Auth #', align: 'right' },
		{ property: 'payment[comments]', name: 'Comments' },
	]

	const adjustmentColumn: Column[] = [
		{ property: 'id', name: '#' },
		{ property: 'date', name: 'Date' },
		{ property: 'amount', name: 'Amount', align: 'right' },
		{ property: 'adjustmentType[name]', name: 'Type' },
	]

	export let asr: SvelteAsr
	export let customer: Customer

	let customerSalesOrders: Customer['salesOrders'] = []
	let selectedSalesOrder: Customer['salesOrders'][number] | null = null
	let dates: ComponentProps<DateRange>['dates'] = undefined
	let range: ComponentProps<DateRange>['range'] = 'Last 90 Days'
	let showVoidSales = false
	let showOpenSales = false
	let showClosedSales = true
	let isLoadingSalesOrders = false

	let perPageCount: number = 6
	let currentPageNumber = 1
	let totalItemsCount: number | undefined = undefined

	/*
		If we want the salesTab to be persisted across page reloads, we should be able to do "asr.getActiveState().parameters.salesTab".
		And since it is reactive, we could just do $:salesTab = asr.getActiveState().parameters.salesTab
	*/
	let salesTab: 'LINE_ITEM' | 'PAYMENT' | 'ADJUSTMENT' = 'LINE_ITEM'
	let selectedPaymentLine: { id: number; paymentId: number; paymentStoreId: number } | null = null
	let reportJobModalComponent: ReportJobModal

	async function getCustomerSalesOrders(filter: { dates: { from: string; to: string }; void: boolean; finalized: boolean | null }, customerId: number, pageNumber: number = 1) {
		isLoadingSalesOrders = true
		// for the void filter, the API is setup to accept null to show all sales orders, true to show all voided sales orders, and false to show all sales orders that has not been voided
		const { data } = await customerSalesOrdersQuery.fetch({
			variables: {
				customerId,
				filter: {
					date: { gte: startOfDay(parseISO(filter.dates.from)), lte: endOfDay(parseISO(filter.dates.to)) },
					void: filter.void ? null : false,
					finalized: filter.finalized,
				},
				pagination: {
					pageNumber,
					pageSize: 6, // so that the user can see the card at the bottom
				},
			},
		})

		if (!data) {
			throw new Error('Failed to load sales orders')
		}

		customerSalesOrders = data.customer.salesOrders.items.map(salesOrder => ({
			...salesOrder,
			storeId: salesOrder.store.id,
			status: salesOrder.void ? 'Void' : salesOrder.finalized ? 'Closed' : 'Open',
		}))
		selectedSalesOrder = null
		selectedPaymentLine = null
		perPageCount = data.customer.salesOrders.pageInfo.pageSize
		currentPageNumber = data.customer.salesOrders.pageInfo.pageNumber
		totalItemsCount = data.customer.salesOrders.totalItems

		isLoadingSalesOrders = false
	}

	const customerSalesOrdersQuery = graphql(`
		query CustomerSalesOrders($customerId: UInt!, $filter: SalesOrderFilter, $pagination: PaginationOptions) {
			customer(id: $customerId) {
				salesOrders(filter: $filter, pagination: $pagination) {
					items {
						salesOrderId
						adjustmentTotal
						adjustments {
							adjustmentType {
								id
								name
								code
								showOnSalesOrders
							}
							amount
							date
							id
						}
						appliedPaymentLines {
							amount
							id
							payment {
								comments
								date
								documentNumber
								id
								storeId
								paymentMethod {
									id
									name
								}
							}
						}
						balance
						counterPerson {
							id
							name
						}
						date
						documentType {
							id
							name
						}
						finalized
						id
						lines {
							description
							id
							inventory {
								vehicle {
									stockNumber
								}
								defaultVendor {
									id
									code
								}
							}
							lookup
							price
							quantity
							total
						}
						paid
						purchaseOrderNumber
						salesperson {
							id
							name
						}
						shippingAddress {
							company
						}
						store {
							id
						}
						subtotal
						tax
						total
						void
					}
					pageInfo {
						pageNumber
						pageSize
						totalPages
					}
					totalItems
				}
			}
		}
	`)

	onMount(async () => {
		if (customer.id) {
			if (range && range !== 'Custom') {
				dates = datesFromRange(range)
				await getCustomerSalesOrders({ dates, void: showVoidSales, finalized: showOpenSales && showClosedSales ? null : !showOpenSales }, customer.id)
			}
		}
	})
</script>

{#if !hasPermission('SALES_ORDERS_CAN_VIEW', customer.store?.id)}
	<div class="alert alert-warning text-center">You do not have permission to view sales orders. Please contact your admin for further assistance.</div>
{:else}
	<div class="card">
		<div class="card-header">
			<h4 class="mb-0">Sales</h4>
		</div>
		<div class="card-body">
			<div class="form-row align-items-end mb-1">
				<div class="col-12 col-md-9">
					<DateRange
						rangeColClass="col-12 col-md-4 mb-md-0"
						fromColClass="col-6 col-md-4 mb-md-0"
						toColClass="col-6 col-md-4 mb-md-0"
						label="Date Range"
						bind:dates
						bind:range
						disabled={isLoadingSalesOrders}
						on:change={async () => {
							if (customer.id && dates) {
								await getCustomerSalesOrders({ dates, void: showVoidSales, finalized: showOpenSales && showClosedSales ? null : !showOpenSales }, customer.id)
							}
						}}
					/>
				</div>
				<div class="col-12 col-md-3">
					<Fieldset label="Show">
						<Checkbox
							inline
							label="Void"
							bind:checked={showVoidSales}
							on:change={async () => {
								if (customer.id && dates) {
									await getCustomerSalesOrders({ dates, void: showVoidSales, finalized: showOpenSales && showClosedSales ? null : !showOpenSales }, customer.id)
								}
							}}
						/>
						<Checkbox
							inline
							label="Open"
							bind:checked={showOpenSales}
							on:change={async () => {
								if (customer.id && dates) {
									await getCustomerSalesOrders({ dates, void: showVoidSales, finalized: showOpenSales && showClosedSales ? null : !showOpenSales }, customer.id)
								}
							}}
						/>
						<Checkbox
							inline
							label="Closed"
							bind:checked={showClosedSales}
							on:change={async () => {
								if (customer.id && dates) {
									await getCustomerSalesOrders({ dates, void: showVoidSales, finalized: showOpenSales && showClosedSales ? null : !showOpenSales }, customer.id)
								}
							}}
						/>
					</Fieldset>
				</div>
			</div>
			{#if isLoadingSalesOrders}
				<div class="text-center">
					<i class="fas fa-spinner fa-spin fa-2x"></i>
				</div>
			{:else}
				<Table
					responsive
					stickyHeader
					columnHidingEnabled
					columnResizingEnabled
					tableId="customer-sales-orders"
					localStorageKey="customer-sales-orders"
					columns={salesOrderColumn}
					rows={customerSalesOrders}
					{perPageCount}
					{currentPageNumber}
					{totalItemsCount}
					pageChange={async ({ pageNumber }) => {
						currentPageNumber = pageNumber
						if (customer.id && dates) {
							await getCustomerSalesOrders({ dates, void: showVoidSales, finalized: showOpenSales && showClosedSales ? null : !showOpenSales }, customer.id, currentPageNumber)
						}
					}}
				>
					{#snippet noRows({ visibleColumnsCount })}
						<tr>
							<td
								colspan={visibleColumnsCount}
								class="text-center"
							>
								No sales order found
							</td>
						</tr>
					{/snippet}
					{#snippet children({ row })}
						<tr
							class="cursor-pointer"
							class:text-muted={row.status === 'Void'}
							class:table-primary={selectedSalesOrder === row}
							on:click={() => {
								selectedSalesOrder = row
								selectedPaymentLine = null
							}}
						>
							<Td property="id">
								{row.id}
							</Td>
							<Td property="purchaseOrderNumber">
								{row.purchaseOrderNumber ?? 'N/A'}
							</Td>
							<Td property="date">
								{row.date.toLocaleDateString()}
							</Td>
							<Td property="documentType[name]">
								{row.documentType?.name ?? 'N/A'}
							</Td>
							<Td property="subtotal">
								{formatCustomerCurrency(row.subtotal)}
							</Td>
							<Td property="tax">
								{formatCustomerCurrency(row.tax)}
							</Td>
							<Td property="total">
								{formatCustomerCurrency(row.total)}
							</Td>
							<Td property="adjustmentTotal">
								{formatCustomerCurrency(row.adjustmentTotal)}
							</Td>
							<Td property="paid">
								{formatCustomerCurrency(row.paid)}
							</Td>
							<Td property="balance">
								{formatCustomerCurrency(row.balance)}
							</Td>
							<Td
								property="counterPerson[name]"
								class="text-nowrap"
							>
								{row.counterPerson?.name ?? 'N/A'}
							</Td>
							<Td
								property="salesperson[name]"
								class="text-nowrap"
							>
								{row.salesperson?.name ?? 'N/A'}
							</Td>
							<Td
								property="shippingAddress[company]"
								class="text-nowrap"
							>
								{row.shippingAddress?.company ?? 'N/A'}
							</Td>
							<Td
								property="status"
								class="text-nowrap"
							>
								{#if row.status === 'Void'}
									{row.status} <i class="fas fa-ban text-danger mr-1"></i>
								{:else}
									{row.status}
								{/if}
							</Td>
						</tr>
					{/snippet}
				</Table>
			{/if}
		</div>
		<div class="card-footer">
			<Button
				outline
				size="sm"
				color="primary"
				iconClass="print"
				disabled={!selectedSalesOrder || isLoadingSalesOrders}
				on:click={() => {
					if (selectedSalesOrder) {
						reportJobModalComponent.open({ type: 'Sales Order', parameters: { salesorderid: selectedSalesOrder.salesOrderId.toString(), storeid: selectedSalesOrder.storeId.toString() } })
					}
				}}
			>
				Print
			</Button>
		</div>
	</div>

	<div class="card mt-3">
		<div class="card-header">
			<h4>Sale {selectedSalesOrder ? `#${selectedSalesOrder.id}` : ''}</h4>
			<NavTabBar
				tabs={[
					{ name: 'LINE_ITEM', title: 'Line Items' },
					{ name: 'PAYMENT', title: 'Payment' },
					{ name: 'ADJUSTMENT', title: 'Adjustments' },
				]}
				bind:selectedTab={salesTab}
				on:tabChange={() => asr.go(null, { salesTab }, { inherit: true })}
			/>
		</div>
		<div class="card-body p-1">
			{#if !selectedSalesOrder}
				<div class="text-center p-3">
					<h3>Select a sales order to view {salesTab === 'LINE_ITEM' ? 'line items' : salesTab === 'PAYMENT' ? 'payments' : 'adjustments'}</h3>
				</div>
			{:else if isLoadingSalesOrders}
				<div class="text-center">
					<i class="fas fa-spinner fa-spin fa-2x"></i>
				</div>
			{:else if salesTab === 'LINE_ITEM'}
				<Table
					responsive
					columns={lineItemColumn}
					rows={selectedSalesOrder.lines}
				>
					{#snippet noRows({ visibleColumnsCount })}
						<tr>
							<td
								colspan={visibleColumnsCount}
								class="text-center"
							>
								No line items found for the selected sales order
							</td>
						</tr>
					{/snippet}

					{#snippet children({ row })}
						<tr>
							<Td property="lookup">
								{row.lookup}
							</Td>
							<Td property="inventory[vehicle][stockNumber]">
								{row.inventory?.vehicle?.stockNumber ?? ''}
							</Td>
							<Td property="inventory[defaultVendor][code]">
								{row.inventory?.defaultVendor?.code ?? ''}
							</Td>
							<Td property="quantity">
								{row.quantity}
							</Td>
							<Td property="description">
								{row.description}
							</Td>
							<Td property="price">
								{formatCustomerCurrency(row.price)}
							</Td>
							<Td property="total">
								{formatCustomerCurrency(row.total)}
							</Td>
						</tr>
					{/snippet}
				</Table>
			{:else if salesTab === 'PAYMENT'}
				<Table
					responsive
					columns={paymentColumn}
					rows={selectedSalesOrder.appliedPaymentLines}
				>
					{#snippet noRows({ visibleColumnsCount })}
						<tr>
							<td
								colspan={visibleColumnsCount}
								class="text-center"
							>
								No payments found for the selected sales order
							</td>
						</tr>
					{/snippet}
					{#snippet children({ row })}
						<tr
							class="cursor-pointer"
							class:table-primary={selectedPaymentLine?.id === row.id}
							on:click={() => {
								selectedPaymentLine = {
									id: row.id,
									paymentId: row.payment.id,
									paymentStoreId: row.payment.storeId,
								}
							}}
						>
							<Td property="payment[id]">
								{row.payment.id}
							</Td>
							<Td property="payment[date]">
								{row.payment.date.toLocaleDateString()}
							</Td>
							<Td property="payment[paymentMethod][name]">
								{row.payment.paymentMethod.name}
							</Td>
							<Td property="amount">
								{formatCustomerCurrency(row.amount)}
							</Td>
							<Td property="payment[documentNumber]">
								{row.payment.documentNumber}
							</Td>
							<Td property="payment[comments]">
								{row.payment.comments}
							</Td>
						</tr>
					{/snippet}
				</Table>
			{:else if salesTab === 'ADJUSTMENT'}
				<Table
					responsive
					columns={adjustmentColumn}
					rows={selectedSalesOrder.adjustments}
				>
					{#snippet noRows({ visibleColumnsCount })}
						<tr>
							<td
								colspan={visibleColumnsCount}
								class="text-center"
							>
								No adjustment found for selected sales order
							</td>
						</tr>
					{/snippet}
					{#snippet children({ row })}
						<tr>
							<Td property="id">
								{row.id}
							</Td>
							<Td property="date">
								{row.date.toLocaleDateString()}
							</Td>
							<Td property="amount">
								{formatCustomerCurrency(row.amount)}
							</Td>
							<Td property="adjustmentType[name]">
								{row.adjustmentType.name}
							</Td>
						</tr>
					{/snippet}
				</Table>
			{/if}
		</div>
		{#if salesTab === 'PAYMENT'}
			<div class="card-footer">
				<Button
					outline
					size="sm"
					color="primary"
					iconClass="print"
					disabled={!selectedPaymentLine || isLoadingSalesOrders}
					on:click={() => {
						if (selectedPaymentLine) {
							reportJobModalComponent.open({ type: 'Payment Receipt', parameters: { paymentid: selectedPaymentLine.paymentId.toString(), storeid: selectedPaymentLine.paymentStoreId.toString() } })
						}
					}}
				>
					Print
				</Button>
			</div>
		{/if}
	</div>
{/if}

<ReportJobModal bind:this={reportJobModalComponent} />
