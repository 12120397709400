export default {
    "name": "VendorLookUp",
    "kind": "HoudiniQuery",
    "hash": "bed76cca62541b05eb1fe65b2aa87c12ba275edadd0e66f9f0dbab09c129db4e",

    "raw": `query VendorLookUp($vendorId: UInt!) {
  vendor(id: $vendorId) {
    addresses {
      vendorAddressId
      address1
      city
      company
      contact
      country
      department
      email
      faxNumber
      lastValidated
      mobilePhoneNumber
      notes
      phone
      primary
      state
      title
      zip
    }
    attachments {
      id
      public
      rank
      file {
        id
        created
        extension
        mimetype
        name
        hash
        type
        updated
        path
        size
        host
        url
      }
    }
    billingAddress {
      address1
      city
      company
      contact
      country
      email
      lastValidated
      phone
      state
      zip
    }
    accountNumber
    active
    code
    companyName
    contactName
    dateEntered
    defaultGLCategory {
      id
      name
    }
    defaultPaymentMethod {
      id
      name
    }
    defaultTerm {
      id
      name
    }
    dunsNumber
    email
    faxNumber
    id
    mobilePhoneNumber
    name
    notes
    orderingGroup
    phoneNumber
    type
    webAddress
    customFields: optionValues {
      value
      option {
        id
        name
        showInList
        type
      }
    }
    purchaseAgent {
      id
      name
    }
    purchaseRegion {
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vendor": {
                "type": "Vendor",
                "keyRaw": "vendor(id: $vendorId)",

                "selection": {
                    "fields": {
                        "addresses": {
                            "type": "VendorAddress",
                            "keyRaw": "addresses",

                            "selection": {
                                "fields": {
                                    "vendorAddressId": {
                                        "type": "UInt",
                                        "keyRaw": "vendorAddressId",
                                        "visible": true
                                    },

                                    "address1": {
                                        "type": "String",
                                        "keyRaw": "address1",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "company": {
                                        "type": "String",
                                        "keyRaw": "company",
                                        "visible": true
                                    },

                                    "contact": {
                                        "type": "String",
                                        "keyRaw": "contact",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "department": {
                                        "type": "String",
                                        "keyRaw": "department",
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "faxNumber": {
                                        "type": "String",
                                        "keyRaw": "faxNumber",
                                        "visible": true
                                    },

                                    "lastValidated": {
                                        "type": "DateTime",
                                        "keyRaw": "lastValidated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "mobilePhoneNumber": {
                                        "type": "String",
                                        "keyRaw": "mobilePhoneNumber",
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "phone": {
                                        "type": "String",
                                        "keyRaw": "phone",
                                        "visible": true
                                    },

                                    "primary": {
                                        "type": "Boolean",
                                        "keyRaw": "primary",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "title": {
                                        "type": "String",
                                        "keyRaw": "title",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachments": {
                            "type": "VendorAttachment",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "extension": {
                                                    "type": "String",
                                                    "keyRaw": "extension",
                                                    "visible": true
                                                },

                                                "mimetype": {
                                                    "type": "String",
                                                    "keyRaw": "mimetype",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "hash": {
                                                    "type": "String",
                                                    "keyRaw": "hash",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "Date",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "size": {
                                                    "type": "Int",
                                                    "keyRaw": "size",
                                                    "visible": true
                                                },

                                                "host": {
                                                    "type": "String",
                                                    "keyRaw": "host",
                                                    "visible": true
                                                },

                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "billingAddress": {
                            "type": "Address",
                            "keyRaw": "billingAddress",

                            "selection": {
                                "fields": {
                                    "address1": {
                                        "type": "String",
                                        "keyRaw": "address1",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "company": {
                                        "type": "String",
                                        "keyRaw": "company",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "contact": {
                                        "type": "String",
                                        "keyRaw": "contact",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastValidated": {
                                        "type": "DateTime",
                                        "keyRaw": "lastValidated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "phone": {
                                        "type": "String",
                                        "keyRaw": "phone",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "accountNumber": {
                            "type": "String",
                            "keyRaw": "accountNumber",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "companyName": {
                            "type": "String",
                            "keyRaw": "companyName",
                            "visible": true
                        },

                        "contactName": {
                            "type": "String",
                            "keyRaw": "contactName",
                            "visible": true
                        },

                        "dateEntered": {
                            "type": "DateTime",
                            "keyRaw": "dateEntered",
                            "nullable": true,
                            "visible": true
                        },

                        "defaultGLCategory": {
                            "type": "GlCategory",
                            "keyRaw": "defaultGLCategory",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultPaymentMethod": {
                            "type": "PaymentMethod",
                            "keyRaw": "defaultPaymentMethod",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultTerm": {
                            "type": "Term",
                            "keyRaw": "defaultTerm",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "dunsNumber": {
                            "type": "String",
                            "keyRaw": "dunsNumber",
                            "visible": true
                        },

                        "email": {
                            "type": "String",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "faxNumber": {
                            "type": "String",
                            "keyRaw": "faxNumber",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "mobilePhoneNumber": {
                            "type": "String",
                            "keyRaw": "mobilePhoneNumber",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "visible": true
                        },

                        "orderingGroup": {
                            "type": "String",
                            "keyRaw": "orderingGroup",
                            "visible": true
                        },

                        "phoneNumber": {
                            "type": "String",
                            "keyRaw": "phoneNumber",
                            "visible": true
                        },

                        "type": {
                            "type": "String",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "webAddress": {
                            "type": "String",
                            "keyRaw": "webAddress",
                            "visible": true
                        },

                        "customFields": {
                            "type": "VendorOptionValue",
                            "keyRaw": "customFields",

                            "selection": {
                                "fields": {
                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "option": {
                                        "type": "VendorOption",
                                        "keyRaw": "option",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "showInList": {
                                                    "type": "Boolean",
                                                    "keyRaw": "showInList",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "VendorOptionType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "purchaseAgent": {
                            "type": "UserAccount",
                            "keyRaw": "purchaseAgent",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "purchaseRegion": {
                            "type": "PurchaseRegion",
                            "keyRaw": "purchaseRegion",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "vendorId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6664e0a3a40879b2ab8400f053bd74c86cda105c8ad41810059114f4bf1f92ee";