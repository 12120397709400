<script lang="ts">
	interface ServerInformation {
		releaseVersionNumber?: string
		schemaVersion?: string
	}

	import Login from '@isoftdata/svelte-login'
	import { getContext } from 'svelte'
	import type { ComponentProps } from 'svelte'
	import debugLog from 'debug'
	import type { Mediator } from 'types/mediator'
	import sessionStore, { type ClientSession } from 'stores/session'

	import { CloseSessionMutationStore, graphql, LoginMutationStore, type PermissionLevel$options } from '$houdini'

	debugLog('enterpriseweb:login')

	const mediator: Mediator = getContext('mediator')

	export let storeList: ComponentProps<Login>['siteList']
	export let selectedStoreId: number
	export let username = ''
	export let isLoading = false
	export let serverInformation: Promise<ServerInformation>
	export let rememberMe = false
	export let asr
	let loginComponent: Login

	function saveRememberMe(rememberMe, username) {
		if (rememberMe) {
			localStorage.setItem('lastUsername', username)
		} else {
			localStorage.removeItem('lastUsername')
		}
	}

	async function login(event) {
		const { username, password, rememberMe, siteId } = event.detail
		debugLog('login fired')
		isLoading = true
		try {
			const { data } = await loginMutation.mutate({
				username,
				passphrase: password,
				selectedStoreId: parseInt(siteId, 10),
			})

			if (!data) {
				throw new Error('No data returned from login mutation')
			}

			const { createSession: userSession } = data
			debugLog(userSession)

			localStorage.setItem('lastStoreId', siteId)
			saveRememberMe(rememberMe, username)

			if (!userSession.token) {
				throw new Error('No token returned from login mutation')
			}

			/* check if user has WEB_CAN_LOGIN*/
			if (userSession.permissions?.WEB_CAN_LOGIN) {
				if (!userSession.storeId) {
					throw new Error('Session does not have a store selected')
				}

				const user: ClientSession = {
					...userSession.userAccount,
					currentStore: userSession.storeId,
					sessionPermissions: userSession.permissions,
					authToken: userSession.token,
					userPermissions:
						userSession.userAccount.highestAccessPermissions.reduce((acc: Record<string, PermissionLevel$options>, { codeName, level }) => {
							acc[codeName] = level
							return acc
						}, {}) ?? {},
				}

				sessionStore.set(user)
				asr.go('app')
			} else {
				//need to cancel session
				await closeSessionMutation.mutate(null)
				isLoading = false
				//ractive.findComponent('login').invalidLogin("User does not have login permission for Enterprise Web")
				loginComponent.invalidLogin('User does not have login permission for Enterprise Web')
			}
		} catch (err) {
			isLoading = false
			console.log(err)
			if (Array.isArray(err)) {
				const alert = err.reduce(
					(acc: { message: string; subMessages: string[] }, e, index) => {
						if (index === 0) {
							acc.message = e.message
						} else {
							acc.subMessages.push(e.message)
						}
						return acc
					},
					{ message: '', subMessages: [] },
				)

				loginComponent.setAlert(alert)
			} else if ((err as Error)?.message) {
				loginComponent.invalidLogin((err as Error)?.message || 'An unknown error occured')
			}
		}
	}

	// #region GraphQL Queries/Mutations
	graphql`
		fragment Permissions on Session {
			permissions {
				WEB_CAN_LOGIN
				VEHICLES_CAN_EDIT_STOCK_NUMBER
				VEHICLES_CAN_EDIT_STATUS
				VEHICLES_CAN_EDIT_BASIC
				VEHICLES_CAN_EDIT_WHOLE_UNIT_ADVANCED
				VEHICLES_CAN_EDIT_ADDITIONAL_INFORMATION
				VEHICLES_CAN_EDIT_QANDA
				VEHICLES_CAN_EDIT_WHOLE_UNITS
				VEHICLES_CAN_EDIT_GL_CATEGORY
				VEHICLES_CAN_EDIT_ATTACHMENTS
				VEHICLES_CAN_EDIT_BIDS
				VEHICLES_CAN_EDIT_NON_BID_UNITS
				VEHICLES_CAN_VIEW_VEHICLE_PERFORMANCE
				VEHICLES_CAN_CHANGE_STOCK_QUANTITY
				SEARCH_CAN_SEARCH_VEHICLES
				DASHBOARD_REPORT_VIEW
				DASHBOARD_REPORT_EDIT_OWN
				DASHBOARD_REPORT_EDIT_ALL
				CONFIGURATION_CAN_CONFIGURE_STORES
				CUSTOMERS_CAN_ADD
				CUSTOMERS_CAN_EDIT_ADDRESSES
				CUSTOMERS_CAN_EDIT_ADVANCED
				CUSTOMERS_CAN_EDIT_BILLING_ADDRESS
				CUSTOMERS_CAN_EDIT_CREDIT_LIMIT
				CUSTOMERS_CAN_EDIT_CUSTOMERS
				CUSTOMERS_CAN_EDIT_PRICING
				CUSTOMERS_CAN_EDIT_SALESMAN_STORE
				CUSTOMERS_CAN_EDIT_TERMS
				CUSTOMERS_CAN_MANAGE_MESSAGES
				CUSTOMERS_CAN_MANAGE_OPTIONS
				CUSTOMERS_CAN_MANAGE_UNITS
				CUSTOMERS_CAN_MERGE
				PARTS_CAN_CLONE_PARTS
				PARTS_CAN_CREATE_CONDITIONS
				PARTS_CAN_EDIT_ATTACHMENTS
				PARTS_CAN_EDIT_COST
				PARTS_CAN_EDIT_GLCATEGORY
				PARTS_CAN_EDIT_INFO
				PARTS_CAN_EDIT_LOCATION
				PARTS_CAN_EDIT_PRICES
				PARTS_CAN_EDIT_QUANTITY
				PARTS_CAN_EDIT_STATUS
				PARTS_CAN_EDIT_STOCKING_DATA
				PARTS_CAN_REPLICATE_PARTS
				PARTS_CAN_SAVE_PARTS
				PARTS_CAN_VIEW_COST
				PARTS_CAN_VIEW_PARTS
				PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES
				PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR
				PART_CONFIGURATION_CAN_EDIT_PART_TYPES
				PURCHASE_ORDERS_CAN_VIEW
				CONFIGURATION_CAN_CONFIGURE_STORES
				GLOBAL_USER_ADMINISTRATOR
				SALES_ORDERS_CAN_VIEW
				VENDORS_CAN_EDIT_VENDORS
			}
		}
	`

	const loginMutation: LoginMutationStore = graphql`
		mutation LoginMutation($username: String!, $passphrase: String!, $selectedStoreId: UInt!) {
			createSession(user: $username, password: $passphrase, storeId: $selectedStoreId) {
				token
				id
				userName
				currentUserAccountId
				accessType
				accessStatus
				sessionStatus
				storeId
				recordType
				recordId
				...Permissions
				userAccount {
					id
					userAccountId: id
					username: name
					email: workEmail
					wageRateId
					deliveryZoneId
					glDepartmentId
					driver
					salesPerson
					worker
					firstName
					lastName
					groups {
						id
						name
					}
					stores
					highestAccessPermissions {
						id
						codeName
						category
						description
						level
					}
					settings {
						preferences {
							optInToTestingFeatures
						}
					}
					authorizedStores {
						id
						name
						code
					}
				}
			}
		}
	`

	const closeSessionMutation: CloseSessionMutationStore = graphql`
		mutation CloseSessionMutation {
			closeSession
		}
	`
	// #endregion GraphQL Queries/Mutations
</script>

<Login
	bind:username
	{rememberMe}
	{isLoading}
	siteLabel="Store"
	siteList={storeList}
	bind:selectedSiteId={selectedStoreId}
	logoImagePath="images/ITrackEnterpiseOnTopNut.svg"
	forgotPasswordHidden
	on:login={login}
	bind:this={loginComponent}
>
	<div class="mt-3 d-flex justify-content-center">
		<a
			href="https://changelog.isoftdata.com/?product=enterprise-web#__buildVersion__"
			target="enterprise_web_changelog"
		>
			<small
				class="text-dim"
				title="Built Date: __buildDate__
Enviroment: __buildEnv__">App Version __buildVersion__</small
			>
		</a>
	</div>
	<div class="d-flex flex-column align-items-center">
		{#await serverInformation then info}
			{#if info.releaseVersionNumber}
				<a
					href="https://changelog.isoftdata.com/?product=enterprise-api#{info.releaseVersionNumber}"
					target="enterprise_api_changelog"
				>
					<small
						class="text-dim"
						title={info.schemaVersion ? `Schema Version ${info.schemaVersion}` : ''}>API Version {info.releaseVersionNumber}</small
					>
				</a>
			{/if}
		{/await}
	</div>
</Login>
