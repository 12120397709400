export default {
    "name": "UserAccountFields",
    "kind": "HoudiniFragment",
    "hash": "10bdbb54cf4ecc70bab65591fafe6a7be1edf323dcda27099f24e2fd7218ffc4",

    "raw": `fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastAccessDate: lastAccess
  workEmail
  recoveryEmail
  lockNotes
  lastPasswordResetDate
  groups {
    id
  }
  authorizedStores {
    id
  }
  userPermissions {
    id
    value: level
  }
  wageRate {
    id
  }
  salesPerson
  driver
  worker
  __typename
}
`,

    "rootType": "UserAccount",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "Int",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "firstName": {
                "type": "String",
                "keyRaw": "firstName",
                "nullable": true,
                "visible": true
            },

            "lastName": {
                "type": "String",
                "keyRaw": "lastName",
                "nullable": true,
                "visible": true
            },

            "status": {
                "type": "UserAccountStatusEnum",
                "keyRaw": "status",
                "visible": true
            },

            "lastAccessDate": {
                "type": "DateTime",
                "keyRaw": "lastAccessDate",
                "nullable": true,
                "visible": true
            },

            "workEmail": {
                "type": "String",
                "keyRaw": "workEmail",
                "visible": true
            },

            "recoveryEmail": {
                "type": "String",
                "keyRaw": "recoveryEmail",
                "nullable": true,
                "visible": true
            },

            "lockNotes": {
                "type": "String",
                "keyRaw": "lockNotes",
                "nullable": true,
                "visible": true
            },

            "lastPasswordResetDate": {
                "type": "DateTime",
                "keyRaw": "lastPasswordResetDate",
                "nullable": true,
                "visible": true
            },

            "groups": {
                "type": "Group",
                "keyRaw": "groups",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "authorizedStores": {
                "type": "Store",
                "keyRaw": "authorizedStores",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userPermissions": {
                "type": "UserAccountPermission",
                "keyRaw": "userPermissions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "value": {
                            "type": "PermissionLevel",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "wageRate": {
                "type": "WageRate",
                "keyRaw": "wageRate",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "salesPerson": {
                "type": "Boolean",
                "keyRaw": "salesPerson",
                "visible": true
            },

            "driver": {
                "type": "Boolean",
                "keyRaw": "driver",
                "visible": true
            },

            "worker": {
                "type": "Boolean",
                "keyRaw": "worker",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=8a155b34c5bb8426ae05b8519c8e73557ea504c9316c1516f064ebbd81a635e5";