<script lang="ts">
	import type { GLCategories, SalesOrderTerms, Vendor, VendorPaymentMethods, VendorTypes } from './vendor'
	import { getEventValue } from '@isoftdata/browser-event'
	import { stringToBoolean } from '@isoftdata/utility-string'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import BooleanIndicator from 'components/BooleanIndicator.svelte'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Table, { Td } from '@isoftdata/svelte-table'
	import Textarea from '@isoftdata/svelte-textarea'

	export let vendor: Vendor
	export let vendorTypeList: VendorTypes = []
	export let glCategories: GLCategories = []
	export let salesOrderTerms: SalesOrderTerms = []
	export let vendorPaymentMethods: VendorPaymentMethods = []
	export let vendorChanged: boolean
	export let viewOnlyMode: boolean = false

	// Looking through the database, it seems like there's no permission for editing vendor custom fields
	// let vendorCanEditOptions = false
	let vendorCustomFieldsValue = vendor.customFields.map(customField => {
		return {
			...customField,
			id: customField.option.id,
		}
	})

	function customFieldValueChanged(event: Event, rowIndex: number) {
		const value = getEventValue(event)
		vendor.customFields[rowIndex].value = value
		vendorCustomFieldsValue[rowIndex].value = value
		vendorChanged = true
	}
</script>

<div class="form-row">
	<div class="col-12 mb-2">
		<Checkbox
			inline
			type="radio"
			label="Status"
			trueLabel="Active"
			falseLabel="Inactive"
			disabled={viewOnlyMode}
			bind:checked={vendor.active}
			on:change={() => (vendorChanged = true)}
		/>
	</div>
	<div class="col-12 col-md-4">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="mb-0">Additional Info</h5>
			</div>
			<div class="card-body">
				<Autocomplete
					canAddNew
					label="Vendor Type"
					options={vendorTypeList}
					disabled={viewOnlyMode}
					bind:value={vendor.type}
					on:change={() => (vendorChanged = true)}
				/>
				<Input
					label="Web Page"
					disabled={viewOnlyMode}
					bind:value={vendor.webAddress}
					on:change={() => (vendorChanged = true)}
				>
					<Button
						slot="append"
						href={vendor.webAddress}
						target="_blank"
						iconClass="external-link-alt"
						disabled={!vendor.webAddress || viewOnlyMode}
					/>
				</Input>
				<Input
					readonly
					label="Date Entered"
					value={vendor.dateEntered?.toISOString().split('T')[0] ?? ''}
				/>
				<Input
					readonly
					label="DUNS Number"
					value={vendor.dunsNumber}
				/>
				<Select
					label="Default AP Terms"
					options={salesOrderTerms.map(term => term.name)}
					disabled={viewOnlyMode}
					value={vendor.defaultTerm?.name ?? null}
					on:change={event => {
						const value = getEventValue(event)
						const selectedDefaultTerm = salesOrderTerms.find(term => term.name === value)
						if (selectedDefaultTerm) {
							vendor.defaultTerm = selectedDefaultTerm
						}
						vendorChanged = true
					}}
				/>
				<Select
					label="Default AP Payment Method"
					emptyText="No Default Payment Method"
					options={vendorPaymentMethods.map(method => method.name)}
					disabled={viewOnlyMode}
					value={vendor.defaultPaymentMethod?.name ?? null}
					on:change={event => {
						const value = getEventValue(event)
						const selectedDefaultPaymentMethod = vendorPaymentMethods.find(method => method.name === value)
						if (selectedDefaultPaymentMethod) {
							vendor.defaultPaymentMethod = selectedDefaultPaymentMethod
						}
						vendorChanged = true
					}}
				/>
				<Select
					label="GL Category (default for replenishable items)"
					options={glCategories.map(category => category.name)}
					disabled={viewOnlyMode}
					value={vendor.defaultGLCategory?.name ?? null}
					on:change={event => {
						const value = getEventValue(event)
						const selectedDefaultGLCategory = glCategories.find(category => category.name === value)
						if (selectedDefaultGLCategory) {
							vendor.defaultGLCategory = selectedDefaultGLCategory
						}
						vendorChanged = true
					}}
				/>
				<Textarea
					label="Notes"
					disabled={viewOnlyMode}
					bind:value={vendor.notes}
					on:change={() => (vendorChanged = true)}
				/>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-8">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="mb-0">Vendor Custom Fields</h5>
			</div>
			<div class="card-body">
				<Table
					responsive
					idProp="id"
					parentStyle="overflow-y: auto; max-height: 55vh;"
					columns={[
						{ property: 'option[name]', name: 'Option' },
						{ property: 'value', name: 'Value' },
						{ property: 'option[showInList]', name: 'Show In Search', align: 'center', width: '1rem' },
					]}
					rows={vendorCustomFieldsValue}
				>
					{#snippet children({ row })}
						<tr>
							<Td property="option[name]">
								{row.option.name}
							</Td>
							<Td property="value">
								{#if row.option.type === 'BOOLEAN'}
									<Checkbox
										type="radio"
										showLabel={false}
										checked={stringToBoolean(row.value)}
										disabled={viewOnlyMode}
										on:change={event => customFieldValueChanged(event, row.originalIndex)}
									/>
								{:else if row.option.type === 'DATE'}
									<Input
										type="date"
										showLabel={false}
										value={row.value}
										disabled={viewOnlyMode}
										on:change={event => customFieldValueChanged(event, row.originalIndex)}
									/>
								{:else if row.option.type === 'NUMBER'}
									<Input
										type="number"
										showLabel={false}
										value={row.value}
										disabled={viewOnlyMode}
										on:change={event => customFieldValueChanged(event, row.originalIndex)}
									/>
								{:else if row.option.type === 'CURRENCY'}
									<CurrencyInput
										showLabel={false}
										value={row.value}
										disabled={viewOnlyMode}
										on:change={event => {
											const value = event.detail.value
											vendor.customFields[row.originalIndex].value = value
											vendorChanged = true
										}}
									/>
								{:else}
									<Input
										showLabel={false}
										value={row.value}
										disabled={viewOnlyMode}
										on:change={event => customFieldValueChanged(event, row.originalIndex)}
									/>
								{/if}
							</Td>
							<Td property="option[showInList]">
								<BooleanIndicator value={row.option.showInList} />
							</Td>
						</tr>
					{/snippet}
				</Table>
			</div>
		</div>
	</div>
</div>
