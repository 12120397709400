export default {
    "name": "VendorPurchaseOrders",
    "kind": "HoudiniQuery",
    "hash": "ad8982e3e643ea1cd8ae9e8917a6e2ca5017aae965f4256de82f5097c9d3f4cf",

    "raw": `query VendorPurchaseOrders($vendorId: UInt!, $filter: PurchaseOrderFilter, $pagination: PaginationOptions) {
  vendor(id: $vendorId) {
    purchaseOrders(filter: $filter, pagination: $pagination) {
      items {
        id
        date
        doneReceiving
        finalized
        internalComments
        ordered
        pricesFinalized
        purchaseOrderId
        receivedPercent
        subtotal
        tax
        total
        void
        customer {
          id
          companyName
          contactName
        }
        salesPerson {
          id
          name
        }
        shippingAddress {
          company
          contact
        }
        store {
          id
          name
        }
        lines {
          id
          description
          lookup
          price
          total
          quantity
          quantityReceived
        }
      }
      pageInfo {
        pageNumber
        pageSize
      }
      totalItems
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vendor": {
                "type": "Vendor",
                "keyRaw": "vendor(id: $vendorId)",

                "selection": {
                    "fields": {
                        "purchaseOrders": {
                            "type": "PaginatedPurchaseOrders",
                            "keyRaw": "purchaseOrders(filter: $filter, pagination: $pagination)",

                            "selection": {
                                "fields": {
                                    "items": {
                                        "type": "PurchaseOrder",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "String",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "date": {
                                                    "type": "Date",
                                                    "keyRaw": "date",
                                                    "visible": true
                                                },

                                                "doneReceiving": {
                                                    "type": "Boolean",
                                                    "keyRaw": "doneReceiving",
                                                    "visible": true
                                                },

                                                "finalized": {
                                                    "type": "Boolean",
                                                    "keyRaw": "finalized",
                                                    "visible": true
                                                },

                                                "internalComments": {
                                                    "type": "String",
                                                    "keyRaw": "internalComments",
                                                    "visible": true
                                                },

                                                "ordered": {
                                                    "type": "Boolean",
                                                    "keyRaw": "ordered",
                                                    "visible": true
                                                },

                                                "pricesFinalized": {
                                                    "type": "Boolean",
                                                    "keyRaw": "pricesFinalized",
                                                    "visible": true
                                                },

                                                "purchaseOrderId": {
                                                    "type": "UInt",
                                                    "keyRaw": "purchaseOrderId",
                                                    "visible": true
                                                },

                                                "receivedPercent": {
                                                    "type": "String",
                                                    "keyRaw": "receivedPercent",
                                                    "visible": true
                                                },

                                                "subtotal": {
                                                    "type": "String",
                                                    "keyRaw": "subtotal",
                                                    "visible": true
                                                },

                                                "tax": {
                                                    "type": "String",
                                                    "keyRaw": "tax",
                                                    "visible": true
                                                },

                                                "total": {
                                                    "type": "String",
                                                    "keyRaw": "total",
                                                    "visible": true
                                                },

                                                "void": {
                                                    "type": "Boolean",
                                                    "keyRaw": "void",
                                                    "visible": true
                                                },

                                                "customer": {
                                                    "type": "Customer",
                                                    "keyRaw": "customer",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "companyName": {
                                                                "type": "String",
                                                                "keyRaw": "companyName",
                                                                "visible": true
                                                            },

                                                            "contactName": {
                                                                "type": "String",
                                                                "keyRaw": "contactName",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "salesPerson": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "salesPerson",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "shippingAddress": {
                                                    "type": "Address",
                                                    "keyRaw": "shippingAddress",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "company": {
                                                                "type": "String",
                                                                "keyRaw": "company",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "contact": {
                                                                "type": "String",
                                                                "keyRaw": "contact",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "store": {
                                                    "type": "Store",
                                                    "keyRaw": "store",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "lines": {
                                                    "type": "PurchaseOrderLine",
                                                    "keyRaw": "lines",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "lookup": {
                                                                "type": "String",
                                                                "keyRaw": "lookup",
                                                                "visible": true
                                                            },

                                                            "price": {
                                                                "type": "Decimal6",
                                                                "keyRaw": "price",
                                                                "visible": true
                                                            },

                                                            "total": {
                                                                "type": "Decimal6",
                                                                "keyRaw": "total",
                                                                "visible": true
                                                            },

                                                            "quantity": {
                                                                "type": "Decimal6",
                                                                "keyRaw": "quantity",
                                                                "visible": true
                                                            },

                                                            "quantityReceived": {
                                                                "type": "Decimal6",
                                                                "keyRaw": "quantityReceived",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "pageInfo": {
                                        "type": "PageInfo",
                                        "keyRaw": "pageInfo",

                                        "selection": {
                                            "fields": {
                                                "pageNumber": {
                                                    "type": "Int",
                                                    "keyRaw": "pageNumber",
                                                    "visible": true
                                                },

                                                "pageSize": {
                                                    "type": "Int",
                                                    "keyRaw": "pageSize",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "vendorId": "UInt",
            "filter": "PurchaseOrderFilter",
            "pagination": "PaginationOptions"
        },

        "types": {
            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "PurchaseOrderFilter": {
                "date": "DateFilter",
                "doneReceiving": "Boolean",
                "pricesFinalized": "Boolean",
                "storeId": "UInt",
                "vendorId": "UInt",
                "void": "Boolean"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b54c592194aa4eef70e92706fb8112ee336903aa2773080f9829e9325f84f7cd";