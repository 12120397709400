<script lang="ts">
	import type { VehicleHistoryTabComponents$result, ComponentAllocationDocumentType$options } from '$houdini'
	import type { SvelteAsr } from 'types/common'
	import type { Column, SortDirection } from '@isoftdata/svelte-table'
	type Vehicle = NonNullable<VehicleHistoryTabComponents$result['vehicle']>

	import NaIfFalsy from 'components/NaIfFalsy.svelte'

	const availablePartsColumns: Array<Column<Vehicle['components'][0]>> = [
		{ property: 'storeId', name: 'Store', numeric: true },
		{ property: 'tagNumber', name: 'Tag #', align: 'right' },
		{ property: 'quantityAvailable', name: 'QA/QOH', numeric: true },
		{ property: 'allocatedVehicleCost', name: 'Allocated Cost', numeric: true },
		{ property: 'retailPrice', name: 'Retail', numeric: true },
		{ property: 'wholesalePrice', name: 'Wholesale', numeric: true },
		{ property: 'retailCorePrice', name: 'Core', numeric: true },
		{ property: 'inventoryType[name]', name: 'Part Type' },
		{ property: 'manufacturer[name]', name: 'Manufacturer' },
		{ property: 'model[name]', name: 'Model' },
		{ property: 'category[name]', name: 'Category' },
	] as const

	const allocatedPartsColumns: Array<Column<Vehicle['componentAllocations']['items'][0]>> = [
		{ property: 'documentLine[lookup]', name: 'Lookup', numeric: true },
		{ property: 'documentType', name: 'Document Type' },
		{ property: 'documentDate', name: 'Date', numeric: true },
		{ property: 'documentNumber', name: 'Document #', numeric: true },
		{ property: 'documentLine[quantity]', name: 'Quantity', numeric: true },
		{ property: 'documentLine[cost]', name: 'Cost', numeric: true },
		{ property: 'documentLine[price]', name: 'Price', numeric: true },
		{ property: 'documentLine[inventoryType][name]', name: 'Part Type', sortType: false },
		{ property: 'documentLine[manufacturer][name]', name: 'Manufacturer' },
		{ property: 'documentLine[model][name]', name: 'Model' },
		{ property: 'documentLine[inventory][category][name]', name: 'Category' },
	] as const

	import Input from '@isoftdata/svelte-input'
	import { Table, Td } from '@isoftdata/svelte-table'
	import { format as currencyFormat } from '@isoftdata/utility-currency'

	export let availableParts: NonNullable<VehicleHistoryTabComponents$result['vehicle']>['components']
	export let allocatedParts: NonNullable<VehicleHistoryTabComponents$result['vehicle']>['componentAllocations']['items']
	export let revenue: string | undefined = undefined
	export let totalCost: string | undefined = undefined
	export let netProfit: string | undefined = undefined
	export let remainingCost: string | undefined = undefined
	export let unallocatedCost: string | undefined = undefined
	export let consumedCost: string | undefined = undefined
	export let asr: SvelteAsr
	export let orderByColumn: string | undefined
	export let orderByDirection: SortDirection | undefined

	let sortColumn = allocatedPartsColumns.find(column => column.property === orderByColumn)

	const documentTypeMap: Record<ComponentAllocationDocumentType$options, string> = {
		JOB_PART: 'Work Order',
		SALES_ORDER: 'Sales Order',
	}
	const getDisplayDocumentType = (key: ComponentAllocationDocumentType$options) => documentTypeMap[key]
</script>

<div
	class="card border-0"
	style="max-height: 40vh;"
>
	<h4 class="card-header border">Available</h4>
	<Table
		rows={availableParts}
		columns={availablePartsColumns}
		responsive
	>
		{#snippet children({ row })}
			<tr>
				<Td property="storeId">{row.storeId}</Td>
				<Td property="tagNumber">{row.tagNumber}</Td>
				<Td property="quantityAvailable">{row.quantityAvailable}/{row.quantityOnHand}</Td>
				<Td property="allocatedVehicleCost">{currencyFormat(row.allocatedVehicleCost ?? '')}</Td>
				<Td property="retailPrice">{currencyFormat(row.retailPrice)}</Td>
				<Td property="wholesalePrice">{currencyFormat(row.wholesalePrice)}</Td>
				<Td property="retailCorePrice">{currencyFormat(row.retailCorePrice)}</Td>
				<Td property="inventoryType[name]">{row.inventoryType.name}</Td>
				<Td property="manufacturer[name]">{row.manufacturer?.name ?? ''}</Td>
				<Td property="model[name]">{row.model?.name ?? ''}</Td>
				<Td property="category[name]">{row.category?.name ?? ''}</Td>
			</tr>
		{/snippet}
	</Table>
</div>

<div
	class="form-row mt-3"
	style="max-height: 40vh;"
>
	<div class="col-12 col-md-8 col-xl-9">
		<div class="card border-0">
			<h4 class="card-header border">Sold</h4>
			<Table
				responsive
				rows={allocatedParts}
				columns={allocatedPartsColumns}
				columnClickedMethod={(column, direction) => {
					asr.go(null, { orderByColumn: column.property, orderByDirection: direction }, { inherit: true })
					return Promise.resolve()
				}}
				sortDirection={orderByDirection}
				{sortColumn}
			>
				{#snippet children({ row })}
					<tr>
						<Td property="documentLine[lookup]">{row.documentLine.lookup}</Td>
						<Td property="documentType">{getDisplayDocumentType(row.documentType)}</Td>
						<Td property="documentDate">{row.documentDate.toLocaleDateString()}</Td>
						<Td property="documentNumber">{row.documentNumber}</Td>
						<Td property="documentLine[quantity]">{row.documentLine.quantity}</Td>
						<Td property="documentLine[cost]">{currencyFormat(row.documentLine.cost)}</Td>
						<Td property="documentLine[price]">{currencyFormat(row.documentLine.price)}</Td>
						<Td property="documentLine[inventoryType][name]"><NaIfFalsy value={row.documentLine?.inventoryType?.name} /></Td>
						<Td property="documentLine[manufacturer][name]"><NaIfFalsy value={row.documentLine?.manufacturer?.name} /></Td>
						<Td property="documentLine[model][name]"><NaIfFalsy value={row.documentLine?.model?.name} /></Td>
						<Td property="documentLine[inventory][category][name]"><NaIfFalsy value={row.documentLine?.inventory?.category?.name} /></Td>
					</tr>
				{/snippet}
			</Table>
		</div>
	</div>
	<div class="col-12 col-md-4 col-xl-3 mt-2 mt-md-0">
		<div class="card">
			<h4 class="card-header">Sales Totals</h4>
			<div class="card-body">
				<Input
					label="Total Vehicle Cost"
					title="The sum of all costs associated with this unit and its components that have been finalized.&#013&#013Note: for details, visit the 'Costs' tab."
					readonly
					value={currencyFormat(totalCost ?? '0')}
					class="text-right"
				/>
				<Input
					label="Cost of sold parts"
					title="Total Cost of Goods Sold (CoGS) of all components associated with this unit.&#013Cost of a component on any document (WO or SO) is any add-on costs (from previous expenses associated with the component) plus any costs allocated manually or at time of sale (% of price costing).&#013&#013Note: for details, see the sold components list."
					readonly
					value={currencyFormat(consumedCost)}
					class="text-right"
				/>
				<Input
					label="Unsold vehicle cost"
					title="Total remaining cost on the vehicle; this represents all vehicle cost that has not been sold (Total cost - CoGS).&#013This remaining cost (minus allocated cost) can be allocated manually, at time of sale (as a % of sale price), or when the unit is written down.&#013&#013Note: this should never be lower than allocated component costs."
					readonly
					value={currencyFormat(remainingCost)}
					class="text-right"
				/>
				<Input
					label="Unallocated cost"
					readonly
					value={currencyFormat(unallocatedCost)}
					class="text-right"
				/>
				<Input
					label="Sales Revenue"
					title="Total sales revenue realized for this unit and its components.&#013This is the sum of the price * quantity of the item on any document it was consumed on (SO or WO)."
					readonly
					value={currencyFormat(revenue ?? '0')}
					class="text-right"
				/>
				<Input
					label="Net Vehicle Profit"
					title="Total profit realized for this unit as a whole; this represents total sales revenue minus total cost (Revenue - CoGS).&#013Once CoGS has reached total cost, sales will be 100% profit.&#013&#013Note: for details, see the sold components list."
					readonly
					value={currencyFormat(netProfit ?? '0')}
					class="text-right"
				/>
			</div>
		</div>
	</div>
</div>
