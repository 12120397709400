export default {
    "name": "CustomerSalesOrders",
    "kind": "HoudiniQuery",
    "hash": "28392ce1165d476f1f32b7824097440fa5beaed7bbaaa21905355a12a47054ed",

    "raw": `query CustomerSalesOrders($customerId: UInt!, $filter: SalesOrderFilter, $pagination: PaginationOptions) {
  customer(id: $customerId) {
    salesOrders(filter: $filter, pagination: $pagination) {
      items {
        salesOrderId
        adjustmentTotal
        adjustments {
          adjustmentType {
            id
            name
            code
            showOnSalesOrders
          }
          amount
          date
          id
        }
        appliedPaymentLines {
          amount
          id
          payment {
            comments
            date
            documentNumber
            id
            storeId
            paymentMethod {
              id
              name
            }
          }
        }
        balance
        counterPerson {
          id
          name
        }
        date
        documentType {
          id
          name
        }
        finalized
        id
        lines {
          description
          id
          inventory {
            vehicle {
              stockNumber
              id
            }
            defaultVendor {
              id
              code
            }
            id
          }
          lookup
          price
          quantity
          total
        }
        paid
        purchaseOrderNumber
        salesperson {
          id
          name
        }
        shippingAddress {
          company
        }
        store {
          id
        }
        subtotal
        tax
        total
        void
      }
      pageInfo {
        pageNumber
        pageSize
        totalPages
      }
      totalItems
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "customer": {
                "type": "Customer",
                "keyRaw": "customer(id: $customerId)",

                "selection": {
                    "fields": {
                        "salesOrders": {
                            "type": "SalesOrderList",
                            "keyRaw": "salesOrders(filter: $filter, pagination: $pagination)",

                            "selection": {
                                "fields": {
                                    "items": {
                                        "type": "SalesOrder",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "salesOrderId": {
                                                    "type": "UInt",
                                                    "keyRaw": "salesOrderId",
                                                    "visible": true
                                                },

                                                "adjustmentTotal": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "adjustmentTotal",
                                                    "visible": true
                                                },

                                                "adjustments": {
                                                    "type": "SalesOrderAdjustment",
                                                    "keyRaw": "adjustments",

                                                    "selection": {
                                                        "fields": {
                                                            "adjustmentType": {
                                                                "type": "AdjustmentType",
                                                                "keyRaw": "adjustmentType",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "code": {
                                                                            "type": "String",
                                                                            "keyRaw": "code",
                                                                            "visible": true
                                                                        },

                                                                        "showOnSalesOrders": {
                                                                            "type": "Boolean",
                                                                            "keyRaw": "showOnSalesOrders",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "amount": {
                                                                "type": "Decimal2",
                                                                "keyRaw": "amount",
                                                                "visible": true
                                                            },

                                                            "date": {
                                                                "type": "Date",
                                                                "keyRaw": "date",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "appliedPaymentLines": {
                                                    "type": "PaymentLine",
                                                    "keyRaw": "appliedPaymentLines",

                                                    "selection": {
                                                        "fields": {
                                                            "amount": {
                                                                "type": "Decimal2",
                                                                "keyRaw": "amount",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "payment": {
                                                                "type": "Payment",
                                                                "keyRaw": "payment",

                                                                "selection": {
                                                                    "fields": {
                                                                        "comments": {
                                                                            "type": "String",
                                                                            "keyRaw": "comments",
                                                                            "visible": true
                                                                        },

                                                                        "date": {
                                                                            "type": "Date",
                                                                            "keyRaw": "date",
                                                                            "visible": true
                                                                        },

                                                                        "documentNumber": {
                                                                            "type": "String",
                                                                            "keyRaw": "documentNumber",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "storeId": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "storeId",
                                                                            "visible": true
                                                                        },

                                                                        "paymentMethod": {
                                                                            "type": "PaymentMethod",
                                                                            "keyRaw": "paymentMethod",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "id": {
                                                                                        "type": "UInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    },

                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "balance": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "balance",
                                                    "visible": true
                                                },

                                                "counterPerson": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "counterPerson",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "date": {
                                                    "type": "Date",
                                                    "keyRaw": "date",
                                                    "visible": true
                                                },

                                                "documentType": {
                                                    "type": "SalesOrderDocument",
                                                    "keyRaw": "documentType",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "finalized": {
                                                    "type": "Boolean",
                                                    "keyRaw": "finalized",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "ID",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "lines": {
                                                    "type": "SalesOrderLine",
                                                    "keyRaw": "lines",

                                                    "selection": {
                                                        "fields": {
                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "inventory": {
                                                                "type": "Inventory",
                                                                "keyRaw": "inventory",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "vehicle": {
                                                                            "type": "Vehicle",
                                                                            "keyRaw": "vehicle",
                                                                            "nullable": true,

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "stockNumber": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "stockNumber",
                                                                                        "visible": true
                                                                                    },

                                                                                    "id": {
                                                                                        "type": "UInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "defaultVendor": {
                                                                            "type": "Vendor",
                                                                            "keyRaw": "defaultVendor",
                                                                            "nullable": true,

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "id": {
                                                                                        "type": "UInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    },

                                                                                    "code": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "code",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "lookup": {
                                                                "type": "String",
                                                                "keyRaw": "lookup",
                                                                "visible": true
                                                            },

                                                            "price": {
                                                                "type": "Decimal2",
                                                                "keyRaw": "price",
                                                                "visible": true
                                                            },

                                                            "quantity": {
                                                                "type": "Decimal6",
                                                                "keyRaw": "quantity",
                                                                "visible": true
                                                            },

                                                            "total": {
                                                                "type": "Decimal2",
                                                                "keyRaw": "total",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "paid": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "paid",
                                                    "visible": true
                                                },

                                                "purchaseOrderNumber": {
                                                    "type": "String",
                                                    "keyRaw": "purchaseOrderNumber",
                                                    "visible": true
                                                },

                                                "salesperson": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "salesperson",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "shippingAddress": {
                                                    "type": "Address",
                                                    "keyRaw": "shippingAddress",

                                                    "selection": {
                                                        "fields": {
                                                            "company": {
                                                                "type": "String",
                                                                "keyRaw": "company",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "store": {
                                                    "type": "Store",
                                                    "keyRaw": "store",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "subtotal": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "subtotal",
                                                    "visible": true
                                                },

                                                "tax": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "tax",
                                                    "visible": true
                                                },

                                                "total": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "total",
                                                    "visible": true
                                                },

                                                "void": {
                                                    "type": "Boolean",
                                                    "keyRaw": "void",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "pageInfo": {
                                        "type": "PageInfo",
                                        "keyRaw": "pageInfo",

                                        "selection": {
                                            "fields": {
                                                "pageNumber": {
                                                    "type": "Int",
                                                    "keyRaw": "pageNumber",
                                                    "visible": true
                                                },

                                                "pageSize": {
                                                    "type": "Int",
                                                    "keyRaw": "pageSize",
                                                    "visible": true
                                                },

                                                "totalPages": {
                                                    "type": "Int",
                                                    "keyRaw": "totalPages",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "customerId": "UInt",
            "filter": "SalesOrderFilter",
            "pagination": "PaginationOptions"
        },

        "types": {
            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "SalesOrderDocumentTypeFilter": {
                "affectsInventory": "Boolean",
                "id": "UInt"
            },

            "SalesOrderFilter": {
                "date": "DateFilter",
                "documentTypeFilter": "SalesOrderDocumentTypeFilter",
                "finalized": "Boolean",
                "storeId": "UInt",
                "void": "Boolean"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=609f598bce3e17d2fffd18235d284dbb59d0a3593f4179828badf2708eb5eaf5";