export default {
    "name": "LIB_settingValues",
    "kind": "HoudiniQuery",
    "hash": "b2b83fd628c81cafa42cb19da42917e1f5851251ed00fd46ef001ea7232fd3e1",

    "raw": `query LIB_settingValues {
  userSettings {
    parts {
      defaultNewInventoryPrintTags
      showSkuField
      showTagNumberWhenCreating
    }
  }
  settingValues {
    inventory {
      ...InventorySettings
    }
    location {
      enforceLocationHierarchy
    }
    accounting {
      useGlAccounting
    }
  }
}

fragment InventorySettings on InventorySettingValues {
  clearScreenOnSave
  defaultDaysToReturn
  defaultDaysToReturnCore
  defaultDaysToReturnCoreToVendor
  defaultDaysToReturnToVendor
  defaultLocationName
  defaultNonReplenishablePartsArePublic
  defaultQuantityForMisc
  defaultQuantityForReplenishable
  defaultQuantityForStandard
  defaultReplenishablePartsArePublic
  defaultReturnable
  defaultGlCategoryId
  defaultNonReplenishablePartsArePublic
  defaultReturnableToVendor
  varianceLocationName
  fields {
    averageCost {
      ...FieldFlags
      id
    }
    averageCoreCost {
      ...FieldFlags
      id
    }
    bodyStyle {
      ...FieldFlags
      id
    }
    buyPackage {
      ...FieldFlags
      id
    }
    category {
      ...FieldFlags
      id
    }
    condition {
      ...FieldFlags
      id
    }
    coreClass {
      ...FieldFlags
      id
    }
    coreCost {
      ...FieldFlags
      id
    }
    coreRequired {
      ...FieldFlags
      id
    }
    coreRequiredToVendor {
      ...FieldFlags
      id
    }
    cost {
      ...FieldFlags
      id
    }
    defaultVendor {
      ...FieldFlags
      id
    }
    deplete {
      ...FieldFlags
      id
    }
    description {
      ...FieldFlags
      id
    }
    distributorCorePrice {
      ...FieldFlags
      id
    }
    distributorPrice {
      ...FieldFlags
      id
    }
    freezeDate {
      ...FieldFlags
      id
    }
    glCategory {
      ...FieldFlags
      id
    }
    interchangeNumber {
      ...FieldFlags
      id
    }
    internalNotes {
      ...FieldFlags
      id
    }
    inventoryType {
      ...FieldFlags
      id
    }
    jobberCorePrice {
      ...FieldFlags
      id
    }
    jobberPrice {
      ...FieldFlags
      id
    }
    listPrice {
      ...FieldFlags
      id
    }
    manufacturer {
      ...FieldFlags
      id
    }
    maxQuantity {
      ...FieldFlags
      id
    }
    minQuantity {
      ...FieldFlags
      id
    }
    model {
      ...FieldFlags
      id
    }
    oemNumber {
      ...FieldFlags
      id
    }
    parentManufacturer {
      ...FieldFlags
      id
    }
    parentModel {
      ...FieldFlags
      id
    }
    partNumber {
      ...FieldFlags
      id
    }
    popularityCode {
      ...FieldFlags
      id
    }
    printTag {
      ...FieldFlags
      id
    }
    public {
      ...FieldFlags
      id
    }
    purchaseFactor {
      ...FieldFlags
      id
    }
    replenish {
      ...FieldFlags
      id
    }
    retailPrice {
      ...FieldFlags
      id
    }
    retailCorePrice {
      ...FieldFlags
      id
    }
    returnable {
      ...FieldFlags
      id
    }
    returnableToVendor {
      ...FieldFlags
      id
    }
    saleClassCode {
      ...FieldFlags
      id
    }
    sellPriceClass {
      ...FieldFlags
      id
    }
    serialized {
      ...FieldFlags
      id
    }
    safetyStockPercent {
      ...FieldFlags
      id
    }
    seasonal {
      ...FieldFlags
      id
    }
    sellPackage {
      ...FieldFlags
      id
    }
    shippingHeight {
      ...FieldFlags
      id
    }
    shippingLength {
      ...FieldFlags
      id
    }
    shippingMeasurementUnit {
      ...FieldFlags
      id
    }
    shippingWidth {
      ...FieldFlags
      id
    }
    side {
      ...FieldFlags
      id
    }
    singleQuantity {
      ...FieldFlags
      id
    }
    stockMethod {
      ...FieldFlags
      id
    }
    stockType {
      ...FieldFlags
      id
    }
    stockingDays {
      ...FieldFlags
      id
    }
    tagNumber {
      ...FieldFlags
      id
    }
    taxable {
      ...FieldFlags
      id
    }
    typeData1 {
      ...FieldFlags
      id
    }
    typeData2 {
      ...FieldFlags
      id
    }
    typeData3 {
      ...FieldFlags
      id
    }
    typeData4 {
      ...FieldFlags
      id
    }
    userStatus {
      ...FieldFlags
      id
    }
    vehicleMake {
      ...FieldFlags
      id
    }
    vehicleModel {
      ...FieldFlags
      id
    }
    vendorProductCode {
      ...FieldFlags
      id
    }
    vendorPopularityCode {
      ...FieldFlags
      id
    }
    vin {
      ...FieldFlags
      id
    }
    weight {
      ...FieldFlags
      id
    }
    weightUnit {
      ...FieldFlags
      id
    }
    wholesalePrice {
      ...FieldFlags
      id
    }
    wholesaleCorePrice {
      ...FieldFlags
      id
    }
    year {
      ...FieldFlags
      id
    }
  }
  __typename
}

fragment FieldFlags on InventoryField {
  editable
  global
  required: requiredToSave
  visible
  logChanges
  id
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userSettings": {
                "type": "UserSettings",
                "keyRaw": "userSettings",

                "selection": {
                    "fields": {
                        "parts": {
                            "type": "UserSettingsInventoryCategory",
                            "keyRaw": "parts",

                            "selection": {
                                "fields": {
                                    "defaultNewInventoryPrintTags": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultNewInventoryPrintTags",
                                        "visible": true
                                    },

                                    "showSkuField": {
                                        "type": "Boolean",
                                        "keyRaw": "showSkuField",
                                        "visible": true
                                    },

                                    "showTagNumberWhenCreating": {
                                        "type": "Boolean",
                                        "keyRaw": "showTagNumberWhenCreating",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "inventory": {
                            "type": "InventorySettingValues",
                            "keyRaw": "inventory",

                            "selection": {
                                "fields": {
                                    "clearScreenOnSave": {
                                        "type": "Boolean",
                                        "keyRaw": "clearScreenOnSave",
                                        "visible": true
                                    },

                                    "defaultDaysToReturn": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturn",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnCore": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnCore",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnCoreToVendor": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnCoreToVendor",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnToVendor": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnToVendor",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultLocationName": {
                                        "type": "String",
                                        "keyRaw": "defaultLocationName",
                                        "visible": true
                                    },

                                    "defaultNonReplenishablePartsArePublic": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultNonReplenishablePartsArePublic",
                                        "visible": true
                                    },

                                    "defaultQuantityForMisc": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForMisc",
                                        "visible": true
                                    },

                                    "defaultQuantityForReplenishable": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForReplenishable",
                                        "visible": true
                                    },

                                    "defaultQuantityForStandard": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForStandard",
                                        "visible": true
                                    },

                                    "defaultReplenishablePartsArePublic": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReplenishablePartsArePublic",
                                        "visible": true
                                    },

                                    "defaultReturnable": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReturnable",
                                        "visible": true
                                    },

                                    "defaultGlCategoryId": {
                                        "type": "Int",
                                        "keyRaw": "defaultGlCategoryId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultReturnableToVendor": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReturnableToVendor",
                                        "visible": true
                                    },

                                    "varianceLocationName": {
                                        "type": "String",
                                        "keyRaw": "varianceLocationName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "fields": {
                                        "type": "InventoryFieldSettingValues",
                                        "keyRaw": "fields",

                                        "selection": {
                                            "fields": {
                                                "averageCost": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "averageCost",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "averageCoreCost": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "averageCoreCost",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "bodyStyle": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "bodyStyle",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "buyPackage": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "buyPackage",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "category": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "category",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "condition": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "condition",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "coreClass": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "coreClass",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "coreCost": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "coreCost",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "coreRequired": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "coreRequired",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "coreRequiredToVendor": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "coreRequiredToVendor",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "cost": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "cost",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "defaultVendor": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "defaultVendor",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "deplete": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "deplete",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "description",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "distributorCorePrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "distributorCorePrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "distributorPrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "distributorPrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "freezeDate": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "freezeDate",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "glCategory": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "glCategory",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "interchangeNumber": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "interchangeNumber",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "internalNotes": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "internalNotes",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "inventoryType": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "inventoryType",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "jobberCorePrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "jobberCorePrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "jobberPrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "jobberPrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "listPrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "listPrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "manufacturer": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "manufacturer",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "maxQuantity": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "maxQuantity",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "minQuantity": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "minQuantity",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "model": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "model",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "oemNumber": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "oemNumber",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "parentManufacturer": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "parentManufacturer",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "parentModel": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "parentModel",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "partNumber": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "partNumber",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "popularityCode": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "popularityCode",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "printTag": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "printTag",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "public",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "purchaseFactor": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "purchaseFactor",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "replenish": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "replenish",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "retailPrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "retailPrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "retailCorePrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "retailCorePrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "returnable": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "returnable",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "returnableToVendor": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "returnableToVendor",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "saleClassCode": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "saleClassCode",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "sellPriceClass": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "sellPriceClass",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "serialized": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "serialized",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "safetyStockPercent": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "safetyStockPercent",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "seasonal": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "seasonal",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "sellPackage": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "sellPackage",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "shippingHeight": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "shippingHeight",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "shippingLength": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "shippingLength",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "shippingMeasurementUnit": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "shippingMeasurementUnit",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "shippingWidth": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "shippingWidth",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "side": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "side",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "singleQuantity": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "singleQuantity",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "stockMethod": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "stockMethod",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "stockType": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "stockType",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "stockingDays": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "stockingDays",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "tagNumber": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "tagNumber",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "taxable": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "taxable",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "typeData1": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "typeData1",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "typeData2": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "typeData2",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "typeData3": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "typeData3",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "typeData4": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "typeData4",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "userStatus": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "userStatus",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "vehicleMake": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "vehicleMake",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "vehicleModel": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "vehicleModel",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "vendorProductCode": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "vendorProductCode",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "vendorPopularityCode": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "vendorPopularityCode",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "vin": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "vin",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "weight": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "weight",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "weightUnit": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "weightUnit",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "wholesalePrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "wholesalePrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "wholesaleCorePrice": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "wholesaleCorePrice",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "year": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "year",

                                                    "selection": {
                                                        "fields": {
                                                            "editable": {
                                                                "type": "Boolean",
                                                                "keyRaw": "editable",
                                                                "visible": true
                                                            },

                                                            "global": {
                                                                "type": "Boolean",
                                                                "keyRaw": "global",
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "visible": {
                                                                "type": "Boolean",
                                                                "keyRaw": "visible",
                                                                "visible": true
                                                            },

                                                            "logChanges": {
                                                                "type": "Boolean",
                                                                "keyRaw": "logChanges",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "FieldFlags": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "InventorySettings": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "location": {
                            "type": "LocationSettingValues",
                            "keyRaw": "location",

                            "selection": {
                                "fields": {
                                    "enforceLocationHierarchy": {
                                        "type": "Boolean",
                                        "keyRaw": "enforceLocationHierarchy",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "accounting": {
                            "type": "AccountingSettingValues",
                            "keyRaw": "accounting",

                            "selection": {
                                "fields": {
                                    "useGlAccounting": {
                                        "type": "Boolean",
                                        "keyRaw": "useGlAccounting",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=838c9ff7d974f1a21b266673128a7092db5067bd5173299ce0ab8069eef363b7";