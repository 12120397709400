<script lang="ts">
	import type { PartForClient as Part, SettingValues } from 'utility/load-part'
	import type { InventoryDocumentHistory$result, InventoryDocumentHistory$input, SalesOrderDocumentTypes$result } from '$houdini'
	import type { SimplifyDeep } from 'type-fest'

	import { onMount, type ComponentProps } from 'svelte'
	import { datesFromRange } from '@isoftdata/utility-date-time'

	type History = SimplifyDeep<
		Omit<InventoryDocumentHistory$result['inventory']['history']['items'][number], 'locations'> & {
			// locations: string
			attn?: never
			documentDate: Date | null
			timeEntered: Date | null
		}
	>

	type Filter = {
		salesOrders: {
			active: boolean
			showClosed: boolean
			showOpen: boolean
			showVoid: boolean
			documentTypeId: number | null
		}
		purchaseOrders: {
			active: boolean
			stillReceiving: boolean
			doneReceiving: boolean
			showFinalized: boolean
			showNotFinalized: boolean
			showVoid: boolean
		}
		transferOrders: {
			active: boolean
			stillReceiving: boolean
			doneReceiving: boolean
			showVoid: boolean
		}
		workOrders: {
			active: boolean
			showEstimates: boolean
			showFinalized: boolean
			showNotFinalized: boolean
			showVoid: boolean
		}
		manualAdjustments: {
			active: boolean
		}
	}

	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import DateRange from '@isoftdata/svelte-date-range'
	import Select from '@isoftdata/svelte-select'
	import ChangeLogTable from './ChangeLogTable.svelte'
	import Popover from 'components/Popover.svelte'

	import { graphql } from '$houdini'
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import makeUserLocalWritable, { type UserLocalWritable } from 'stores/user-local-writable'
	import session from 'stores/session'
	import Input from '@isoftdata/svelte-input'

	export let part: Part

	let showFiltersModal = false
	let dates:
		| {
				to: string
				from: string
		  }
		| undefined = undefined
	let documentTypeSelect: HTMLSelectElement | undefined = undefined
	let range: ComponentProps<DateRange>['range'] = 'Last 30 Days'
	let history: Array<History> = []

	let salesOrderDocumentTypes: SalesOrderDocumentTypes$result['salesOrderDocumentTypes'] = []

	const filter: UserLocalWritable<Filter> = makeUserLocalWritable($session.id, 'part-history-filter', {
		salesOrders: {
			active: true,
			showClosed: true,
			showOpen: true,
			showVoid: false,
			documentTypeId: null,
		},
		purchaseOrders: {
			active: true,
			stillReceiving: true,
			doneReceiving: true,
			showFinalized: true,
			showNotFinalized: true,
			showVoid: false,
		},
		transferOrders: {
			active: true,
			stillReceiving: true,
			doneReceiving: true,
			showVoid: false,
		},
		workOrders: {
			active: true,
			showEstimates: true,
			showFinalized: true,
			showNotFinalized: true,
			showVoid: false,
		},
		manualAdjustments: {
			active: true,
		},
	})

	const columns: Array<Column<History>> = [
		{
			name: '',
			property: 'attn',
			icon: 'info-circle',
			sortType: false,
			width: '1rem',
		},
		{
			name: 'Document Type',
			property: 'documentTypeName',
			sortType: false,
		},
		{
			name: '#',
			property: 'documentNumber',
			numeric: true,
			sortType: false,
		},
		{
			name: 'Date',
			property: 'documentDate',
		},
		{
			name: 'Date Added',
			property: 'timeEntered',
		},
		// {
		// 	name: 'Counterperson',
		// 	property: 'user.name',
		// },
		{
			name: 'Price (EA)',
			property: 'unitPrice',
			numeric: true,
			sortType: false,
		},
		{
			name: 'Doc. Qty',
			property: 'documentQuantity',
			numeric: true,
			sortType: false,
		},
		{
			name: 'Affected Qty',
			property: 'affectedQuantity',
			numeric: true,
			sortType: false,
		},
		// {
		// 	name: 'Serial #',
		// 	property: 'serials',
		// },
		// {
		// 	name: 'Location(s)',
		// 	property: 'locations',
		// },
		{
			name: 'Description',
			property: 'lineDescription',
			sortType: false,
		},
		{
			name: 'Source',
			property: 'sourceName',
			sortType: false,
		},
		{
			name: 'Destination',
			property: 'destinationName',
			sortType: false,
		},
		{
			name: 'Dest. Address',
			property: 'destinationAddress',
			sortType: false,
		},
		// {
		// 	name: 'Dest. City',
		// 	property: 'destinationcity',
		// },
		// {
		// 	name: 'Dest. State',
		// 	property: 'destinationstate',
		// },
		// {
		// 	name: 'Dest. Zip',
		// 	property: 'destinationzip',
		// },
		// {
		// 	name: 'Dest. Country',
		// 	property: 'destinationcountry',
		// },
		{
			name: 'Doc. Notes',
			property: 'documentNotes',
			sortType: false,
		},
		// {
		// 	name: 'Extra Info',
		// 	property: '',
		// },
	]

	let currentPageNumber = 1
	let perPageCount = 10
	let totalItemsCount = 0
	let sortDirection: 'ASC' | 'DESC' = 'DESC'
	let sortColumnName: 'documentDate' | 'timeEntered' = 'documentDate'
	let sortColumn: Column<History> = columns[3]
	$: sortColumnName = sortColumn?.property === 'documentDate' ? 'documentDate' : 'timeEntered'
	$: sortColumn && sortDirection && loadDocumentHistory()

	$: displayFilter = getDisplayFilter($filter)

	function isVoid(row: History) {
		if (row.document?.__typename === 'SalesOrderLine') {
			return row.document?.salesOrder.void
		} else if (row.document?.__typename === 'JobPart') {
			return row.document?.job.workOrder.void
		} else if (row.document?.__typename === 'WorkOrderMasterPart') {
			return row.document?.workOrder.void
		} else if (row.document?.__typename === 'PurchaseOrderLine') {
			return row.document?.purchaseOrder.void
		} else if (row.document?.__typename === 'TransferOrderLine') {
			return row.document?.transferOrder.void
		}
		return false
	}

	async function loadDocumentHistory(): Promise<void> {
		history = []
		if (!part.innodbInventoryid) {
			return
		}

		/** Turn two booleans into a boolean or undefined for those API filters */
		function superCoolFilterLogic(a: boolean, b: boolean) {
			if (a && b) {
				return undefined
			} else if (a) {
				return true
			} else if (b) {
				return false
			} else {
				return undefined
			}
		}

		// dates might be undefined on first load
		if (!dates && range && range !== 'Custom') {
			dates = datesFromRange(range)
		}

		const theFilter: InventoryDocumentHistory$input['filter'] = {
			documentDate: dates
				? {
						gte: new Date(new Date(dates.from).setUTCHours(0, 0, 0, 0)),
						lte: new Date(new Date(dates.to).setUTCHours(23, 59, 59, 999)),
					}
				: undefined,
			salesOrderLineFilter: $filter.salesOrders.active
				? {
						salesOrderFilter: {
							finalized: superCoolFilterLogic($filter.salesOrders.showClosed, $filter.salesOrders.showOpen),
							void: $filter.salesOrders.showVoid ? undefined : false,
							documentTypeFilter: $filter.salesOrders.documentTypeId
								? {
										id: [$filter.salesOrders.documentTypeId],
									}
								: undefined,
						},
					}
				: null,
			purchaseOrderLineFilter: $filter.purchaseOrders.active
				? {
						purchaseOrderFilter: {
							doneReceiving: superCoolFilterLogic($filter.purchaseOrders.doneReceiving, $filter.purchaseOrders.stillReceiving),
							void: $filter.purchaseOrders.showVoid ? undefined : false,
							pricesFinalized: superCoolFilterLogic($filter.purchaseOrders.showFinalized, $filter.purchaseOrders.showNotFinalized),
						},
					}
				: null,
			transferOrderLineFilter: $filter.transferOrders.active
				? {
						transferOrderFilter: {
							doneReceiving: superCoolFilterLogic($filter.transferOrders.doneReceiving, $filter.transferOrders.stillReceiving),
							void: $filter.transferOrders.showVoid ? undefined : false,
						},
					}
				: null,
			workOrderFilter: $filter.workOrders.active
				? {
						// finalized?
						isEstimate: $filter.workOrders.showEstimates ? undefined : false,
						void: $filter.workOrders.showVoid ? undefined : false,
					}
				: null,
			includeManualAdjustments: $filter.manualAdjustments.active,
		}
		const orderBy: `${typeof sortColumnName}_${typeof sortDirection}` = `${sortColumnName}_${sortDirection}`

		console.log('filter', theFilter, {
			currentPageNumber,
			perPageCount,
			orderBy,
		})
		const { data } = await documentHistoryQuery.fetch({
			variables: {
				inventoryId: part.innodbInventoryid,
				pagination: {
					pageNumber: currentPageNumber,
					pageSize: perPageCount,
				},
				orderBy: [orderBy],
				filter: theFilter,
			},
		})

		if (!data) {
			history = []
			totalItemsCount = 0
			currentPageNumber = 1
		} else {
			history = data.inventory.history.items.map(
				(history): History => ({
					...history,
					// locations: locations.map(({ code }) => code).join(', '),
					documentDate: history.documentDate ? new Date(history.documentDate) : null,
					timeEntered: history.timeEntered ? new Date(history.timeEntered) : null,
				}),
			)
			totalItemsCount = data.inventory.history.totalItems
			console.log(history)
		}
	}

	function getDisplayFilter(theFilter: Filter) {
		const displayFilter = {
			salesOrders: '',
			purchaseOrders: '',
			transferOrders: '',
			workOrders: '',
		}
		// SO
		if (theFilter.salesOrders.active && (theFilter.salesOrders.showOpen || theFilter.salesOrders.showClosed)) {
			if (!theFilter.salesOrders.showVoid) {
				displayFilter.salesOrders += 'non-void, '
			}

			if (!theFilter.salesOrders.showClosed && !theFilter.salesOrders.showOpen) {
				displayFilter.salesOrders += 'open, '
			} else if (theFilter.salesOrders.showClosed && !theFilter.salesOrders.showOpen) {
				displayFilter.salesOrders += 'finalized, '
			}

			if (displayFilter.salesOrders.endsWith(', ')) {
				displayFilter.salesOrders = displayFilter.salesOrders.slice(0, -2)
			}

			// if ($filter.salesOrders.documentType) {
			// 	displayFilter.salesOrders += ` ${$filter.salesOrders.documentType}(s)`
			// }
		} else {
			displayFilter.salesOrders = 'None'
		}

		if (!displayFilter.salesOrders) {
			displayFilter.salesOrders = 'All'
		}

		// WO
		if (theFilter.workOrders.active && (theFilter.workOrders.showFinalized || theFilter.workOrders.showNotFinalized)) {
			if (!theFilter.workOrders.showVoid) {
				displayFilter.workOrders += 'non-void, '
			}

			if (!theFilter.workOrders.showFinalized && theFilter.workOrders.showNotFinalized) {
				displayFilter.workOrders += 'open, '
			} else if (theFilter.workOrders.showFinalized && !theFilter.workOrders.showNotFinalized) {
				displayFilter.workOrders += 'finalized, '
			}

			if (!theFilter.workOrders.showEstimates) {
				displayFilter.workOrders += 'non-estimate, '
			}

			if (displayFilter.workOrders.endsWith(', ')) {
				displayFilter.workOrders = displayFilter.workOrders.slice(0, -2)
			}
		} else {
			displayFilter.workOrders = 'None'
		}

		if (!displayFilter.workOrders) {
			displayFilter.workOrders = 'All'
		}

		// PO

		if (theFilter.purchaseOrders.active && (theFilter.purchaseOrders.showFinalized || theFilter.purchaseOrders.showNotFinalized)) {
			if (!theFilter.purchaseOrders.showVoid) {
				displayFilter.purchaseOrders += 'non-void, '
			}

			if (!theFilter.purchaseOrders.doneReceiving && theFilter.purchaseOrders.stillReceiving) {
				displayFilter.purchaseOrders += 'receiving, '
			} else if (theFilter.purchaseOrders.doneReceiving && !theFilter.purchaseOrders.stillReceiving) {
				displayFilter.purchaseOrders += 'done receiving, '

				if (!theFilter.purchaseOrders.showFinalized && theFilter.purchaseOrders.showNotFinalized) {
					displayFilter.purchaseOrders += 'open, '
				} else if (theFilter.purchaseOrders.showFinalized && !theFilter.purchaseOrders.showNotFinalized) {
					displayFilter.purchaseOrders += 'finalized, '
				}
			}

			if (displayFilter.purchaseOrders.endsWith(', ')) {
				displayFilter.purchaseOrders = displayFilter.purchaseOrders.slice(0, -2)
			}
		} else {
			displayFilter.purchaseOrders = 'None'
		}

		if (!displayFilter.purchaseOrders) {
			displayFilter.purchaseOrders = 'All'
		}

		// TO
		if (theFilter.transferOrders.active && (theFilter.transferOrders.stillReceiving || theFilter.transferOrders.doneReceiving)) {
			if (!theFilter.transferOrders.showVoid) {
				displayFilter.transferOrders += 'non-void, '
			}

			if (!theFilter.transferOrders.doneReceiving && theFilter.transferOrders.stillReceiving) {
				displayFilter.transferOrders += 'receiving, '
			} else if (theFilter.transferOrders.doneReceiving && !theFilter.transferOrders.stillReceiving) {
				displayFilter.transferOrders += 'done receiving, '
			}

			if (displayFilter.transferOrders.endsWith(', ')) {
				displayFilter.transferOrders = displayFilter.transferOrders.slice(0, -2)
			}
		} else {
			displayFilter.transferOrders = 'None'
		}

		if (!displayFilter.transferOrders) {
			displayFilter.transferOrders = 'All'
		}

		return displayFilter
	}

	//#region queries
	const salesOrderDocumentTypesQuery = graphql(`
		query SalesOrderDocumentTypes {
			salesOrderDocumentTypes {
				id
				name
				affectsInventory
			}
		}
	`)

	const documentHistoryQuery = graphql(`
		query InventoryDocumentHistory($inventoryId: UInt!, $filter: InventoryHistoryFilter, $orderBy: [InventoryHistorySort!], $pagination: PaginationOptions) {
			inventory(id: $inventoryId) {
				history(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
					items {
						documentTypeName
						documentNumber
						documentDate
						#counterPerson
						unitPrice
						documentQuantity
						affectedQuantity
						#serial #
						sourceName
						destinationName
						destinationAddress
						documentNotes
						lineDescription
						timeEntered
						document {
							... on SalesOrderLine {
								salesOrder {
									void
									internalComments
									documentType {
										affectsInventory
									}
								}
							}
							... on PurchaseOrderLine {
								purchaseOrder {
									internalComments
									void
								}
							}
							... on TransferOrderLine {
								transferOrder {
									void
								}
							}
							... on JobPart {
								job {
									workOrder {
										void
									}
								}
							}
							... on WorkOrderMasterPart {
								workOrder {
									void
								}
							}
						}
					}
					totalItems
				}
			}
		}
	`)
	//#endregion
	onMount(async () => {
		const [docTypeRes] = await Promise.all([
			//
			salesOrderDocumentTypesQuery.fetch(),
			loadDocumentHistory(),
		])
		salesOrderDocumentTypes = docTypeRes.data?.salesOrderDocumentTypes ?? []
	})
</script>

<CollapsibleCard
	entireHeaderToggles
	headerText="Document History"
	cardHeaderClass="card-header d-flex justify-content-between h4"
>
	<div class="mb-3">
		<Button
			outline
			size="sm"
			iconClass="search"
			on:click={() => (showFiltersModal = true)}
			>Modify Filter
		</Button>
		<Button
			outline
			size="sm"
			iconClass="refresh"
			on:click={loadDocumentHistory}
			>Refresh
		</Button>

		<Popover
			outline
			size="sm"
			iconClass="circle-info"
		>
			<svelte:fragment slot="popover">
				<div class="popover-header">
					<h6 class="d-inline">Filter</h6>
					{range ? `(${range})` : ''}
				</div>
				<div class="popover-body">
					<div class="text-nowrap">
						<div><strong>SO:</strong> {displayFilter.salesOrders}</div>
						<div><strong>WO:</strong> {displayFilter.workOrders}</div>
						<div><strong>PO:</strong> {displayFilter.purchaseOrders}</div>
						<div><strong>TO:</strong> {displayFilter.transferOrders}</div>
					</div>
				</div>
				<div class="popover-header"><h6>Legend</h6></div>
				<div class="popover-body d-flex flex-wrap justify-content-between text-nowrap">
					<span class="w-50">
						<Icon
							fixedWidth
							class="text-secondary"
							icon="circle-xmark"
						/> Void
					</span>
					<span class="w-50">
						<Icon
							fixedWidth
							class="text-success"
							icon="up"
						/> Quantity Increase
					</span>
					<span class="w-50">
						<Icon
							fixedWidth
							class="text-primary"
							icon="quotes"
						/> Quote
					</span>
					<span class="w-50">
						<Icon
							fixedWidth
							class="text-danger"
							icon="down"
						/> Quantity Decrease
					</span>
				</div>
			</svelte:fragment>
		</Popover>
	</div>
	<Table
		responsive
		{columns}
		rows={history}
		bind:sortDirection
		bind:sortColumn
		{perPageCount}
		{currentPageNumber}
		{totalItemsCount}
		pageChange={({ pageNumber }) => {
			currentPageNumber = pageNumber
			loadDocumentHistory()
		}}
	>
		<svelte:fragment slot="body">
			{#each history as row}
				<tr class="text-nowrap">
					<Td property="attn">
						<!-- Void -->
						{#if isVoid(row)}
							<Icon
								fixedWidth
								class="text-secondary"
								icon="circle-xmark"
								title="Document has been voided"
							/>
						{/if}
						<!-- Quote: sales order type does not affect inventory -->
						{#if row.document && 'salesOrder' in row.document && row.document?.salesOrder.documentType.affectsInventory === false}
							<Icon
								fixedWidth
								class="text-primary"
								icon="quotes"
								title="Document is a quote"
							/>
						{/if}
						<!-- Quantity Increase/Decrease -->
						{#if row.affectedQuantity !== null && parseFloat(row.affectedQuantity) > 0}
							<Icon
								fixedWidth
								class="text-success"
								icon="up"
								title="Quantity increase"
							/>
						{:else if row.affectedQuantity !== null && parseFloat(row.affectedQuantity) < 0}
							<Icon
								fixedWidth
								class="text-danger"
								icon="down"
								title="Quantity decrease"
							/>
						{/if}
					</Td>
					<Td property="documentTypeName">
						{row.documentTypeName}
					</Td>
					<Td property="documentNumber">
						{row.documentNumber}
					</Td>
					<Td property="documentDate">
						{row.documentDate ? row.documentDate.toLocaleDateString() : ''}
					</Td>
					<Td property="timeEntered">
						{row.timeEntered ? row.timeEntered.toLocaleString() : ''}
					</Td>
					<Td property="unitPrice">
						{formatCurrency(row.unitPrice ?? '0')}
					</Td>
					<Td property="documentQuantity">
						{row.documentQuantity}
					</Td>
					<Td property="affectedQuantity">
						{row.affectedQuantity}
					</Td>
					<!-- <Td property="locations">
						{row.locations}
					</Td> -->
					<Td property="lineDescription">
						{row.lineDescription ?? ''}
					</Td>
					<Td property="sourceName">
						{row.sourceName ?? ''}
					</Td>
					<Td property="destinationName">
						{row.destinationName ?? ''}
					</Td>
					<Td property="destinationAddress">
						{row.destinationAddress ?? ''}
					</Td>
					<Td property="documentNotes">
						{row.documentNotes}
					</Td>
				</tr>
			{:else}
				<tr>
					<td
						class="text-center"
						colspan={columns.length}
					>
						{#if $documentHistoryQuery.fetching}<Icon
								fixedWidth
								isLoading
								class="mr-1"
							></Icon> Loading document history...{:else}No document history found.{/if}
					</td>
				</tr>
			{/each}
		</svelte:fragment>
	</Table>
</CollapsibleCard>

<CollapsibleCard
	entireHeaderToggles
	cardClass="mt-2"
	headerText="Change Log"
	cardHeaderClass="card-header d-flex justify-content-between h4"
>
	<div class="form-row mb-3">
		<div class="col-12 col-md-4 col-xl-2">
			<Input
				readonly
				label="Entered By"
				value={part.enteredBy?.name ?? 'Unknown'}
			/>
		</div>
		<div class="col-12 col-md-4 col-xl-2">
			<Input
				readonly
				label="Date Viewed"
				value={part.dateViewed ? part.dateViewed.toLocaleString() : null}
			/>
		</div>
		<div class="col-12 col-md-4 col-xl-2">
			<Input
				readonly
				label="Date Modified"
				title="The date this inventory record was modified by a user"
				value={part.dateModified ? part.dateModified.toLocaleString() : null}
			/>
		</div>
		<div class="col-12 col-md-4 col-xl-2">
			<Input
				readonly
				label="Date Entered"
				title="The date this inventory record was first created in the system"
				value={part.dateEntered ? part.dateEntered.toLocaleString() : null}
			/>
		</div>
		<div class="col-12 col-md-4 col-xl-2">
			<Input
				readonly
				class="text-right"
				label="Average Cost"
				title="The date this inventory record was first created in the system"
				value={formatCurrency(part.averageCost)}
			/>
		</div>
		<div class="col-12 col-md-4 col-xl-2">
			<Input
				readonly
				class="text-right"
				label="Average Core Cost"
				title="The date this inventory record was first created in the system"
				value={formatCurrency(part.averageCoreCost)}
			/>
		</div>
	</div>
	<ChangeLogTable {part}></ChangeLogTable>
</CollapsibleCard>

<Modal
	bind:show={showFiltersModal}
	title="Transaction Filters"
	modalSize="lg"
	cancelShown={false}
	closeShown={false}
	on:confirm={() => {
		showFiltersModal = false
		currentPageNumber = 1
		loadDocumentHistory()
	}}
>
	<DateRange
		colClass="col-12 col-md-4"
		bind:dates
		bind:range
		defaultRange="Last 30 Days"
	></DateRange>
	<div>
		<CollapsibleCard
			cardHeaderClass="card-header d-flex justify-content-between h5"
			bind:bodyShown={$filter.salesOrders.active}
		>
			<svelte:fragment slot="cardHeader">
				<Checkbox
					label="Sales Orders"
					bind:checked={$filter.salesOrders.active}
				></Checkbox>
			</svelte:fragment>
			<div class="form-row">
				<div class="col-6">
					<Select
						id="document-type-select"
						label="Document Type"
						emptyText="All Document Types"
						isLoading={$salesOrderDocumentTypesQuery.fetching}
						options={salesOrderDocumentTypes}
						disabled={!$filter.salesOrders.active}
						bind:select={documentTypeSelect}
						bind:value={$filter.salesOrders.documentTypeId}
						let:option
					>
						{#if option}
							<option value={option.id}>{option.name}</option>
						{/if}
					</Select>
				</div>
				<div class="col-6">
					<Checkbox
						label="Show Closed"
						disabled={!$filter.salesOrders.active}
						bind:checked={$filter.salesOrders.showClosed}
					></Checkbox>
					<Checkbox
						label="Show Open"
						disabled={!$filter.salesOrders.active}
						bind:checked={$filter.salesOrders.showOpen}
					></Checkbox>
					<Checkbox
						label="Show Void"
						disabled={!$filter.salesOrders.active}
						bind:checked={$filter.salesOrders.showVoid}
					></Checkbox>
				</div>
			</div>
		</CollapsibleCard>

		<CollapsibleCard
			cardHeaderClass="card-header d-flex justify-content-between h5"
			bind:bodyShown={$filter.purchaseOrders.active}
		>
			<svelte:fragment slot="cardHeader">
				<Checkbox
					label="Purchase Orders"
					bind:checked={$filter.purchaseOrders.active}
				></Checkbox>
			</svelte:fragment>
			<Checkbox
				inline
				label="Still Receiving"
				disabled={!$filter.purchaseOrders.active}
				bind:checked={$filter.purchaseOrders.stillReceiving}
			></Checkbox>
			<Checkbox
				inline
				label="Done Receiving"
				disabled={!$filter.purchaseOrders.active}
				bind:checked={$filter.purchaseOrders.doneReceiving}
			></Checkbox>
			<Checkbox
				inline
				label="Show Finalized"
				disabled={!$filter.purchaseOrders.active}
				bind:checked={$filter.purchaseOrders.showFinalized}
			></Checkbox>
			<Checkbox
				inline
				label="Show Not Finalized"
				disabled={!$filter.purchaseOrders.active}
				bind:checked={$filter.purchaseOrders.showNotFinalized}
			></Checkbox>
			<Checkbox
				inline
				label="Show Void"
				disabled={!$filter.purchaseOrders.active}
				bind:checked={$filter.purchaseOrders.showVoid}
			></Checkbox>
		</CollapsibleCard>

		<CollapsibleCard
			cardHeaderClass="card-header d-flex justify-content-between h5"
			bind:bodyShown={$filter.transferOrders.active}
		>
			<svelte:fragment slot="cardHeader">
				<Checkbox
					label="Transfer Orders"
					bind:checked={$filter.transferOrders.active}
				></Checkbox>
			</svelte:fragment>
			<Checkbox
				inline
				label="Still Receiving"
				disabled={!$filter.transferOrders.active}
				bind:checked={$filter.transferOrders.stillReceiving}
			></Checkbox>
			<Checkbox
				inline
				label="Done Receiving"
				disabled={!$filter.transferOrders.active}
				bind:checked={$filter.transferOrders.doneReceiving}
			></Checkbox>
			<Checkbox
				inline
				label="Show Void"
				disabled={!$filter.transferOrders.active}
				bind:checked={$filter.transferOrders.showVoid}
			></Checkbox>
		</CollapsibleCard>

		<CollapsibleCard
			cardHeaderClass="card-header d-flex justify-content-between h5"
			bind:bodyShown={$filter.workOrders.active}
		>
			<svelte:fragment slot="cardHeader">
				<Checkbox
					label="Work Orders"
					bind:checked={$filter.workOrders.active}
				></Checkbox>
			</svelte:fragment>
			<Checkbox
				inline
				label="Show Estimates"
				disabled={!$filter.workOrders.active}
				bind:checked={$filter.workOrders.showEstimates}
			></Checkbox>
			<Checkbox
				inline
				label="Show Finalized"
				disabled={!$filter.workOrders.active}
				bind:checked={$filter.workOrders.showFinalized}
			></Checkbox>
			<Checkbox
				inline
				label="Show Not Finalized"
				disabled={!$filter.workOrders.active}
				bind:checked={$filter.workOrders.showNotFinalized}
			></Checkbox>
			<Checkbox
				inline
				label="Show Void"
				disabled={!$filter.workOrders.active}
				bind:checked={$filter.workOrders.showVoid}
			></Checkbox>
		</CollapsibleCard>

		<div class="card">
			<div class="card-header h5 border-bottom-0">
				<Checkbox
					label="Manual Adjustments"
					bind:checked={$filter.manualAdjustments.active}
				/>
			</div>
		</div>
	</div>
</Modal>

<style>
	* :global(div.card:not(:first-child)) {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	* :global(div.card:not(:last-child)) {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: none;
	}
</style>
