import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/Permissions'


// create the fragment store

export class PermissionsStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "PermissionsStore",
			variables: true,
			
		})
	}
}
