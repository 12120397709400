export default {
    "name": "VendorCustomFields",
    "kind": "HoudiniQuery",
    "hash": "7c2809f5c40db7bcb853693b0539ce2045db91631c878deb0ad7db8f793f8584",

    "raw": `query VendorCustomFields {
  vendorOptions {
    id
    name
    showInList
    type
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vendorOptions": {
                "type": "VendorOption",
                "keyRaw": "vendorOptions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "showInList": {
                            "type": "Boolean",
                            "keyRaw": "showInList",
                            "visible": true
                        },

                        "type": {
                            "type": "VendorOptionType",
                            "keyRaw": "type",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=53aab66ddbad7cf76df9130ef0190f114059b62538904977f1400d3dc8dc0165";