import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/CustomerCustomFields'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class CustomerCustomFieldsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "CustomerCustomFieldsStore",
			variables: false,
		})
	}
}

export async function load_CustomerCustomFields(params) {
	await initClient()

	const store = new CustomerCustomFieldsStore()

	await store.fetch(params)

	return {
		CustomerCustomFields: store,
	}
}
