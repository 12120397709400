<script lang="ts">
	import { getContext, onDestroy, setContext } from 'svelte'
	import Setting from '@isoftdata/svelte-setting'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import type { UserSetting, SettingSaveType, DefaultSettingSaveType } from 'types/settings'
	import type { SvelteAsr } from 'types/common'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { writable } from 'svelte/store'
	import type { Writable } from 'svelte/store'
	import type { Mediator } from 'types/mediator'

	const mediator = getContext<Mediator>('mediator')

	let dirtySettings = writable<Array<UserSetting>>([])
	let defaultDirtySettings = writable<Array<UserSetting>>([])
	setContext<Writable<Array<UserSetting>>>('dirtySettings', dirtySettings)
	setContext<Writable<Array<UserSetting>>>('defaultDirtySettings', defaultDirtySettings)

	export let settingsChanged: boolean = false
	$: settingsChanged = $dirtySettings.length > 0 || $defaultDirtySettings.length > 0

	//#region global props
	export let asr: SvelteAsr
	const mutations = {
		// These are local mutations, not to be confused with the mutations in common/graphql-queries which are shared
		updateSettingValues: `#graphql
		mutation UpdateSettingValue($input: SettingsValueUpdate) {
			updateSettingValue(input: $input)
		}
	`,
		updateDefaultSettingValues: `#graphql
		mutation UpdateSettingDefaultValue($input: SettingsDefaultValueUpdate) {
			updateSettingDefaultValue(input: $input)
		}
	`,
	}
	//#endregion
	//#region local props
	export let configurationSettings: {
		//Typed object to get the settings from the db shapped correctly
		importantConfiguration: string
		optionalConfiguration: string
		preference: string
		interfacePreference: string
		interfaceHistory: string
	}
	export let selectedCategory: string = 'All'
	export let settings: UserSetting[] = []
	//#endregion
	//#region internal props
	let filterChoices = [
		//This is the list of filters we bind to and show for the setting component along with the real setting name that it represents { name: display name, value: value, realSettingName: setting name in db for lookup}
		{
			name: 'Important Configuration',
			value: stringToBoolean(configurationSettings?.importantConfiguration) ?? true,
			realSettingName: "Configure Settings Dialog: Show 'important configuration' settings",
		},
		{
			name: 'Optional Configuration',
			value: stringToBoolean(configurationSettings?.optionalConfiguration) ?? true,
			realSettingName: "Configure Settings Dialog: Show 'optional configuration' settings",
		},
		{
			name: 'Preference',
			value: stringToBoolean(configurationSettings?.preference) ?? true,
			realSettingName: "Configure Settings Dialog: Show 'preference' settings",
		},
		{
			name: 'Interface Preference',
			value: stringToBoolean(configurationSettings?.interfacePreference) ?? true,
			realSettingName: "Configure Settings Dialog: Show 'interface preference' settings",
		},
		{
			name: 'Interface History',
			value: stringToBoolean(configurationSettings?.interfaceHistory) ?? true,
			realSettingName: "Configure Settings Dialog: Show 'interface history' settings",
		},
	]
	//#endregion
	//#region formatters
	function formatSettingForSave(setting: UserSetting): SettingSaveType {
		let valueToSave = setting.value
		if (setting.dataType === 'boolean') {
			valueToSave = booleanToString(stringToBoolean(setting.value))
		} else {
			valueToSave = setting.value
		}
		return {
			settingId: setting.id,
			value: valueToSave,
		}
	}

	function formatDefaultSettingForSave(setting: UserSetting): DefaultSettingSaveType {
		let valueToSave = setting.defaultValue
		if (setting.dataType === 'boolean') {
			valueToSave = booleanToString(stringToBoolean(setting.defaultValue)) //Verify that this default value is actually a boolean
		} else {
			valueToSave = setting.defaultValue //otherwise just save the default value
		}
		return {
			settingId: setting.id,
			defaultValue: valueToSave,
		}
	}
	//#endregion
	//#region save functions
	async function saveSettings() {
		const defaultValuesToUpdate = $defaultDirtySettings.map(formatDefaultSettingForSave) //format default settings for save
		const dirtyValuesToUpdate = $dirtySettings.map(formatSettingForSave) //format dirty settings for save

		await Promise.all([
			defaultValuesToUpdate.length ? mediator.publish('graphqlFetch', mutations.updateDefaultSettingValues, { input: { settings: defaultValuesToUpdate } }) : null,
			dirtyValuesToUpdate.length ? mediator.publish('graphqlFetch', mutations.updateSettingValues, { input: { settings: dirtyValuesToUpdate } }) : null,
		])

		dirtySettings.set([]) //reset dirty settings
		defaultDirtySettings.set([]) //reset default dirty settings

		asr.go(null, { lastSavedTime: Date.now(), lastResetTime: null, selectedCategory }, { inherit: true }) //reset the page (Causes a page reload/onDestory to fire)
	}
	async function saveInterfaceHistory(): Promise<void> {
		// Find the settings that control the interface history
		const settingsToFindNames = filterChoices.map(choice => choice.realSettingName) // Single 5 iteration loop
		const mappedSettings = settingsToFindNames.map(setting => {
			// Single 5 iteration loop
			const originalSetting = settings.find(s => s.name === setting) // find which sucks so one Settinglist length loop
			if (!originalSetting) return null
			if (originalSetting.value) {
				const originalValue = stringToBoolean(originalSetting.value)
				const newValue = filterChoices.find(choice => choice.realSettingName === originalSetting.name)?.value // 5 iteration loop
				if (originalValue !== newValue) {
					originalSetting.value = booleanToString(newValue ?? true)
				}
			}
			return formatSettingForSave(originalSetting)
		})
		await mediator.publish('graphqlFetch', mutations.updateSettingValues, { input: { settings: mappedSettings } })
	}
	//#endregion

	export function canLeaveState() {
		return !settingsChanged || confirm('You have unsaved changes. Are you sure you want to leave?')
	}

	onDestroy(() => {
		// Saves interface history on destroy/page change
		saveInterfaceHistory()
	})
</script>

<div class="card">
	<div class="card-header">
		<div class="form-row">
			<div class="col">
				<h4 class="card-title">Settings Configuration</h4>
			</div>
			<div class="col text-right">
				<SaveResetButton
					disabled={!settingsChanged}
					resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null, selectedCategory }, { inherit: true })}
					on:saveClick={saveSettings}
				/>
			</div>
		</div>
	</div>
	<div class="p-2">
		<Setting
			bind:settings
			bind:selectedCategory
			{filterChoices}
			hideDescription={true}
			defaultValueEditable={true}
		/>
	</div>
</div>
