<script lang="ts">
	import type { InventoryTypesFull$result } from '$houdini'
	import type { Column } from '@isoftdata/svelte-table'
	import type { SvelteAsr } from 'types/common'

	type InventoryType = InventoryTypesFull$result['inventoryTypes'][number]

	import { Table, Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import InventoryTypeModal from './InventoryTypeModal.svelte'
	import { onMount, tick } from 'svelte'
	import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import BooleanIndicator from 'components/BooleanIndicator.svelte'

	export let inventoryTypes: Array<InventoryType>
	export let selectedInventoryTypeId: number | undefined
	export let asr: SvelteAsr
	export let showInactive: boolean

	let selectedInventoryType: InventoryType | undefined = undefined
	let filteredInventoryTypes: Array<InventoryType>
	let inventoryTypeModal: InventoryTypeModal | undefined = undefined

	$: selectedInventoryType = inventoryTypes.find(({ id }) => id === selectedInventoryTypeId)
	$: filteredInventoryTypes = inventoryTypes.filter(inventoryType => showInactive || inventoryType.active)

	const columns: Array<Column<InventoryType>> = [
		{
			property: 'active',
			name: 'Active',
			align: 'center',
			width: '1rem',
			title: 'Is this inventory type active?',
		},
		{
			property: 'id',
			name: 'Type #',
			numeric: true,
			title: 'A unique # for this inventory type',
		},
		{
			property: 'setId',
			name: 'Set #',
			numeric: true,
			title: `A 'set' inventory type dictates which type's manufacturer/model list to use on search as well as in the assembly area on the parts screen.\r\nGenerally, components will have a major assembly as its set type and major assemblies will just use their own type #`,
		},
		{
			property: 'name',
			name: 'Name',
			title: 'What inventory of this type is called (must be unique)',
		},
		{
			property: 'vehicle',
			name: 'Vehicle Unit',
			align: 'center',
			width: '1rem',
			title: 'If checked, this inventory type represents a vehicle/whole unit type.',
		},
		{
			property: 'taxCode',
			name: 'Tax Code',
			title: 'The tax code to use for this inventory type for external tax calculations.',
		},
	]

	onMount(async () => {
		if (selectedInventoryTypeId) {
			await tick()
			document.getElementById(`inventory_type_id_${selectedInventoryTypeId}`)?.scrollIntoView({ block: 'center' })
		}
	})
</script>

<Table
	responsive
	stickyHeader
	filterEnabled
	columnHidingEnabled
	parentStyle="overflow-y: auto; max-height: 50vh;"
	idProp="id"
	class="border-0"
	{columns}
	rows={filteredInventoryTypes}
>
	{#snippet header()}
		<div class="d-flex align-items-end">
			<div class="btn-group mr-2">
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					title="Add"
					on:click={() => inventoryTypeModal?.add()}>Add</Button
				>

				<Button
					outline
					size="sm"
					color="primary"
					iconClass="pencil"
					title="Edit Inventory Type"
					disabled={!selectedInventoryType}
					on:click={() => {
						if (selectedInventoryType) {
							inventoryTypeModal?.edit(selectedInventoryType)
						}
					}}>Edit Inventory Type</Button
				>
			</div>
			<Checkbox
				label="Show Inactive"
				bind:checked={showInactive}
				on:change={() => asr.go(null, { showInactive: booleanToString(showInactive) }, { inherit: true })}
			/>
		</div>
	{/snippet}
	{#snippet children({ row: inventoryTypeItem })}
		<tr
			class="cursor-pointer"
			id="inventory_type_id_{inventoryTypeItem.id}"
			class:table-primary={selectedInventoryTypeId === inventoryTypeItem.id}
			class:text-muted={!inventoryTypeItem.active}
			class:font-italic={!inventoryTypeItem.active}
			on:click={() => {
				selectedInventoryTypeId = inventoryTypeItem.id
				asr.go(null, { inventoryTypeId: inventoryTypeItem.id }, { inherit: true })
			}}
		>
			<Td property="active"><BooleanIndicator value={inventoryTypeItem.active} /></Td>
			<Td property="id">{inventoryTypeItem.id}</Td>
			<Td property="setId">{inventoryTypeItem.setId}</Td>
			<Td property="name">{inventoryTypeItem.name}</Td>
			<Td property="vehicle"><BooleanIndicator value={inventoryTypeItem.vehicle} /></Td>
			<Td property="taxCode"
				>{#if inventoryTypeItem.taxCode}{inventoryTypeItem.taxCode}{:else}
					<i class="text-muted">N/A</i>{/if}</Td
			>
		</tr>
	{/snippet}
	{#snippet noRows({ visibleColumnsCount })}
		<tr>
			<td colspan={visibleColumnsCount}> No matching inventory types found.</td>
		</tr>
	{/snippet}
</Table>

<div class="card mt-3">
	<div class="card-header">
		<h5 class="mb-0">Inventory Type Details</h5>
	</div>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>

<InventoryTypeModal
	{asr}
	bind:this={inventoryTypeModal}
	bind:inventoryTypeList={inventoryTypes}
	on:inventoryTypeCreate={event => {
		selectedInventoryTypeId = event.detail.id
	}}
/>
