export default {
    "name": "UserAccountData",
    "kind": "HoudiniQuery",
    "hash": "c6c07699eaab414d7defb96eb947cf85c26cf1378282dbbbc63254c5ee13c0dc",

    "raw": `query UserAccountData {
  userAccounts {
    lastName
    id
    firstName
    name
    status
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts",

                "selection": {
                    "fields": {
                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8047963c9c9c6e1d17933dc52faaf37c3a43dd4443bcbdb60ddf2268b630194e";