<script lang="ts">
	import type { Column } from '@isoftdata/svelte-table'
	import type { InventoryOptionsConfig$result } from '$houdini'
	import type { InventoryType } from 'utility/search-fields'

	type InventoryOption = Omit<InventoryOptionsConfig$result['inventoryOptions'][number], ' $fragments'> & {
		' $fragments'?: unknown
		hasChoices: boolean
	}

	import CustomQuestionModal from './CustomQuestionModal.svelte'
	import { Table, Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import BooleanIndicator from 'components/BooleanIndicator.svelte'
	import { upsert } from '@isoftdata/utility-array'
	import { tick } from 'svelte'

	export let selectedInventoryTypeId: number | undefined
	export let questions: Array<InventoryOption>
	export let dataTypesMap: Map<string, string>
	export let inventoryTypes: Array<InventoryType>

	let customQuestionModal: CustomQuestionModal | undefined = undefined
	let selectedQuestion: InventoryOption | null = null
	let showGlobalQuestions = true

	const columns: Array<Column<InventoryOption>> = [
		{ property: 'name', name: 'Question', minWidth: '250px' },
		{ property: 'inventoryType[name]', name: 'Type' },
		{ property: 'manufacturer[name]', name: 'Manufacturer' },
		{ property: 'model[name]', name: 'Model' },
		{ property: 'category[name]', name: 'Category' },
		{ property: 'dataType', name: 'Data Type' },
		{ property: 'public', name: 'Public', align: 'center', width: '1rem' },
		{ property: 'required', name: 'Required', align: 'center', width: '1rem' },
		{ property: 'hasChoices', name: 'Choices' },
	]
</script>

<Table
	responsive
	stickyHeader
	filterEnabled
	columnHidingEnabled
	idProp="id"
	class="border-0"
	parentStyle="overflow-y: auto; max-height: 50vh;"
	{columns}
	rows={questions}
>
	{#snippet header()}
		<div class="d-flex align-items-end">
			<div class="btn-group mr-2">
				<Button
					size="sm"
					color="success"
					outline
					iconClass="plus"
					title="Add"
					on:click={() => {
						customQuestionModal?.add()
					}}>Add</Button
				>

				<Button
					size="sm"
					color="primary"
					outline
					colorGreyDisabled
					iconClass="pencil"
					title="Edit Question"
					disabled={!selectedQuestion}
					on:click={() => {
						if (selectedQuestion) {
							customQuestionModal?.edit(selectedQuestion)
						}
					}}>Edit Question</Button
				>
			</div>
			<Checkbox
				label="Show Global Questions"
				bind:checked={showGlobalQuestions}
			/>
		</div>
	{/snippet}

	{#snippet children({ row })}
		{#if showGlobalQuestions || row.inventoryType}
			<tr
				id="question-{row.id}"
				class="cursor-pointer"
				class:table-primary={selectedQuestion?.id === row.id}
				on:click={() => (selectedQuestion = questions[row.originalIndex])}
			>
				<Td property="name">{row.name}</Td>
				<Td
					class="text-nowrap"
					property="inventoryType[name]">{row.inventoryType === null ? 'All Inventory Types' : `${row.inventoryType?.name} - ${row.inventoryType?.id}`}</Td
				>
				<Td property="manufacturer[name]">{row.manufacturer?.name || ''}</Td>
				<Td property="model[name]">{row.model?.name || ''}</Td>
				<Td property="category[name]">{row.category?.name || ''}</Td>
				<Td property="dataType">{dataTypesMap.get(row.dataType)}</Td>
				<Td property="public"><BooleanIndicator value={row.public}></BooleanIndicator></Td>
				<Td property="required"><BooleanIndicator value={row.required}></BooleanIndicator></Td>
				<Td
					property="hasChoices"
					class="text-nowrap"
					>{#if row.choiceQuery || row.choices.length}
						{row.choiceQuery ? 'Choice Query' : ''}
						{row.choiceQuery && row.choices.length ? ' & ' : ''}
						{row.choices.length ? `${row.choices.length} Choice(s)` : ''}
					{:else}
						<i class="text-muted">N/A</i>
					{/if}
				</Td>
			</tr>
		{/if}
	{/snippet}
	{#snippet noRows({ visibleColumnsCount })}
		<tr>
			<td colspan={visibleColumnsCount}> No matching questions found.</td>
		</tr>
	{/snippet}
</Table>

<CustomQuestionModal
	bind:this={customQuestionModal}
	{dataTypesMap}
	{selectedInventoryTypeId}
	{inventoryTypes}
	on:questionSaved={async event => {
		const question = {
			...event.detail,
			hasChoices: !!event.detail.choiceQuery || event.detail.choices.length > 0,
		}
		questions = upsert(questions, 'id', question)
		selectedQuestion = question
		await tick()
		document.getElementById(`question-${question.id}`)?.scrollIntoView({ block: 'center' })
	}}
/>
