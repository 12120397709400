export default {
    "name": "CreateUserAccount",
    "kind": "HoudiniMutation",
    "hash": "f0b9ce65ea58eacd74394ed2618609edfe3194654d9ed949361f372c783cc216",

    "raw": `mutation CreateUserAccount($input: NewUserAccount!) {
  createUserAccount(input: $input) {
    ...UserAccountFields
    id
  }
}

fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastAccessDate: lastAccess
  workEmail
  recoveryEmail
  lockNotes
  lastPasswordResetDate
  groups {
    id
  }
  authorizedStores {
    id
  }
  userPermissions {
    id
    value: level
  }
  wageRate {
    id
  }
  salesPerson
  driver
  worker
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createUserAccount": {
                "type": "UserAccount",
                "keyRaw": "createUserAccount(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastAccessDate": {
                            "type": "DateTime",
                            "keyRaw": "lastAccessDate",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "String",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "lockNotes": {
                            "type": "String",
                            "keyRaw": "lockNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "lastPasswordResetDate": {
                            "type": "DateTime",
                            "keyRaw": "lastPasswordResetDate",
                            "nullable": true,
                            "visible": true
                        },

                        "groups": {
                            "type": "Group",
                            "keyRaw": "groups",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "authorizedStores": {
                            "type": "Store",
                            "keyRaw": "authorizedStores",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userPermissions": {
                            "type": "UserAccountPermission",
                            "keyRaw": "userPermissions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionLevel",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "wageRate": {
                            "type": "WageRate",
                            "keyRaw": "wageRate",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "salesPerson": {
                            "type": "Boolean",
                            "keyRaw": "salesPerson",
                            "visible": true
                        },

                        "driver": {
                            "type": "Boolean",
                            "keyRaw": "driver",
                            "visible": true
                        },

                        "worker": {
                            "type": "Boolean",
                            "keyRaw": "worker",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "UserAccountFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewUserAccount"
        },

        "types": {
            "UserPermission": {
                "id": "UInt",
                "level": "PermissionLevel"
            },

            "NewUserAccount": {
                "apiToken": "String",
                "authorizedStoreIds": "Int",
                "firstName": "String",
                "isDriver": "Boolean",
                "isSalesPerson": "Boolean",
                "isWorker": "Boolean",
                "lastName": "String",
                "lockNotes": "String",
                "name": "String",
                "newPassword": "String",
                "userGroupIds": "Int",
                "userPermissions": "UserPermission",
                "wageRateId": "Int",
                "workEmail": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=755ddd892e70d2c4fa6f0975c52f1dbf970e082c796da63183af4247156afc52";