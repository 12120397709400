<script lang="ts">
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import { graphql, type VendorPurchaseOrders$result } from '$houdini'
	import { datesFromRange } from '@isoftdata/utility-date-time'
	import { endOfDay, parseISO, startOfDay } from 'date-fns'
	import { onMount, type ComponentProps } from 'svelte'
	import { hasPermission } from 'utility/permission'
	import type { Merge } from 'type-fest'

	import Button from '@isoftdata/svelte-button'
	import DateRange from '@isoftdata/svelte-date-range'
	import ReportJobModal from 'components/ReportJobModal.svelte'
	import Table, { type Column, Td } from '@isoftdata/svelte-table'

	type PurchaseOrderStatus = 'Open' | 'Prices Finalized' | 'Ordered' | 'Done Receiving' | 'Finalized' | 'Void'
	type VendorPurchaseOrder = Merge<VendorPurchaseOrders$result['vendor']['purchaseOrders']['items'][number], { shipTo: string; status: PurchaseOrderStatus }>

	const purchaseOrderHistoryColumn: Column<VendorPurchaseOrder>[] = [
		{ property: 'id', name: 'PO #', numeric: true },
		{ property: 'date', name: 'Date' },
		{ property: 'subtotal', name: 'Subtotal', numeric: true, sortType: 'ALPHA_NUM' },
		{ property: 'tax', name: 'Tax', numeric: true, sortType: 'ALPHA_NUM' },
		{ property: 'total', name: 'Total', numeric: true, sortType: 'ALPHA_NUM' },
		{ property: 'shipTo', name: 'Customer' },
		{ property: 'salesPerson[name]', name: 'Salesperson' },
		{ property: 'store[id]', name: 'Store #', numeric: true },
		{ property: 'receivedPercent', name: '% Received', numeric: true },
		{ property: 'status', name: 'PO status' },
		{ property: 'internalComments', name: 'Internal Comments' },
	]

	const lineItemColumn: Column<VendorPurchaseOrder['lines'][number]>[] = [
		{ property: 'lookup', name: 'Part' },
		{ property: 'quantity', name: 'Qty', numeric: true },
		{ property: 'quantityReceived', name: 'Received', numeric: true },
		{ property: 'description', name: 'Description' },
		{ property: 'price', name: 'Price', numeric: true, sortType: 'ALPHA_NUM' },
		{ property: 'total', name: 'Total', numeric: true, sortType: 'ALPHA_NUM' },
	]

	export let vendorId: number | null //it could be a new vendor

	let vendorPurchaseOrders: VendorPurchaseOrder[] = []
	let selectedPurchaseOrder: VendorPurchaseOrder | null = null
	let isLoadingPurchaseOrders: boolean = false
	let reportJobModalComponent: ReportJobModal

	let dates: ComponentProps<DateRange>['dates'] = undefined
	let range: ComponentProps<DateRange>['range'] = 'Last 90 Days'
	let perPageCount: number = 6
	let currentPageNumber = 1
	let totalItemsCount: number | undefined = undefined

	// get vendor purchase orders with formatted status and shipping destination
	async function getVendorPurchaseOrders(dates: { from: string; to: string }, pageNumber: number, vendorId: number) {
		isLoadingPurchaseOrders = true
		const { data } = await vendorPurchaseOrdersQuery.fetch({
			variables: {
				vendorId,
				filter: {
					date: {
						gte: startOfDay(parseISO(dates.from)),
						lte: endOfDay(parseISO(dates.to)),
					},
				},
				pagination: {
					pageSize: 6,
					pageNumber,
				},
			},
		})

		if (!data) {
			throw new Error('Failed to fetch vendor purchase orders')
		}
		vendorPurchaseOrders =
			data.vendor?.purchaseOrders?.items.map(purchaseOrder => ({
				...purchaseOrder,
				shipTo: purchaseOrder.shippingAddress?.company || purchaseOrder.shippingAddress?.contact || 'N/A',
				status: getPOStatus(purchaseOrder).status,
			})) ?? []
		selectedPurchaseOrder = null

		currentPageNumber = data.vendor?.purchaseOrders?.pageInfo.pageNumber ?? 1
		totalItemsCount = data.vendor?.purchaseOrders?.totalItems
		isLoadingPurchaseOrders = false
	}

	function getPOStatus(purchaseOrder: VendorPurchaseOrders$result['vendor']['purchaseOrders']['items'][number]): { status: PurchaseOrderStatus; textColor: string } {
		if (purchaseOrder.void) {
			return { status: 'Void', textColor: 'danger' }
		} else if (purchaseOrder.finalized) {
			return { status: 'Finalized', textColor: 'success' }
		} else if (purchaseOrder.doneReceiving) {
			return { status: 'Done Receiving', textColor: 'primary' }
		} else if (purchaseOrder.ordered) {
			return { status: 'Ordered', textColor: 'warning' }
		} else if (purchaseOrder.pricesFinalized) {
			return { status: 'Prices Finalized', textColor: 'info' }
		} else {
			return { status: 'Open', textColor: '' }
		}
	}

	const vendorPurchaseOrdersQuery = graphql(`
		query VendorPurchaseOrders($vendorId: UInt!, $filter: PurchaseOrderFilter, $pagination: PaginationOptions) {
			vendor(id: $vendorId) {
				purchaseOrders(filter: $filter, pagination: $pagination) {
					items {
						id
						date
						doneReceiving
						finalized
						internalComments
						ordered
						pricesFinalized
						purchaseOrderId
						receivedPercent
						subtotal
						tax
						total
						void
						customer {
							id
							companyName
							contactName
						}
						salesPerson {
							id
							name
						}
						shippingAddress {
							company
							contact
						}
						store {
							id
							name
						}
						lines {
							id
							description
							lookup
							price
							total
							quantity
							quantityReceived
						}
					}
					pageInfo {
						pageNumber
						pageSize
					}
					totalItems
				}
			}
		}
	`)

	onMount(async () => {
		if (vendorId && range && range !== 'Custom') {
			dates = datesFromRange(range)
			await getVendorPurchaseOrders(dates, 1, vendorId)
		}
	})
</script>

{#if !hasPermission('PURCHASE_ORDERS_CAN_VIEW')}
	<div class="alert alert-warning text-center">You do not have permission to view purchase order history. Please contact your admin for further assistance.</div>
{:else}
	<div class="card">
		<div class="card-header">
			<h4 class="mb-0">Purchase Order History</h4>
		</div>
		<div class="card-body">
			<div class="mb-1">
				<DateRange
					colClass="col-md-3"
					label="Date Range"
					bind:dates
					bind:range
					disabled={isLoadingPurchaseOrders || !vendorId}
					on:change={async () => {
						if (vendorId && dates) {
							await getVendorPurchaseOrders(dates, currentPageNumber, vendorId)
						}
					}}
				/>
			</div>
			{#if isLoadingPurchaseOrders}
				<div class="text-center">
					<i class="fas fa-spinner fa-spin fa-2x"></i>
				</div>
			{:else}
				<Table
					responsive
					stickyHeader
					columnHidingEnabled
					columnResizingEnabled
					tableId="purchase-order-history"
					localStorageKey="purchase-order-history"
					columns={purchaseOrderHistoryColumn}
					rows={vendorPurchaseOrders}
					{perPageCount}
					{currentPageNumber}
					{totalItemsCount}
					pageChange={({ pageNumber }) => {
						currentPageNumber = pageNumber
						if (vendorId && dates) {
							getVendorPurchaseOrders(dates, currentPageNumber, vendorId)
						}
					}}
				>
					{#snippet noRows({ visibleColumnsCount })}
						<tr>
							<td
								colspan={visibleColumnsCount}
								class="text-center"
							>
								No purchase order found
							</td>
						</tr>
					{/snippet}
					{#snippet children({ row })}
						<tr
							class="cursor-pointer"
							class:table-primary={selectedPurchaseOrder === row}
							on:click={() => {
								selectedPurchaseOrder = row
							}}
						>
							<Td property="id">
								{row.id}
							</Td>
							<Td property="date">
								{row.date.toLocaleDateString()}
							</Td>
							<Td property="subtotal">
								{formatCurrency(row.subtotal)}
							</Td>
							<Td property="tax">
								{formatCurrency(row.tax)}
							</Td>
							<Td property="total">
								{formatCurrency(row.total)}
							</Td>
							<Td property="shipTo">
								{row.shipTo}
							</Td>
							<Td property="salesPerson[name]">
								{row.salesPerson?.name ?? 'N/A'}
							</Td>
							<Td property="store[id]">
								{row.store?.id ?? 'N/A'}
							</Td>
							<Td property="receivedPercent">
								{row.receivedPercent}
							</Td>
							<Td
								property="status"
								class="text-nowrap text-{getPOStatus(row).textColor}"
							>
								{row.status}
							</Td>
							<Td property="internalComments">
								{row.internalComments}
							</Td>
						</tr>
					{/snippet}
				</Table>
			{/if}
		</div>
		<div class="card-footer">
			<Button
				outline
				size="sm"
				iconClass="print"
				disabled={!selectedPurchaseOrder || isLoadingPurchaseOrders}
				on:click={() => {
					if (selectedPurchaseOrder) {
						reportJobModalComponent.open({
							type: 'Purchase Order',
							parameters: { purchaseorderid: selectedPurchaseOrder.purchaseOrderId.toString(), storeid: selectedPurchaseOrder.store.id.toString() },
						})
					}
				}}
			>
				Print...
			</Button>
		</div>
	</div>
	{#if !isLoadingPurchaseOrders}
		<div class="card mt-3">
			<div class="card-header">
				<h4 class="mb-0">Purchase Order {selectedPurchaseOrder ? `#${selectedPurchaseOrder.id}` : ''}</h4>
			</div>
			<div class="card-body p-1">
				{#if !selectedPurchaseOrder}
					<div class="text-center p-3">
						<h5>Select a purchase order to view line items</h5>
					</div>
				{:else}
					<Table
						responsive
						columns={lineItemColumn}
						rows={selectedPurchaseOrder.lines}
					>
						{#snippet noRows({ visibleColumnsCount })}
							<tr>
								<td
									colspan={visibleColumnsCount}
									class="text-center"
								>
									No line items found for the selected purchase order
								</td>
							</tr>
						{/snippet}
						{#snippet children({ row })}
							<tr>
								<Td property="lookup">
									{row.lookup}
								</Td>
								<Td property="quantity">
									{row.quantity}
								</Td>
								<Td property="quantityReceived">
									{row.quantityReceived}
								</Td>
								<Td property="description">
									{row.description}
								</Td>
								<Td property="price">
									{formatCurrency(row.price)}
								</Td>
								<Td property="total">
									{formatCurrency(row.total)}
								</Td>
							</tr>
						{/snippet}
					</Table>
				{/if}
			</div>
		</div>
	{/if}
{/if}

<ReportJobModal bind:this={reportJobModalComponent} />
