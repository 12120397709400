export default {
    "name": "UserAccount",
    "kind": "HoudiniQuery",
    "hash": "70b2d20c6c93b8f0bd70bec6dfab2a62b15dae7fcc3022cbf6c64d96a6461e39",

    "raw": `query UserAccount($userAccountId: Int!) {
  userAccount(id: $userAccountId) {
    ...UserAccountFields
    id
  }
}

fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastAccessDate: lastAccess
  workEmail
  recoveryEmail
  lockNotes
  lastPasswordResetDate
  groups {
    id
  }
  authorizedStores {
    id
  }
  userPermissions {
    id
    value: level
  }
  wageRate {
    id
  }
  salesPerson
  driver
  worker
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccount": {
                "type": "UserAccount",
                "keyRaw": "userAccount(id: $userAccountId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastAccessDate": {
                            "type": "DateTime",
                            "keyRaw": "lastAccessDate",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "String",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "lockNotes": {
                            "type": "String",
                            "keyRaw": "lockNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "lastPasswordResetDate": {
                            "type": "DateTime",
                            "keyRaw": "lastPasswordResetDate",
                            "nullable": true,
                            "visible": true
                        },

                        "groups": {
                            "type": "Group",
                            "keyRaw": "groups",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "authorizedStores": {
                            "type": "Store",
                            "keyRaw": "authorizedStores",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userPermissions": {
                            "type": "UserAccountPermission",
                            "keyRaw": "userPermissions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionLevel",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "wageRate": {
                            "type": "WageRate",
                            "keyRaw": "wageRate",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "salesPerson": {
                            "type": "Boolean",
                            "keyRaw": "salesPerson",
                            "visible": true
                        },

                        "driver": {
                            "type": "Boolean",
                            "keyRaw": "driver",
                            "visible": true
                        },

                        "worker": {
                            "type": "Boolean",
                            "keyRaw": "worker",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "UserAccountFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=592140cc0a016c9fde8d42e116043cf33f3e153e2a9819e5d08729d7c6bae956";