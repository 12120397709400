export default {
    "name": "InitialCustomerPageLoad",
    "kind": "HoudiniQuery",
    "hash": "b1a964d37d40bdfb25d06097cd0e25786520c3116743bd01c69fb2c50ec84311",

    "raw": `query InitialCustomerPageLoad($filter: UserAccountFilter) {
  states {
    country
    stateAbbreviation
    stateCode
    stateName
  }
  paymentMethods {
    id
    name
  }
  userAccounts(filter: $filter) {
    id
    name
  }
  taxItems {
    id
    name
  }
  salesOrderTerms {
    id
    name
    code
    customerMemo
  }
  stores {
    id
    name
  }
  customerTypes
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "states": {
                "type": "State",
                "keyRaw": "states",

                "selection": {
                    "fields": {
                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "nullable": true,
                            "visible": true
                        },

                        "stateAbbreviation": {
                            "type": "String",
                            "keyRaw": "stateAbbreviation",
                            "nullable": true,
                            "visible": true
                        },

                        "stateCode": {
                            "type": "Int",
                            "keyRaw": "stateCode",
                            "nullable": true,
                            "visible": true
                        },

                        "stateName": {
                            "type": "String",
                            "keyRaw": "stateName",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "paymentMethods": {
                "type": "PaymentMethod",
                "keyRaw": "paymentMethods",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "taxItems": {
                "type": "TaxItem",
                "keyRaw": "taxItems",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "salesOrderTerms": {
                "type": "Term",
                "keyRaw": "salesOrderTerms",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "customerMemo": {
                            "type": "String",
                            "keyRaw": "customerMemo",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "stores": {
                "type": "Store",
                "keyRaw": "stores",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "customerTypes": {
                "type": "String",
                "keyRaw": "customerTypes",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "UserAccountFilter"
        },

        "types": {
            "UserAccountFilter": {
                "authorizedStores": "UInt",
                "isSalesPerson": "Boolean",
                "name": "String",
                "status": "UserAccountStatusEnum"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b3c228880367dc367a7fbf9cdcffd7f7f468ae57be30e154f36d74ed40d4b878";