import type { AppContext } from 'types/common'

import component from './Results.svelte'
import { graphql, type VendorSearch$result } from '$houdini'

export type VendorSearchResult = VendorSearch$result['vendors']['items'][number]

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.vendor-search.results',
		route: 'results',
		querystringParameters: ['pageNumber', 'perPageCount', 'keyword'],
		defaultParameters: {
			pageNumber: '1',
			perPageCount: '10',
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters) {
			const pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = parseInt(parameters.perPageCount, 10) || 10

			const { data } = await vendorsQuery.fetch({
				variables: {
					filter: {
						keyword: parameters.keyword,
					},
					pagination: {
						pageNumber,
						pageSize,
					},
				},
			})

			if (!data) {
				throw new Error('No data returned from server')
			}

			return {
				vendors: data.vendors.items,
				perPageCount: data.vendors.pageInfo.pageSize.toString(),
				currentPageNumber: data.vendors.pageInfo.pageNumber,
				totalItemsCount: data.vendors.totalItems,
			}
		},
	})
}

const vendorsQuery = graphql(`
	query VendorSearch($pagination: PaginationOptions, $filter: VendorFilter) {
		vendors(pagination: $pagination, filter: $filter) {
			pageInfo {
				pageNumber
				pageSize
				totalPages
			}
			totalItems
			items {
				companyName
				contactName
				code
				phoneNumber
				faxNumber
				mobilePhoneNumber
				type
				id
				dateEntered
				email
				webAddress
				active
				billingAddress {
					address1
					city
					country
					state
					zip
				}
				optionValues {
					option {
						id
						name
						type
						showInList
					}
					value
				}
			}
		}
	}
`)
