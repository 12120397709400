<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import type { Column } from '@isoftdata/svelte-table'
	import type { VendorSearchResult } from './results'
	import { perPageCounts, type PerPageCount } from 'utility/search-fields'

	import Select from '@isoftdata/svelte-select'
	import Table, { Td } from '@isoftdata/svelte-table'
	import BooleanIndicator from 'components/BooleanIndicator.svelte'
	import { stringToBoolean } from '@isoftdata/utility-string'

	export let asr: SvelteAsr
	export let vendors: VendorSearchResult[]
	export let perPageCount: PerPageCount
	export let currentPageNumber = 1
	export let totalItemsCount: number | undefined = undefined

	// TODO:
	// - add title on all the columns, could get it from the desktop
	// - add column hiding and column resizing

	const columns: Column<VendorSearchResult>[] = [
		{
			name: 'Company Name',
			property: 'companyName',
			title: '',
		},
		{
			name: 'Contact Name',
			property: 'contactName',
			title: '',
		},
		{
			name: 'Code',
			property: 'code',
			title: '',
			align: 'right',
		},
		{
			name: 'Phone Number',
			property: 'phoneNumber',
			title: '',
			sortType: false,
		},
		{
			name: 'Fax Number',
			property: 'faxNumber',
			title: '',
			sortType: false,
		},
		{
			name: 'Mobile Number',
			property: 'mobilePhoneNumber',
			title: '',
			sortType: false,
		},
		{
			name: 'Type',
			property: 'type',
			title: '',
		},
		{
			name: 'Number',
			property: 'id',
			title: '',
			align: 'right',
		},
		{
			name: 'Date Entered',
			property: 'dateEntered',
			title: '',
			align: 'right',
		},
		{
			name: 'Street Address',
			property: 'billingAddress[address1]',
			title: '',
		},
		{
			name: 'City',
			property: 'billingAddress[city]',
			title: '',
		},
		{
			name: 'State',
			property: 'billingAddress[state]',
			title: '',
		},
		{
			name: 'Zip',
			property: 'billingAddress[zip]',
			title: '',
			align: 'right',
		},
		{
			name: 'Country',
			property: 'billingAddress[country]',
			title: '',
		},
		{
			name: 'Email Address',
			property: 'email',
			title: '',
		},
		{
			name: 'Web Address',
			property: 'webAddress',
			title: '',
		},
		{
			name: 'Active',
			property: 'active',
			align: 'center',
		},
		...getVendorCustomFieldColumns(vendors[0]),
	]

	function getVendorCustomFieldColumns(vendor: VendorSearchResult) {
		if (!vendor) {
			return []
		}
		const vendorOptionColumns: Column[] = []
		vendor.optionValues.forEach((optionValue, index) => {
			if (optionValue.option.showInList) {
				vendorOptionColumns.push({
					property: `optionValues[${index}][value]`,
					name: optionValue.option.name,
					align: (optionValue.option.type === 'CURRENCY' && 'right') || (optionValue.option.type === 'BOOLEAN' && 'center') || undefined,
				})
			}
		})
		return vendorOptionColumns
	}
</script>

<div class="card-body">
	<div class="d-flex justify-content-end align-items-center">
		<span class="mr-auto">{totalItemsCount ? `${totalItemsCount} Vendors Found` : 'No vendors found'}</span>
		<Select
			label="Results Per Page"
			labelClass="mr-2"
			labelParentClass="form-inline mb-0"
			showEmptyOption={false}
			bind:value={perPageCount}
			on:change={() => {
				asr.go(null, { perPageCount, pageNumber: 1 }, { inherit: true })
			}}
		>
			{#each perPageCounts as perPageCount}
				<option value={perPageCount}>{perPageCount}</option>
			{/each}
		</Select>
	</div>
	<Table
		responsive
		stickyHeader
		columnHidingEnabled
		{columns}
		{currentPageNumber}
		{totalItemsCount}
		rows={vendors}
		perPageCount={parseInt(perPageCount, 10)}
		parentStyle="max-height: 60vh"
		pageChange={({ pageNumber }) => asr.go(null, { pageNumber }, { inherit: true })}
	>
		{#snippet noRows({ visibleColumnsCount })}
			<tr>
				<td
					colspan={visibleColumnsCount}
					class="text-center">No vendors found</td
				>
			</tr>
		{/snippet}
		{#snippet children({ row: vendor })}
			<tr
				class="cursor-pointer"
				on:click={() => asr.go('app.vendor', { vendorId: vendor.id })}
			>
				<Td property="companyName">{vendor.companyName}</Td>
				<Td property="contactName">{vendor.contactName}</Td>
				<Td property="code">{vendor.code}</Td>
				<Td
					property="phoneNumber"
					class="text-nowrap"
				>
					{vendor.phoneNumber}
				</Td>
				<Td
					property="faxNumber"
					class="text-nowrap"
				>
					{vendor.faxNumber}
				</Td>
				<Td property="mobilePhoneNumber">{vendor.mobilePhoneNumber}</Td>
				<Td property="type">{vendor.type}</Td>
				<Td property="id">{vendor.id}</Td>
				<Td property="dateEntered">{vendor.dateEntered ? vendor.dateEntered.toLocaleDateString() : 'N/A'}</Td>
				<Td property="billingAddress[address1]">{vendor.billingAddress.address1}</Td>
				<Td property="billingAddress[city]">{vendor.billingAddress.city}</Td>
				<Td property="billingAddress[state]">{vendor.billingAddress.state}</Td>
				<Td property="billingAddress[zip]">{vendor.billingAddress.zip}</Td>
				<Td property="billingAddress[country]">{vendor.billingAddress.country}</Td>
				<Td property="email">{vendor.email}</Td>
				<Td property="webAddress">{vendor.webAddress}</Td>
				<Td property="active">
					<BooleanIndicator value={vendor.active} />
				</Td>
				{#each vendor.optionValues as optionValue, index}
					{#if optionValue.option.showInList}
						<Td
							property={`optionValues[${index}][value]`}
							class={optionValue.option.type === 'CURRENCY' ? 'text-right' : ''}
						>
							{#if optionValue.option.type === 'DATE'}
								{optionValue.value ? new Date(optionValue.value).toLocaleDateString() : ''}
							{:else if optionValue.option.type === 'BOOLEAN'}
								<BooleanIndicator value={stringToBoolean(optionValue.value)} />
							{:else}
								{optionValue.value}
							{/if}
						</Td>
					{/if}
				{/each}
			</tr>
		{/snippet}
	</Table>
</div>

<style>
	* :global(.text-dark-goldenrod) {
		color: darkgoldenrod;
	}
</style>
