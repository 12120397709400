export default {
    "name": "UserAccounts",
    "kind": "HoudiniQuery",
    "hash": "fded9c85a4c96d84c1ce0c2b4795fe31a26637f65f277b3211c6e18220d8f045",

    "raw": `query UserAccounts($filter: UserAccountFilter) {
  userAccounts(filter: $filter) {
    id
    name
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "UserAccountFilter"
        },

        "types": {
            "UserAccountFilter": {
                "authorizedStores": "UInt",
                "isSalesPerson": "Boolean",
                "name": "String",
                "status": "UserAccountStatusEnum"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=34d2a227727d284cfe6b3c06cbbe41cdb79903d2f7d87aa8e8e75d871e944568";