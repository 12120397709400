<script lang="ts">
	import type { FlatAttachment, PartForClient as Part, SettingValues, UserSettings, CollectionMember, LoadedCollectionMember } from 'utility/load-part'
	import type { SvelteAsr } from 'types/common'
	import type { BreakdownTemplates$result, LIB_partStoreData$result, PartScreenStageOne$result, CollectionMembershipLocalInventory$data } from '$houdini'
	import type { PartModel } from 'utility/load-models-for-manufacturer'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'

	import Table, { Td, TreeRow, treeify, type Column, type TreeNode, type IndexedRowProps } from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import PartSearchModal from 'components/PartSearchModal.svelte'
	import Modal from '@isoftdata/svelte-modal'
	import PartInfo from './Basic.svelte'
	import { Attachments } from '@isoftdata/svelte-attachments'

	import { graphql } from '$houdini'
	import { getContext, onMount, tick } from 'svelte'
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import { v4 as uuid } from '@lukeed/uuid'
	import { loadPart, getOptionValueMap, computeTypeFields, newPart } from 'utility/load-part'
	import { klona } from 'klona'
	import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
	import { getEventValue, getEventChecked, getEventValueEnum } from '@isoftdata/browser-event'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import userLocalWritable from 'stores/user-local-writable'
	import session from 'stores/session'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Input from '@isoftdata/svelte-input'
	import { dequal } from 'dequal'
	import Icon from '@isoftdata/svelte-icon'

	export let part: Part
	export let asr: SvelteAsr
	export let settingValues: SettingValues
	export let userSettings: UserSettings

	// part-basic stuff
	export let assyModels: Array<PartModel>
	export let glCategories: PartScreenStageOne$result['glCategories']
	export let inventoryConditions: PartScreenStageOne$result['inventoryConditions']
	export let inventoryTypeList: PartScreenStageOne$result['inventoryTypeList']
	/** "True" Models*/
	export let partModels: Array<PartModel>
	export let partStoreLocations: LIB_partStoreData$result['locations']
	export let saleClasses: PartScreenStageOne$result['saleClasses']
	export let sellPriceClasses: PartScreenStageOne$result['sellPriceClasses']
	export let userPartStatusList: PartScreenStageOne$result['userPartStatusList']
	export let vehicleMakes: PartScreenStageOne$result['vehicleMakes']
	export let vehicleModels: Array<string>
	export let vendorList: PartScreenStageOne$result['vendors']['items']

	let membersTable: Table<CollectionMember> | undefined = undefined
	let membershipTable: Table<MembershipNode> | undefined = undefined
	let breakdownTemplates: BreakdownTemplates$result['inventoryType']['breakdownTemplates'] = []
	let selectedMemberIndex: number | null = null
	let loadingMemberIndex: number | null = null
	let partSearchModal: PartSearchModal | undefined = undefined
	let breakdownTemplate: BreakdownTemplates$result['inventoryType']['breakdownTemplates'][number] | null = null
	let showPartModal = false
	let breakpoint = getBootstrapBreakpoint()
	let sortedRows: Array<CollectionMember & IndexedRowProps> = []
	let showAttachmentModal = false
	let collectionMembershipTree = makeCollectionMembershipTree()

	const viewMode = userLocalWritable($session.id, 'partLinksViewMode', 'LIST')
	const collectionCrudStore = getContext<CrudStore<CollectionMember, 'uuid'>>('collectionCrudStore')

	$: membersTable?.setColumnVisibility(['costFactor', 'integral'], collectionType === 'ASSEMBLY')
	$: membersTable?.setColumnVisibility(
		['part[side]', 'part[cost]', 'part[retailPrice]', 'part[wholesalePrice]', 'part[jobberPrice]', 'part[retailCorePrice]'],
		collectionType === 'ASSEMBLY' && $viewMode === 'TABLE',
	)
	$: membersTable?.setColumnVisibility(
		['costFactor', 'integral', 'part[parentManufacturer][name]', 'part[parentModel][name]', 'part[manufacturer][name]', 'part[model][name]', 'part[category][name]'],
		$viewMode === 'TABLE',
	)
	$: isCollection = !!part.collection
	$: collectionType = part.collection?.type ?? null
	// $: membersTable?.setColumnVisibility(['part[attachmentCount]'], $viewMode === 'LIST')
	$: selectedMember = selectedMemberIndex !== null ? (part.collection?.members[selectedMemberIndex] ?? null) : null
	$: isMobile = breakpoint === 'xs' || breakpoint === 'sm'
	$: showSplitView = !!selectedMember && !isMobile && $viewMode === 'LIST'

	// Really terrible change detection since the PartInfo component wasn't built to do this
	$: part.collection?.members.forEach(member => collectionMemberHasChanged(member))
	$: $collectionCrudStore && console.log('collection updates', collectionCrudStore.updatedValues)
	$: nextIndex = getNextOrPrevMemberIndex(sortedRows, selectedMemberIndex, 1)
	$: prevIndex = getNextOrPrevMemberIndex(sortedRows, selectedMemberIndex, -1)

	type MembershipRow = CollectionMembershipLocalInventory$data & { parentPartId: number | null }
	type MembershipNode = TreeNode<MembershipRow, 'innodbInventoryid', 'parentPartId'>

	function makeCollectionMembershipTree(): Array<MembershipNode> {
		if (!part.collectionMembership.length) {
			return []
		}

		const parentPart = part.collectionMembership[0].collection.inventory
			? {
					...part.collectionMembership[0].collection.inventory,
					parentPartId: null,
				}
			: null

		const siblings = part.collectionMembership[0].collection.members.reduce((acc: Array<MembershipRow>, member) => {
			if (member?.localInventory) {
				acc.push({
					...member.localInventory,
					parentPartId: parentPart?.innodbInventoryid ?? null,
				})
			}
			return acc
		}, [])

		// Parent part might not be at the current store, so fall back to just rendering the siblings in that case?
		const rowsToTreeify = parentPart ? [parentPart, ...siblings] : siblings

		return treeify<MembershipRow, 'innodbInventoryid', 'parentPartId'>(rowsToTreeify, 'innodbInventoryid', 'parentPartId')
	}

	function collectionMemberHasChanged(member: CollectionMember): boolean {
		if (!member.loaded || member.template) {
			return false
		}
		// Will also catch changes to Q&A
		const crudHasChanges = collectionCrudStore.isCreated(member) || collectionCrudStore.isUpdated(member) || collectionCrudStore.isDeleted(member)
		if (crudHasChanges) {
			return true
		}
		const partHasChanges = !dequal(member.part, member.origPart)
		if (partHasChanges) {
			collectionCrudStore.update(member)
			return true
		}

		const attachmentsHaveChanges = member.attachments.some(attachment => !!attachment.action)
		if (attachmentsHaveChanges) {
			collectionCrudStore.update(member)
			return true
		}
		return false
	}

	function newCollectionMember(): void {
		if (!part.collection) {
			return
		}
		const newMember = newPart(part.storeId, settingValues, userSettings)

		// get default location from parent part
		if (part.locations.length) {
			newMember.part.locations = [
				{
					...klona(part.locations[0]),
					uuid: uuid(),
				},
			]
		}

		part.collection.members.push(makeCollectionMember(newMember.part, newMember.attachments))
		part.collection.members = part.collection.members
		selectedMemberIndex = part.collection.members.length - 1
		if (isMobile || $viewMode === 'TABLE') {
			showPartModal = true
		}
		collectionCrudStore.create(part.collection.members[selectedMemberIndex])
	}

	async function loadCollectionMember(
		partial: { innodbInventoryid: number; storeId: number; uuid?: string },
		memberInfo?: {
			id?: number
			costFactor: string
			quantity: string
			integral: boolean
		},
	): Promise<LoadedCollectionMember> {
		const { part, attachments } = await loadPart(partial.innodbInventoryid, false, settingValues, userSettings, partial.storeId)

		part.glCategory = glCategories.find(cat => cat.id === part.glCategory?.id) ?? null
		part.saleClass = saleClasses.find(saleClass => saleClass.code === part.saleClass?.code) ?? part.saleClass

		return makeCollectionMember(part, attachments, memberInfo)
	}

	function makeCollectionMember(
		part: Part,
		attachments: Array<FlatAttachment>,
		collectionMemberInfo: {
			id?: number
			costFactor: string
			quantity: string
			integral: boolean
		} = { costFactor: '0', quantity: '1', integral: false },
		template: boolean | undefined = undefined,
	): LoadedCollectionMember {
		return {
			...collectionMemberInfo,
			part,
			attachments,
			loaded: true,
			origPart: klona(part),
			optionValueMap: getOptionValueMap(part),
			typeFields: computeTypeFields(part, settingValues),
			template,
			uuid: uuid(),
		}
	}

	// function collectionMemberIsLoaded(member: CollectionMember): member is LoadedCollectionMember {
	// 	return member.loaded
	// }

	function getNextOrPrevMemberIndex(rows: Array<CollectionMember & IndexedRowProps>, selectedMemberIndex: number | null, offset: 1 | -1) {
		const currentSortedIndex = rows.findIndex(row => row.originalIndex === selectedMemberIndex)
		const nextIndex = currentSortedIndex + offset
		if (nextIndex >= 0 && nextIndex < rows.length) {
			return rows[nextIndex].originalIndex
		}
		return null
	}

	async function collectionMemberSelected(index: number, doNotOpen = false): Promise<void> {
		if (collectionType !== 'ASSEMBLY' || index === loadingMemberIndex || !part.collection) {
			return
		}

		const member = part.collection.members[index]

		if (!member.loaded && member.part.innodbInventoryid) {
			loadingMemberIndex = index

			part.collection.members[index] = await loadCollectionMember(member.part, member)
			await tick()
			loadingMemberIndex = null
		}
		if (!doNotOpen && (isMobile || $viewMode === 'TABLE')) {
			showPartModal = true
		}
		selectedMemberIndex = index
	}

	function onTemplateChange(): void {
		if (!part.collection) {
			return
		}
		// remove existing template members
		part.collection.members = part.collection.members.filter(member => !member.template)
		if (breakdownTemplate) {
			// add new template members
			const templateParts = (breakdownTemplate.components ?? []).reduce((acc: Array<LoadedCollectionMember>, component) => {
				const foundType = inventoryTypeList.find(it => it.inventoryTypeId === component.inventoryTypeId) ?? null
				// if the inventory type isn't in the array, it is not active, so don't add the part
				if (foundType) {
					const newComponent = newPart(part.storeId, settingValues, userSettings)
					newComponent.part.inventoryTypeId = component.inventoryTypeId ?? null
					newComponent.part.inventoryType = foundType
						? {
								id: foundType.inventoryTypeId,
								name: foundType.name,
								setId: foundType.typeSetId,
								typeData1History: [],
								typeData2History: [],
								typeData3History: [],
								typeData4History: [],
							}
						: null
					acc.push(makeCollectionMember(newComponent.part, newComponent.attachments, undefined, true))
				}

				return acc
			}, [])

			part.collection.members.push(...templateParts)
			part.collection.members = part.collection.members
		}
	}

	function removeCollectionItem(index: number): void {
		if (!part.collection) {
			return
		}

		if (part.collection.members[index].template === false) {
			// if we remove a template item, just set it back as a template item
			part.collection.members[index].template = true
			collectionCrudStore.delete(part.collection.members[index])
		} else if (selectedMemberIndex !== null) {
			collectionCrudStore.delete(part.collection.members[index])
			part.collection.members.splice(index, 1)
			part.collection.members = part.collection.members
			if (selectedMemberIndex === index) {
				selectedMemberIndex = part.collection.members.length ? 0 : null
			}
		}
	}

	function updateCollectionItem(index: number | null) {
		if (index !== null && part.collection?.members[index]) {
			collectionCrudStore.update(part.collection.members[index])
		}
	}

	// #region Constants
	const membersColumns: Array<Column<CollectionMember>> = [
		{
			name: 'Status',
			property: 'part[status]',
			width: '1rem',
			footer: {
				fn: (count, status) => {
					if (status === 'A' && typeof count === 'number') {
						count++
					}
					return count
				},
				initialValue: 0,
			},
		},
		{
			name: 'Tag #',
			property: 'part[tagNumber]',
		},
		{
			name: 'Type #',
			property: 'part[inventoryType][id]',
			numeric: true,
		},
		{
			name: 'Part Type',
			property: 'part[inventoryType][name]',
		},
		{
			name: '',
			icon: 'paperclip',
			property: 'part[attachmentCount]',
			width: '1rem',
			align: 'center',
			footer: {
				fn: 'SUM',
			},
		},
		{
			name: 'Cost Factor',
			property: 'costFactor',
		},
		{
			name: 'Integral',
			property: 'integral',
		},
		{
			name: 'Assy. Manufacturer',
			property: 'part[parentManufacturer][name]',
		},
		{
			name: 'Assy. Model',
			property: 'part[parentModel][name]',
		},

		{
			name: 'Manufacturer',
			property: 'part[manufacturer][name]',
		},
		{
			name: 'Model',
			property: 'part[model][name]',
		},
		{
			name: 'Category',
			property: 'part[category][name]',
		},
		{
			name: 'Side',
			property: 'part[side]',
		},
		{
			name: 'Cost',
			property: 'part[cost]',
			numeric: true,
			footer: {
				fn: 'SUM',
				formatCurrency: true,
			},
		},
		{
			name: 'Retail',
			property: 'part[retailPrice]',
			numeric: true,
			footer: {
				fn: 'SUM',
				formatCurrency: true,
			},
		},
		{
			name: 'Wholesale',
			property: 'part[wholesalePrice]',
			numeric: true,
			footer: {
				fn: 'SUM',
				formatCurrency: true,
			},
		},
		{
			name: 'Jobber',
			property: 'part[jobberPrice]',
			numeric: true,
			footer: {
				fn: 'SUM',
				formatCurrency: true,
			},
		},
		{
			name: 'Retail Core',
			property: 'part[retailCorePrice]',
			numeric: true,
			footer: {
				fn: 'SUM',
				formatCurrency: true,
			},
		},
	] as const
	// Desktop only displays "one level" of collections, IE, this part and its parent collection, even if that collection is in another collection
	const collectionsColumns: Array<Column<MembershipNode>> = [
		{
			name: 'Tag #',
			property: 'tagNumber',
		},
		{
			name: '',
			property: 'innodbInventoryid',
			title: 'View Part',
			width: '1rem',
			icon: 'search',
			sortType: false,
			align: 'center',
		},
		{
			name: 'Part Type',
			property: 'inventoryType[id]',
		},
		{
			name: 'Quantity',
			property: 'quantity',
		},
		{
			name: 'QOH',
			property: 'quantityAvailable',
		},
		{
			name: 'Manufacturer',
			property: 'manufacturer[name]',
		},
		{
			name: 'Model',
			property: 'model[name]',
		},
		{
			name: 'Category',
			property: 'category[name]',
		},
	]
	// #endregion
	onMount(async () => {
		membershipTable?.expandRow(collectionMembershipTree[0].innodbInventoryid, true)

		if (part.collection?.members.length) {
			collectionMemberSelected(0, true)
		}
		if (part.inventoryType) {
			const { data } = await breakdownTemplatesQuery.fetch({
				variables: {
					inventoryTypeId: part.inventoryType?.id,
				},
			})
			breakdownTemplates = data?.inventoryType?.breakdownTemplates ?? []
		}
	})

	// #region Queries
	const breakdownTemplatesQuery = graphql(`
		query BreakdownTemplates($inventoryTypeId: Int!) {
			inventoryType(id: $inventoryTypeId) {
				breakdownTemplates {
					components {
						integral
						inventoryTypeId
						quantity
						rank
					}
					default
					id
					name
				}
			}
		}
	`)
	// #endregion
</script>

<svelte:window on:resize={() => (breakpoint = getBootstrapBreakpoint())} />

<div class="form-row">
	<div
		class="col-12"
		class:col-lg-4={selectedMemberIndex !== null && showSplitView}
	>
		<div style="position: sticky; top: 0.5rem;">
			<div class="card">
				<div class="card-header h4">Collection Information</div>
				<div class="card-body">
					<Table
						responsive
						showFooter
						filterEnabled
						columnHidingEnabled
						idProp="uuid"
						rowSelectionIdProp="uuid"
						headerColumnClass="col"
						filterColumnClass="{showSplitView ? '' : 'col-md-4 col-lg-2'} col-12 align-self-end"
						parentStyle="overflow-y: auto; max-height: 50vh;"
						columns={membersColumns}
						rows={part.collection?.members ?? []}
						bind:sortedRows
						bind:this={membersTable}
					>
						{#snippet header()}
							<div class="form-row mb-3">
								<div
									class="col-auto align-content-end mr-auto mr-md-0"
									class:flex-grow-1={showSplitView}
								>
									<Checkbox
										label="This Part is a Collection"
										checked={isCollection}
										on:change={event => {
											if (getEventChecked(event)) {
												part.collection = {
													type: 'ASSEMBLY',
													members: [],
												}
											} else {
												part.collection = null
											}
										}}
									></Checkbox>
								</div>

								<div
									class="col-auto align-content-end"
									class:col-2={showSplitView}
									class:order-md-last={!showSplitView}
								>
									<div>
										<Checkbox
											type="radio"
											showLabel={false}
											trueLabel=""
											trueIcon="table"
											falseLabel=""
											falseIcon="list"
											checked={$viewMode === 'TABLE'}
											on:change={event => {
												$viewMode = stringToBoolean(getEventValue(event)) ? 'TABLE' : 'LIST'
												if ($viewMode === 'TABLE') {
													selectedMemberIndex = null
												}
											}}
										></Checkbox>
									</div>
								</div>
								<div
									class:col-6={showSplitView && collectionType === 'ASSEMBLY'}
									class:col-12={!showSplitView || collectionType !== 'ASSEMBLY'}
									class:col-md-4={!showSplitView}
									class:col-lg-2={!showSplitView}
								>
									<Select
										label="Collection Type"
										labelParentClass="mb-0"
										required={isCollection}
										disabled={!isCollection}
										value={part.collection?.type ?? null}
										on:change={event => {
											if (!part.collection) {
												return
											}
											part.collection.type = getEventValueEnum(event, 'ASSEMBLY', 'KIT', 'ORDERED_KIT', 'TEMPLATE')
											if (part.collection.type !== 'ASSEMBLY') {
												breakdownTemplate = null
											}
										}}
									>
										<option value="ASSEMBLY">Assembly</option>
										<option value="KIT">Kit</option>
										<option value="ORDERED_KIT">Ordered Kit</option>
										<option value="TEMPLATE">Template</option>
									</Select>
								</div>
								{#if collectionType === 'ASSEMBLY'}
									<div
										class="mr-md-auto"
										class:col-6={showSplitView}
										class:col-12={!showSplitView}
										class:col-md-4={!showSplitView}
										class:col-lg-2={!showSplitView}
									>
										<Select
											label="Template"
											labelParentClass="mb-0"
											disabled={collectionType !== 'ASSEMBLY'}
											options={breakdownTemplates}
											bind:value={breakdownTemplate}
											on:change={onTemplateChange}
											let:option
										>
											{#if option}
												<option value={option}>{option.name}</option>
											{/if}
										</Select>
									</div>
								{/if}
							</div>
						{/snippet}

						{#snippet body({ rows, visibleColumnsCount })}
							{#if rows.length}
								{#each rows as { part: rowPart, originalIndex, template, ...row } (row.uuid)}
									<tr
										class="text-nowrap cursor-pointer"
										class:table-primary={selectedMemberIndex === originalIndex || loadingMemberIndex === originalIndex}
										on:click={() => collectionMemberSelected(originalIndex)}
									>
										<Td property="part[status]">
											<span class="mr-2">{rowPart.status}</span>
											{#if template}
												<Button
													outline
													size="sm"
													color="success"
													iconClass="plus"
													on:click={() => {
														if (!part.collection) {
															return
														}

														part.collection.members[originalIndex].template = false
														selectedMemberIndex = originalIndex
														collectionCrudStore.create(part.collection.members[originalIndex])
													}}
												></Button>
											{:else if !rowPart.innodbInventoryid}
												<!-- Remove template or other item -->
												<Button
													outline
													size="sm"
													color="danger"
													iconClass="trash-undo"
													on:click={() => removeCollectionItem(originalIndex)}
												></Button>
											{/if}
											{#if loadingMemberIndex === originalIndex}
												<Icon
													fixedWidth
													isLoading
												></Icon>
											{/if}
										</Td>
										<Td
											stopPropagation
											property="part[tagNumber]"
										>
											{#if rowPart.innodbInventoryid && rowPart.tagNumber}
												<a href={asr.makePath('app.part', { inventoryId: rowPart.innodbInventoryid, storeId: rowPart.storeId, tab: 'links' })}>{rowPart.tagNumber}</a>
											{:else if rowPart.tagNumber}
												{rowPart.tagNumber}
											{:else}
												<i class="text-muted">N/A</i>
											{/if}
										</Td>
										<Td property="part[inventoryType][id]">
											{#if rowPart.inventoryType}
												{rowPart.inventoryType.id}
											{/if}
										</Td>
										<Td property="part[inventoryType][name]">
											{rowPart.inventoryType?.name ?? ''}
										</Td>
										<Td property="part[attachmentCount]">
											<Button
												outline
												size="sm"
												iconClass="paperclip"
												on:click={async () => {
													await collectionMemberSelected(originalIndex, true)
													showAttachmentModal = true
												}}>{rowPart.attachmentCount}</Button
											>
										</Td>
										<Td property="costFactor">
											{row.costFactor ?? ''}
										</Td>
										<Td property="integral">
											{row.integral ? 'Yes' : 'No'}
										</Td>
										<Td property="part[parentManufacturer][name]">
											{rowPart.parentManufacturer?.name ?? ''}
										</Td>
										<Td property="part[parentModel][name]">
											{rowPart.parentModel?.name ?? ''}
										</Td>
										<Td property="part[manufacturer][name]">
											{rowPart.manufacturer?.name ?? ''}
										</Td>
										<Td property="part[model][name]">
											{rowPart.model?.name ?? ''}
										</Td>
										<Td property="part[category][name]">
											{rowPart.category?.name ?? ''}
										</Td>
										<Td property="part[side]">
											{rowPart.side}
										</Td>
										<Td property="part[cost]">
											{formatCurrency(rowPart.cost)}
										</Td>
										<Td property="part[retailPrice]">
											{formatCurrency(rowPart.retailPrice)}
										</Td>
										<Td property="part[wholesalePrice]">
											{formatCurrency(rowPart.wholesalePrice)}
										</Td>
										<Td property="part[jobberPrice]">
											{formatCurrency(rowPart.jobberPrice)}
										</Td>
										<Td property="part[retailCorePrice]">
											{formatCurrency(rowPart.retailCorePrice)}
										</Td>
									</tr>
								{/each}
							{:else}
								<tr>
									<td
										class="text-center"
										colspan={visibleColumnsCount}
										>{#if isCollection}This collection has no members{:else}This part is not a collection{/if}</td
									>
								</tr>
							{/if}
						{/snippet}
					</Table>
				</div>
				<div class="card-footer d-flex justify-content-between flex-wrap">
					<div>
						{#if collectionType === 'ASSEMBLY'}
							<Button
								outline
								size="sm"
								color="success"
								iconClass="plus"
								title="Add a new part to this collection"
								on:click={newCollectionMember}
								>New Item
							</Button>
						{/if}
						<Button
							outline
							size="sm"
							color="success"
							icon={{
								prefix: 'far',
								icon: 'magnifying-glass-plus',
							}}
							title="Search for a part to add to this collection"
							on:click={() => partSearchModal?.show()}
							>Add Existing Part
						</Button>
						<Button
							outline
							size="sm"
							color="danger"
							iconClass="xmark"
							title="Remove this item from this collection"
							disabled={selectedMemberIndex === null}
							on:click={() => selectedMemberIndex !== null && removeCollectionItem(selectedMemberIndex)}
							>Remove
						</Button>
					</div>
					<div>
						<Button
							outline
							size="sm"
							iconClass="search"
							title="Search for parts in this collection"
							href={asr.makePath(
								'app.part-search.results',
								{
									ADDITIONAL_FIELDS: JSON.stringify({
										'collectionparent.tagnumber': part.tagNumber,
									}),
								},
								{ inherit: false },
							)}
							>Members
						</Button>
					</div>
				</div>
			</div>
			<div class="card mt-2">
				<div class="card-header h4">Collections Containing This Part</div>
				<div class="card-body">
					<Table
						tree
						responsive
						columnHidingEnabled
						columns={collectionsColumns}
						rows={collectionMembershipTree}
						bind:this={membershipTable}
					>
						{#snippet children({ row })}
							<TreeRow
								class="text-nowrap"
								property="tagNumber"
								idProp="innodbInventoryid"
								parentIdProp="parentPartId"
								node={row}
							>
								{#snippet children({ node })}
									<Td property="innodbInventoryid">
										<Button
											outline
											size="xs"
											class="ml-auto"
											iconClass="search"
											title="View this part"
											disabled={node.innodbInventoryid === part.innodbInventoryid}
											href={asr.makePath(null, { inventoryId: node.innodbInventoryid, storeId: node.storeId })}
										></Button>
									</Td>
									<Td property="inventoryType[id]">
										{node.inventoryType?.id} - {node.inventoryType?.name}
									</Td>
									<Td property="quantity">
										{node.quantity}
									</Td>
									<Td property="quantityAvailable">
										{node.quantityAvailable}
									</Td>
									<Td property="manufacturer[name]">
										{node.manufacturer?.name ?? ''}
									</Td>
									<Td property="model[name]">
										{node.model?.name ?? ''}
									</Td>
									<Td property="category[name]">
										{node.category?.name ?? ''}
									</Td>
								{/snippet}
							</TreeRow>
						{/snippet}
						{#snippet noRows({ visibleColumnsCount })}
							<tr slot="noRows">
								<td
									class="text-center"
									colspan={visibleColumnsCount}>This part is not a member of any collections</td
								>
							</tr>
						{/snippet}
					</Table>
				</div>
			</div>
		</div>
	</div>
	{#if selectedMemberIndex !== null && selectedMember?.loaded && part.collection && part.collection.members[selectedMemberIndex].loaded && showSplitView}
		<div class="col-12 col-md-8">
			<div class="d-flex justify-content-between mb-2">
				<div>
					{#if !selectedMember.part.innodbInventoryid && !selectedMember.template}
						<Button
							outline
							color="danger"
							iconClass="trash-undo"
							on:click={() => {
								// Template item
								if (selectedMemberIndex !== null) {
									removeCollectionItem(selectedMemberIndex)
								}
							}}
							>Undo New Part
						</Button>
					{/if}
					<!-- TODO File input? attachment component? Image viewer? -->
					<Button
						outline
						color="secondary"
						iconClass="camera"
						disabled={selectedMember.template}
						on:click={async () => {
							showAttachmentModal = true
							await tick()
							document.getElementById('attachment-upload')?.click()
						}}
					></Button>
					<Button
						outline
						iconClass="paperclip"
						on:click={() => {
							showAttachmentModal = true
						}}>{selectedMember.part.attachmentCount}</Button
					>
				</div>
				{#if selectedMember.template}
					<div class="text-center align-content-end">
						<h6 class="text-danger mb-0">Template</h6>
					</div>
				{/if}
				<div>
					<Button
						outline
						color="secondary"
						iconClass="caret-left"
						disabled={prevIndex === null}
						on:click={() => prevIndex !== null && collectionMemberSelected(prevIndex)}
						>Prev
					</Button>
					<Button
						outline
						color="secondary"
						iconClass="caret-right"
						disabled={nextIndex === null}
						on:click={() => nextIndex !== null && collectionMemberSelected(nextIndex)}
						>Next
					</Button>
				</div>
			</div>

			<!--
			TODO fix these type errors somehow, type narrowing isn't compatible with the way I need to pass this crap
			I've already tried narrowing by property and with a type guard fn, neither worked
			Also I can't even ignore the errors, lmao
			-->
			<!-- API does not and apparently will not support updating the inventory object for saved collection members, so don't let them edit saved collection members -->
			<PartInfo
				{asr}
				{assyModels}
				disabled={selectedMember.template || collectionType !== 'ASSEMBLY'}
				{glCategories}
				{inventoryConditions}
				inventoryTypeData={inventoryTypeList.find(it => it.inventoryTypeId === selectedMember.part.inventoryType?.id) ?? null}
				{inventoryTypeList}
				{partStoreLocations}
				{saleClasses}
				{sellPriceClasses}
				{settingValues}
				{userPartStatusList}
				{vehicleMakes}
				{vehicleModels}
				{vendorList}
				{partModels}
				bind:part={part.collection.members[selectedMemberIndex].part}
				bind:optionValueMap={part.collection.members[selectedMemberIndex].optionValueMap}
				bind:origPart={part.collection.members[selectedMemberIndex].origPart}
				bind:typeFields={part.collection.members[selectedMemberIndex].typeFields}
				on:qaInputChange={() => updateCollectionItem(selectedMemberIndex)}
			/>
			<CollapsibleCard
				cardClass="mt-2"
				headerText="Collection Member Info"
				cardHeaderClass="card-header d-flex justify-content-between h4"
				entireHeaderToggles
			>
				<div class="form-row">
					<div class="col-4">
						<Input
							label="Quantity"
							type="number"
							bind:value={part.collection.members[selectedMemberIndex].quantity}
							on:change={() => updateCollectionItem(selectedMemberIndex)}
						></Input>
					</div>
					<div class="col-4">
						<Input
							label="Cost Factor"
							type="number"
							bind:value={part.collection.members[selectedMemberIndex].costFactor}
							on:change={() => updateCollectionItem(selectedMemberIndex)}
						></Input>
					</div>
					<div class="col-4 align-content-end">
						<Checkbox
							label="Integral"
							bind:checked={part.collection.members[selectedMemberIndex].integral}
							on:change={() => updateCollectionItem(selectedMemberIndex)}
						></Checkbox>
					</div>
				</div>
			</CollapsibleCard>
		</div>
	{/if}
</div>

<PartSearchModal
	chooseItem={async item => {
		if (!part.collection) {
			return
		}

		const newMember = await loadCollectionMember({
			innodbInventoryid: item.id,
			storeId: item.store.id,
		})
		part.collection.members.push(newMember)
		part.collection.members = part.collection.members
		selectedMemberIndex = part.collection.members.length - 1
		collectionCrudStore.create(part.collection.members[selectedMemberIndex])
	}}
	bind:this={partSearchModal}
></PartSearchModal>

<Modal
	bind:show={showPartModal}
	modalSize="xxl"
	title="Edit Part"
	confirmShown={false}
	on:close={() => {
		showPartModal = false
		if (part.collection) {
			part.collection.members = part.collection.members
		}
	}}
>
	<!-- Ignore the type errors, they've become endemic and I can't get rid of them -->
	{#if selectedMemberIndex !== null && selectedMember?.loaded && part.collection}
		<PartInfo
			{asr}
			{assyModels}
			disabled={selectedMember.template || collectionType !== 'ASSEMBLY'}
			{glCategories}
			{inventoryConditions}
			inventoryTypeData={inventoryTypeList.find(it => it.inventoryTypeId === selectedMember.part.inventoryType?.id) ?? null}
			{inventoryTypeList}
			{partStoreLocations}
			{saleClasses}
			{sellPriceClasses}
			{settingValues}
			{userPartStatusList}
			{vehicleMakes}
			{vehicleModels}
			{vendorList}
			{partModels}
			bind:part={part.collection.members[selectedMemberIndex].part}
			bind:optionValueMap={part.collection.members[selectedMemberIndex].optionValueMap}
			bind:origPart={part.collection.members[selectedMemberIndex].origPart}
			bind:typeFields={part.collection.members[selectedMemberIndex].typeFields}
			on:qaInputChange={() => updateCollectionItem(selectedMemberIndex)}
		/>
		<CollapsibleCard
			cardClass="mt-2"
			headerText="Collection Member Info"
			cardHeaderClass="card-header d-flex justify-content-between h4"
			entireHeaderToggles
		>
			<div class="form-row">
				<div class="col-4">
					<Input
						label="Quantity"
						type="number"
						bind:value={part.collection.members[selectedMemberIndex].quantity}
						on:change={() => updateCollectionItem(selectedMemberIndex)}
					></Input>
				</div>
				<div class="col-4">
					<Input
						label="Cost Factor"
						type="number"
						bind:value={part.collection.members[selectedMemberIndex].costFactor}
						on:change={() => updateCollectionItem(selectedMemberIndex)}
					></Input>
				</div>
				<div class="col-4 align-content-end">
					<Checkbox
						label="Integral"
						bind:checked={part.collection.members[selectedMemberIndex].integral}
						on:change={() => updateCollectionItem(selectedMemberIndex)}
					></Checkbox>
				</div>
			</div>
		</CollapsibleCard>
	{/if}
</Modal>

<Modal
	bind:show={showAttachmentModal}
	confirmShown={false}
	title="{selectedMember?.part.tagNumber} Attachments"
	backdropClickCancels={false}
	modalSize="xxl"
	on:close={() => (showAttachmentModal = false)}
>
	{#if part.collection && selectedMemberIndex !== null}
		<Attachments
			bind:fileList={part.collection.members[selectedMemberIndex].attachments}
			on:filesAdded={event => {
				if (part.collection && selectedMemberIndex !== null) {
					part.collection.members[selectedMemberIndex].part.attachmentCount += event.detail.length
					updateCollectionItem(selectedMemberIndex)
				}
			}}
			on:filesDeleted={event => {
				if (part.collection && selectedMemberIndex !== null) {
					part.collection.members[selectedMemberIndex].part.attachmentCount -= event.detail.length
					updateCollectionItem(selectedMemberIndex)
				}
			}}
			on:publicChange={() => {
				updateCollectionItem(selectedMemberIndex)
			}}
			on:rankChange={() => {
				updateCollectionItem(selectedMemberIndex)
			}}
		></Attachments>
	{:else}
		<p>No part selected</p>
	{/if}
</Modal>
