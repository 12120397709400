<script lang="ts">
	import type { TypeField, PartForClient as Part, OptionValueMap, FlatQuestion, SettingValues } from 'utility/load-part'
	import type { PartScreenStageOne$result } from '$houdini'

	import Input from '@isoftdata/svelte-input'
	import QaInput from 'components/QaInput.svelte'
	import { createEventDispatcher } from 'svelte'

	import { graphql } from '$houdini'
	import { getEventValue } from '@isoftdata/browser-event'

	type Option = FlatQuestion

	export let typeFields: Array<TypeField>
	export let options: Array<FlatQuestion>
	export let disableGlobalInputs = false
	export let columnClass = 'col-12 col-md-6 col-xl-3 mt-auto'
	export let optionValueMap: OptionValueMap

	export let inventoryTypeData: PartScreenStageOne$result['inventoryTypeList'][number] | null
	export let serials: Part['serials']
	export let category: Part['category']
	export let selectedSerialId: number | null = null
	export let selectedSerialUuid: string | null = null

	let inventoryOptionsLoading = false
	$: inventoryTypeId = inventoryTypeData?.inventoryTypeId ?? 0

	let lastInventoryTypeId = inventoryTypeData?.inventoryTypeId ?? null

	const dispatch = createEventDispatcher<{
		/** The value of an inventory question has changed */
		qaInputChange: Option
		/** The value of an inventory type field has changed */
		typeFieldChange: {
			label: string
			value: string
		}
		/** The list of options has changed */
		optionListChange: Array<FlatQuestion>
		/** The list of type fields has changed */
		typeFieldListChange: Array<TypeField>
	}>()

	export async function fetchNewQuestions(filter: { inventoryTypeId: number; manufacturerId: number | null; modelId: number | null; categoryName: string }) {
		inventoryOptionsLoading = true
		let typeFieldsChanged = false

		const [{ data: optionsData }, { data: typeFieldsData }] = await Promise.all([
			inventoryOptionsQuery.fetch({
				variables: {
					inventoryTypeId: filter.inventoryTypeId ? [filter.inventoryTypeId] : [],
					manufacturerId: filter.manufacturerId ? [filter.manufacturerId] : [],
					modelId: filter.modelId ? [filter.modelId] : [],
					categoryName: filter.categoryName ? [filter.categoryName] : [],
				},
			}),
			filter.inventoryTypeId !== inventoryTypeId ? inventoryTypeFieldsQuery.fetch({ variables: { inventoryTypeId: filter.inventoryTypeId } }) : { data: null },
		])

		// If we change inventoryType, we need to set the category to one with a matching name for the new type as the id will not match
		const matchingCategory = inventoryTypeData?.categories.find(category => category.name?.toLowerCase() === filter.categoryName?.toLowerCase())
		category = matchingCategory ?? null

		// Set the type fields (with no value) and other inventory type fields
		if (filter.inventoryTypeId !== lastInventoryTypeId && typeFieldsData) {
			lastInventoryTypeId = filter.inventoryTypeId
			const existingTypeFields = typeFields.reduce((acc: Record<string, string>, field) => {
				if (field.label && field.value) {
					acc[field.label] = field.value
				}
				return acc
			}, {})

			typeFields = [
				{
					label: typeFieldsData.inventoryType.typeLabel1 ?? '',
					history: typeFieldsData.inventoryType.typeData1History,
					value: typeFieldsData.inventoryType.typeLabel1 ? (existingTypeFields[typeFieldsData.inventoryType.typeLabel1] ?? '') : '',
				},
				{
					label: typeFieldsData.inventoryType.typeLabel2 ?? '',
					history: typeFieldsData.inventoryType.typeData2History,
					value: typeFieldsData.inventoryType.typeLabel2 ? (existingTypeFields[typeFieldsData.inventoryType.typeLabel2] ?? '') : '',
				},
				{
					label: typeFieldsData.inventoryType.typeLabel3 ?? '',
					history: typeFieldsData.inventoryType.typeData3History,
					value: typeFieldsData.inventoryType.typeLabel3 ? (existingTypeFields[typeFieldsData.inventoryType.typeLabel3] ?? '') : '',
				},
				{
					label: typeFieldsData.inventoryType.typeLabel4 ?? '',
					history: typeFieldsData.inventoryType.typeData4History,
					value: typeFieldsData.inventoryType.typeLabel4 ? (existingTypeFields[typeFieldsData.inventoryType.typeLabel4] ?? '') : '',
				},
			].filter(field => field.label)
			typeFieldsChanged = true
		}

		// Mostly here for typeguard purposes, but just in case
		if (!optionsData) {
			inventoryOptionsLoading = false
			if (typeFieldsChanged) {
				dispatch('typeFieldListChange', typeFields)
			}
			return
		}

		const newInventoryOptions: Array<FlatQuestion> = optionsData.inventoryOptions
			.map(option => {
				const { category, manufacturer, model, inventoryType, ...optionRest } = option
				return {
					...optionRest,
					value: null,
					categoryId: category?.id ?? null,
					categoryName: category?.name ?? null,
					manufacturerId: manufacturer?.id ?? null,
					modelId: model?.id ?? null,
					inventoryTypeId: inventoryType?.id ?? null,
				}
			})
			.sort((a, b) => a.rank - b.rank)
		const newInventoryOptionIdsSet = new Set(newInventoryOptions.map(option => option.id))
		const oldOptionIdsToNamesMap = options.reduce((acc: Record<number, string>, option) => {
			acc[option.id] = option.name
			return acc
		}, {})

		type OptionMaps = {
			newOptionNamesToIdsMap: Record<string, number>
			newDefaultOptionValueMap: Record<number, string>
		}

		const { newOptionNamesToIdsMap, newDefaultOptionValueMap } = newInventoryOptions.reduce(
			(acc: OptionMaps, option) => {
				acc.newOptionNamesToIdsMap[option.name] = option.id
				if (option.defaultChoice) {
					acc.newDefaultOptionValueMap[option.id] = option.defaultChoice
				}
				return acc
			},
			{ newOptionNamesToIdsMap: {}, newDefaultOptionValueMap: {} },
		)

		// // Put old empty values in the big ol map so we can revert to them if the user changes their mind
		for (const option of options) {
			for (const serial of serials) {
				if (!optionValueMap.has({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid })) {
					optionValueMap.set({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid }, '')
				}
			}
			if (!optionValueMap.has({ optionId: option.id })) {
				optionValueMap.set({ optionId: option.id }, '')
			}
		}
		// Put new empty values in the big ol map so defaults get computed in the next step
		for (const option of newInventoryOptions) {
			for (const serial of serials) {
				if (!optionValueMap.has({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid })) {
					// Real values won't ever be false. This will let us see this is only there to apply defaults, and is not a real empty value
					optionValueMap.set({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid }, false)
				}
			}
			if (!optionValueMap.has({ optionId: option.id })) {
				optionValueMap.set({ optionId: option.id }, false)
			}
		}

		const ovMapEntries = Array.from(optionValueMap.entries()) as Array<
			[
				{
					optionId: number
					serialId?: number | null | undefined
					serialUuid?: string | null | undefined
					valueWasDefault?: boolean | undefined
				},
				string | boolean | number,
			]
		>

		for (const [{ optionId, serialId, serialUuid, valueWasDefault = false }, value] of ovMapEntries) {
			const newIdWithMatchingName = newOptionNamesToIdsMap[oldOptionIdsToNamesMap[optionId]]
			// Is there a matching option by id in the new list? And do we have an existing value? Or, have we already changed this value and don't need to again?
			if (
				(newInventoryOptionIdsSet.has(optionId) && typeof optionValueMap.get({ optionId: newIdWithMatchingName, serialId, serialUuid }) === 'string') ||
				optionValueMap.get({ optionId, serialId, serialUuid }) !== value
			) {
				// Yes? Carry on.
				continue
			} else if (value && newIdWithMatchingName && !valueWasDefault) {
				// If this has a value, is there a matching option by name? This should not carry forwartd values that were applied as a default so new defaults can be applied
				// If so, move the optionId in the map to the new id
				optionValueMap.set({ optionId: newIdWithMatchingName, serialId, serialUuid }, value)
				// Delete the old one, we can just flip it back if the user changes their mind
				optionValueMap.delete({ optionId, serialId, serialUuid })
			} else if (typeof value !== 'string' && optionValueMap.get({ optionId }) && serialUuid) {
				// Check for existing default value and apply/keep if found
				optionValueMap.set({ optionId, serialId, serialUuid, valueWasDefault: true }, optionValueMap.get({ optionId }) ?? null)
			} else if (typeof optionValueMap.get({ optionId, serialId, serialUuid }) !== 'string' && newDefaultOptionValueMap[optionId]) {
				// Check for new default value and apply if an existing one wasn't found
				optionValueMap.set({ optionId, serialId, serialUuid, valueWasDefault: true }, newDefaultOptionValueMap[optionId])
			} else if (value === false) {
				// This is an empty value that was only there to apply defaults or a default that no longer applies, remove it.
				optionValueMap.delete({ optionId, serialId, serialUuid })
			}
			// if not, then we just don't have a value.
		}

		options = newInventoryOptions
		inventoryOptionsLoading = false
		dispatch('optionListChange', options)
		// dispatch this at the end not in the middle because I've had weird issues with dispatching in the middle of a fn before
		if (typeFieldsChanged) {
			dispatch('typeFieldListChange', typeFields)
		}
	}

	function onQaInputChange(option: Option, value: string | number | boolean | null) {
		option.value = option.dataType === 'NUMBER' || option.dataType === 'CURRENCY' ? Number(value) : value

		if (value) {
			optionValueMap.set(
				{
					optionId: option.id,
					serialId: selectedSerialId,
					serialUuid: selectedSerialUuid,
				},
				value,
			)
		} else {
			optionValueMap.delete({
				optionId: option.id,
				serialId: selectedSerialId,
				serialUuid: selectedSerialUuid,
			})
		}

		dispatch('qaInputChange', option)
	}

	function onTypeFieldChange(event: Event, index: number) {
		dispatch('typeFieldChange', {
			label: typeFields[index].label,
			value: getEventValue(event),
		})
	}

	const inventoryOptionsQuery = graphql(`
		query InventoryOptions(
			$inventoryTypeId: [Int!]
			$manufacturerId: [Int!]
			$modelId: [Int!]
			$categoryName: [String!]
			$suppressFixedChoices: Boolean
			$suppressQueryChoices: Boolean
			$queryOverride: String
		) {
			inventoryOptions(inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId, categoryName: $categoryName) {
				id
				name
				rank
				required
				public
				dataType
				choices(suppressFixedChoices: $suppressFixedChoices, suppressQueryChoices: $suppressQueryChoices, queryOverride: $queryOverride) {
					id
					default
					label
					rank
				}
				manufacturer {
					id
					name
				}
				model {
					id
					name
				}
				category {
					id
					name
				}
				inventoryType {
					id
					name
				}
				defaultChoice
				choiceQuery
			}
		}
	`)
	const inventoryTypeFieldsQuery = graphql(`
		query TypeFields($inventoryTypeId: Int!) {
			inventoryType(id: $inventoryTypeId) {
				id
				setId
				typeData1History
				typeData2History
				typeData3History
				typeData4History
				typeLabel1
				typeLabel2
				typeLabel3
				typeLabel4
			}
		}
	`)
</script>

<div class="form-row">
	{#each typeFields as typeField, index}
		<div class={columnClass}>
			<Input
				selectOnFocus
				label={typeField.label}
				labelClass={typeField.global ? 'text-underline' : ''}
				list="typeData{index + 1}History"
				title="Custom part type field {index + 1}/4"
				disabled={disableGlobalInputs && typeField.global}
				value={typeField.value}
				on:change={event => onTypeFieldChange(event, index)}
			></Input>
			<datalist id="typeData{index + 1}History">
				<option value="N/A"></option>
				{#each typeField.history as entry}
					<option value={entry}> </option>
				{/each}
			</datalist>
		</div>
	{/each}

	{#each options as option}
		<div class={columnClass}>
			<QaInput
				required={option.required}
				defaultChoice={option.choices.find(choice => choice.default)?.label}
				value={option.value}
				choices={option.choices.map(choice => choice.label)}
				disabled={disableGlobalInputs}
				id={option.id}
				dataType={option.dataType}
				name={option.name}
				on:change={event => onQaInputChange(option, event.detail)}
			></QaInput>
		</div>
	{/each}

	{#if !typeFields.length && !options.length}
		<div class="col-12">
			<p class="text-center">No Custom Fields</p>
		</div>
	{/if}
</div>
