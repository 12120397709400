import type { AppContext } from 'types/common'

import { getSession } from 'stores/session'
import component from './MyAccount.svelte'
//import { getPasswordRules } from 'utility/get-password-rules'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration.my-account',
		route: 'my-account',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		template: {
			svelte: true,
			component,
		},
		canLeaveState: domApi => {
			// @ts-expect-error blah
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			return domApi.canLeaveState() as boolean
		},
		resolve(_data, _parameters) {
			const session = getSession()

			return Promise.resolve({
				user: {
					...session,
					name: session.username,
					workEmail: session.email,
					lockNotes: '',
					status: 'ACTIVE',
					currentPassword: '',
					newPassword: '',
					confirmPassword: '',
				},
			})
		},
	})
}
