import { makeDashboardManageComponent } from '@isoftdata/web-dashboard'
import { dashboardReportsForConfiguration, groupsQuery, dashboardRemoveChartFromReportMutation, dashboardUserAccountsQuery, dashboardAddChartToReportMutation, dashboardUpdateChartRankMutation } from 'common/graphql-queries'
import { loadChartDataHelper, loadReportMetadataHelper, loadDashboardChartMembershipHelper, saveDashboardReportHelper } from 'utility/dashboard-helper'
import template from './manage.html'

const defaultData = {
	chartConfigEnabled: true,
	shareConfigEnabled: true,
	dateRangeDefaultOptions: [
		'Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Last 90 Days', 'Last 365 Days',
		'This Week', 'This Month', 'This Quarter', 'This Year',
		'Previous Week', 'Previous Month', 'Previous Quarter', 'Previous Year',
	],
	selectedParameter: 0,
}

export default function({ stateRouter, mediator }) {
	stateRouter.addState({
		name: 'app.configuration.dashboard.manage',
		route: ':dashboardReportId',
		querystringParameters: [ 'dashboardReportId' ],
		template: {
			template,
			components: {
				itDashboardManage: makeDashboardManageComponent({
					loadDashboardChartMembershipForReport: ({ dashboardReportId }) => {
						return loadDashboardChartMembershipHelper(mediator, { dashboardReportId })
					},
					loadDashboardReportAuthor: async({ dashboardReportId }) => {
						const { dashboardReport } = await mediator.publish('graphqlFetch', `query Query($dashboardReportId: Int!) {dashboardReport(id: $dashboardReportId) {ownerId}}`, { dashboardReportId })
						if (dashboardReport.ownerId) {
							const { userAccount } = await mediator.publish('graphqlFetch', `query Query($userAccountId: Int!) {userAccount(id: $userAccountId) {name lastName firstName}}`, { userAccountId: dashboardReport.ownerId })
							return ((userAccount.firstName && userAccount.lastName) ? `${userAccount.firstName} ${userAccount.lastName}` : userAccount.name)
						}
						return 'Default Report'
					},
					loadDashboardReportMetadata: ({ dashboardReportId, chartId }) => {
						return loadReportMetadataHelper(mediator, { dashboardReportId, chartId })
					},
					loadChartDataForReport: ({ chartId, dashboardReportId, parameterSelectionList }) => {
						return loadChartDataHelper(mediator, { chartId, dashboardReportId, parameterSelectionList })
					},
					loadDashboardReportsForConfiguration: async() => {
						const { dashboardReports } = await mediator.publish('graphqlFetch', dashboardReportsForConfiguration, { args: { editableByCurrentUser: true } })
						dashboardReports.map(report => {
							report.shareType = report.shareType.toLowerCase()
							return report
						})
						return dashboardReports
					},
					updateDashboardChartMembershipForReport: async(row, originalRow) => { // New mutation needed
						if (row.inReport && originalRow.rank === null) { // add
							const { addChartToDashboardReport } = await mediator.publish('graphqlFetch', dashboardAddChartToReportMutation, { input: { chartId: row.chartId, reportId: row.dashboardReportId, rank: row.rank } })
							return addChartToDashboardReport.filter(chart => chart.display).map(chart => {
								return {
									...chart,
									dashboardReportId: row.dashboardReportId,
								}
							})
						} else if (row.inReport && originalRow.rank && originalRow.rank !== row.rank) { // modify rank
							const { updateDashboardReport } = await mediator.publish('graphqlFetch', dashboardUpdateChartRankMutation, {
								input: {
									id: row.dashboardReportId,
									charts:
									[
										{
											chartId: row.chartId,
											rank: row.rank,
										},
									],
								},
								args: {
									hidden: false,
								},
							})
							return updateDashboardReport.chartMembership.filter(chart => chart.display).map(chart => {
								const entry = updateDashboardReport.chartEntries.find(chartEntry => {
									return chartEntry?.chart?.id === chart.chartId
								})
								return {
									...chart,
									rank: (entry && entry.rank >= 0) ? entry.rank : null,
									dashboardReportId: row.dashboardReportId,
								}
							})
						} else { // remove
							const { removeChartFromDashboardReport } = await mediator.publish('graphqlFetch', dashboardRemoveChartFromReportMutation, { chartId: row.chartId, reportId: row.dashboardReportId })
							return removeChartFromDashboardReport.filter(chart => chart.display).map(chart => {
								return {
									...chart,
									dashboardReportId: row.dashboardReportId,
								}
							})
						}
					},
					saveDashboardReport: ({ dashboardReport }) => saveDashboardReportHelper(mediator, { dashboardReport }),
					saveDashboardReportChartOptions: async({ dashboardReportChart }) => { // New mutation needed
						const { updateDashboardReport } = await mediator.publish('graphqlFetch', dashboardUpdateChartRankMutation, {
							input: {
								id: dashboardReportChart.dashboardReportId,
								charts: [
									{
										chartId: dashboardReportChart.chartId,
										jsonOverride: dashboardReportChart.jsonOverride,
									},
								],
							},
							args: {
								id: dashboardReportChart.chartId,
							},
						})
						return updateDashboardReport
					},
					loadUsers: async() => {
						let { userAccounts } = await mediator.publish('graphqlFetch', dashboardUserAccountsQuery, { status: "ACTIVE" })
						return userAccounts
					},
					loadStores: async() => {
						const { stores } = await mediator.publish('graphqlFetch', `
						query storeQuery {
							stores {
							  id
							  name
							  code
							}
						  }`, {})
						return stores.map(store => {
							return {
								storeId: store.id,
								name: `${store.code} - ${store.name}`,
							}
						})
					},
					loadGroups: async() => {
						const { groups } = await mediator.publish('graphqlFetch', groupsQuery, {})
						return groups
					},
				}),
			},
		},
		resolve(data, { dashboardReportId }) {
			return Promise.resolve({ dashboardReportId: Number(dashboardReportId) })
		},
	})
}
