/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import svelteComponent from './Settings.svelte' // Svelte component
import pProps from 'p-props' // Bulk promise
import { UserSettingsSessionReturnType, UserSetting, ConfigSettingValuesReturnType } from 'types/settings' // Query return types

import { configurationSettingsQuery } from 'common/graphql-queries'
import type { AppContext } from 'types/common'

export default function ({ stateRouter, mediator }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration.settings',
		route: 'settings',
		querystringParameters: ['lastResetTime', 'lastSavedTime', 'selectedCategory'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
			selectedCategory: 'All',
		},
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: svelteComponent, // this is the svelte component
			options: {},
		},
		canLeaveState(domApi) {
			// @ts-expect-error todo fix asr types like presage
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return domApi.canLeaveState()
		},
		//@ts-expect-error todo fix asr types like presage
		async resolve(_data, parameters: { lastResetTime: string; lastSavedTime: string; selectedCategory: string }) {
			const { selectedCategory } = parameters // These are the query parameters

			const { session, settings, configUISettings } = (await pProps({
				// This is the bulk promise to get all the data needed for the page
				session: mediator.publish('graphqlFetch', queries.session),
				settings: mediator.publish('graphqlFetch', configurationSettingsQuery),
				configUISettings: mediator.publish('graphqlFetch', queries.configUISettings),
			})) as { session: UserSettingsSessionReturnType; settings: { settingValuesForConfig: UserSetting[] }; configUISettings: ConfigSettingValuesReturnType }

			const mappedAndFilteredSettings = settings.settingValuesForConfig.reduce((acc, setting) => {
				switch (setting.scope) {
					case 'GLOBAL':
						if (session.session.permissions.SETTINGS_GLOBAL_ADMINISTRATOR) {
							acc.push({
								...setting,
								dataType: checkSettingValue(setting.value),
								dirty: false,
								defaultDirty: false,
								type: transformTypeEnum(setting.type),
							})
						}
						break
					case 'STORE':
						if (session.session.permissions.SETTINGS_STORE_ADMINISTRATOR) {
							acc.push({
								...setting,
								dataType: checkSettingValue(setting.value),
								dirty: false,
								defaultDirty: false,
								type: transformTypeEnum(setting.type),
							})
						}
						break
					case 'USER':
						acc.push({
							...setting,
							dataType: checkSettingValue(setting.value),
							dirty: false,
							defaultDirty: false,
							type: transformTypeEnum(setting.type),
						})
						break
					default:
						break
				}
				return acc
			}, [] as UserSetting[])

			const mappedConfigurationSettings = {
				// This sticks the query response into an object that the svelte component expects to render the correct settings
				importantConfiguration: configUISettings.settingValues.settingsConfig.importantConfigurationShown,
				interfaceHistory: configUISettings.settingValues.settingsConfig.interfaceHistoryConfigurationShown,
				interfacePreference: configUISettings.settingValues.settingsConfig.interfacePreferenceConfigurationShown,
				optionalConfiguration: configUISettings.settingValues.settingsConfig.optionalConfigurationShown,
				preference: configUISettings.settingValues.settingsConfig.preferenceConfigurationShown,
			}

			return {
				selectedCategory,
				settings: mappedAndFilteredSettings, // This passes the settings to the svelte component
				configurationSettings: mappedConfigurationSettings, // This passes the configuration settings to the svelte component
			}
		},
	})
}

function transformTypeEnum(settingType: string) {
	// This is to convert in and out of the API to what the UI expects
	switch (settingType) {
		case 'IMPORTANT_CONFIGURATION':
			return 'Important Configuration'
		case 'INTERFACE_HISTORY':
			return 'Interface History'
		case 'INTERFACE_PREFERENCE':
			return 'Interface Preference'
		case 'OPTIONAL_CONFIGURATION':
			return 'Optional Configuration'
		case 'PREFERENCE':
			return 'Preference'
		case 'ACCESSIBILITY':
			return 'Accessibility'
		default:
			return 'Unknown'
	}
}

function checkSettingValue(settingValue: string) {
	// This is an abstraction to check the type of the setting value for display
	const numberRegex = /^\d+$/
	const booleanRegex = /^(True|False)$/
	if (numberRegex.test(settingValue)) {
		return 'number'
	} else if (booleanRegex.test(settingValue)) {
		return 'boolean'
	}
	return 'string'
}

const queries = {
	// These are local queries, not to be confused with the queries in common/graphql-queries which are shared
	session: `#graphql
		query permissionQuery {
			session {
				permissions {
					SETTINGS_GLOBAL_ADMINISTRATOR
					SETTINGS_STORE_ADMINISTRATOR
					SETTINGS_GROUP_ADMINISTRATOR
				}
			}
		}
	`,
	configUISettings: `#graphql
		query SettingValues {
			settingValues {
				settingsConfig {
					importantConfigurationShown
					interfaceHistoryConfigurationShown
					interfacePreferenceConfigurationShown
					optionalConfigurationShown
					preferenceConfigurationShown
				}
			}
		}
	`,
}
