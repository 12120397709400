import sessionStore, { getSession, type ClientSession } from 'stores/session'

let session = getSession()
sessionStore.subscribe(value => {
	session = value
})

export type PermissionCode = keyof Exclude<ClientSession['sessionPermissions'], null>

/** Whether the user has the given permission at the currently logged-in store */
export function checkSessionPermission(permissionCode: PermissionCode): boolean {
	if (session.sessionPermissions && !(permissionCode in session.sessionPermissions)) {
		console.warn(`Permission code "${permissionCode}" is not a valid permission code`)
	}

	return session.sessionPermissions?.[permissionCode] ?? false
}

/** Whether the user has the given permission at the specified store (or the currently logged-in store, if not specified) */
export function hasPermission(permissionCode: PermissionCode, storeId?: number): boolean {
	storeId ??= session.currentStore

	if (storeId === session.currentStore) {
		return checkSessionPermission(permissionCode)
	}

	const level = session.userPermissions[permissionCode]

	if (!level) {
		console.warn(`Permission code "${permissionCode}" is not a valid permission code`)
		return false
	}

	return level === 'Global' || (level === 'Store' && !!session.authorizedStores.find(store => store.id === storeId))
}

type GenericPermissionValue = 'SITE' | 'GLOBAL' | 'NONE'
type EnterprisePermissionValue = 'Store' | 'Global' | 'None'

/** Map to go from the generic permission value constant to the Enterprise permission value */
export const genericPermissionValueMap: Record<GenericPermissionValue, EnterprisePermissionValue> = {
	SITE: 'Store',
	GLOBAL: 'Global',
	NONE: 'None',
}

/** Map to go from the Enterprise permission value to the generic permission value constant */
export const genericPermissionValueMapReversed: Record<EnterprisePermissionValue, GenericPermissionValue> = {
	Store: 'SITE',
	Global: 'GLOBAL',
	None: 'NONE',
}
