<script lang="ts">
	import type { Merge } from 'type-fest'
	import type { SvelteAsr, Mediator } from 'types/common'
	import type { ClientSession } from 'stores/session'
	//import type { SaveResetProps } from '../configuration'
	//import type { PasswordValidationRules } from 'utility/get-password-rules'

	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { UserAccountInfo } from '@isoftdata/svelte-user-configuration'
	import { getContext } from 'svelte'
	import { graphql } from '$houdini'
	import session, { isClientSession } from 'stores/session'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'

	const updateSessionUser = graphql(`
		mutation MyAccountUpdateSessionUser($input: SessionUserUpdate!) {
			updateSessionUser(input: $input) {
				id
				name
				firstName
				lastName
				recoveryEmail
				workEmail
			}
		}
	`)

	const mediator = getContext<Mediator>('mediator')

	type User = Merge<
		{
			id: number
			name: string
			workEmail: ClientSession['email']
			lockNotes: string
			status: 'ACTIVE' //They made it here, they are active
			currentPassword: string
			newPassword: string
			confirmPassword: string
			lastPasswordResetDate: Date | undefined
		},
		ClientSession
	>

	export let user: User
	export let asr: SvelteAsr

	//Make a frozen deep copy of the original data so we can compare it later to see if there are any changes
	let originalData = Object.freeze(klona(user))
	let workEmailIsValid: boolean = true
	let recoveryEmailIsValid: boolean = true

	export function canLeaveState() {
		if (hasUnsavedChanges) {
			return confirm('You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.')
		}
		return true
	}

	async function saveChanges() {
		if (hasUnsavedChanges) {
			try {
				const { data } = await updateSessionUser.mutate({
					input: {
						firstName: user.firstName,
						lastName: user.lastName,
						recoveryEmail: user.recoveryEmail,
					},
				})

				if (data?.updateSessionUser) {
					const changedFields: Partial<ClientSession> = {
						firstName: data.updateSessionUser.firstName,
						lastName: data.updateSessionUser.lastName,
						recoveryEmail: data.updateSessionUser.recoveryEmail,
					}

					//Update the session so their name and other info is displayed correctly throughout the app
					session.update(session => {
						if (isClientSession(session)) {
							return {
								...session,
								...changedFields,
							}
						}
						return session
					})
					user = {
						...user,
						...changedFields,
					}
					originalData = Object.freeze(klona(user))

					asr.go(null, { lastSavedTime: Date.now(), lastResetTime: null }, { inherit: true }) //Reload the page after saving
				}
			} catch (err) {
				console.error(err)
				mediator.publish('showMessage', {
					heading: 'Error',
					message: err instanceof Error ? err.message : '',
					type: 'danger',
					time: false,
				})
			}
		}
	}

	$: hasUnsavedChanges = !dequal(originalData, user)
</script>

<SaveResetButton
	disabled={!hasUnsavedChanges}
	resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSaveTime: null }, { inherit: true })}
	on:saveClick={saveChanges}
/>

<div class="row mt-2">
	<div class="col-12 col-md-8 col-lg-6 col-xl-5 order-1 order-sm-0">
		<UserAccountInfo
			bind:userAccount={user}
			icon="address-card"
			myAccountMode
			cardTitle={'My Account'}
			confirmPasswordSet={async ({ currentPassword, newPassword }) => {
				await updateSessionUser.mutate({
					input: {
						currentPassword,
						newPassword,
					},
				})
			}}
			success={({ heading, message }) => {
				mediator.publish('showMessage', { heading, message, type: 'success', time: 10 })
			}}
			error={({ heading, message }) => {
				mediator.publish('showMessage', { heading, message, type: 'danger', time: false })
			}}
			bind:workEmailIsValid
			bind:recoveryEmailIsValid
		/>
	</div>
</div>
