<script lang="ts">
	import type { Template, DomApi } from 'types/common'
	import type { Column } from '@isoftdata/svelte-table'
	import type { AdditionalInfoType } from './ItemDetail.svelte'
	type Inventories = PartSearchResultsData$result['inventories']
	import type { InventoryItem } from './results.ts'
	import type { Breakpoints } from '@isoftdata/utility-bootstrap'
	import type { AbstractStateRouter } from 'abstract-state-router'
	import type { PartSearchResultsData$result } from '$houdini'
	import type { PerPageCount } from 'utility/search-fields'

	import { localWritable } from '@macfja/svelte-persistent-store'
	import { graphql } from '$houdini'
	import { tick, onMount } from 'svelte'
	import { slide } from 'svelte/transition'
	import Modal from '@isoftdata/svelte-modal'
	import ItemDetail from './ItemDetail.svelte'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { Table, Td } from '@isoftdata/svelte-table'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import { perPageCounts, computeTypeLabelColumns } from 'utility/search-fields'

	export let sortColumnProp: string
	export let pageNumber: number // Required
	export let inventories: Inventories // Required
	export let sortDirection: 'ASC' | 'DESC' = 'ASC'
	export let perPageCount: PerPageCount // Required
	export let asr: AbstractStateRouter<Template, DomApi>
	export let selectedInventoryId: number | null // Required
	export let previousSortDirection: 'ASC' | 'DESC' = 'DESC'
	export let inventoryTypeId: number | undefined

	const inventoryAllocationsQuery = graphql(`
		query PartSearchResultsAllocations($filter: InventoryFilter) {
			inventories(filter: $filter) {
				items {
					id
					allocations {
						salesOrderLines {
							items {
								id
							}
						}
						jobParts {
							id
						}
						transferOrderLines {
							id
						}
					}
				}
			}
		}
	`)

	const showThumbnails = localWritable('showThumbs', true)
	let resultsColumns: Column[] = computeTableColumns($showThumbnails, [])
	let sortColumn: Column | undefined = sortColumnProp ? resultsColumns.find(column => column.property === sortColumnProp) : undefined
	let itemDetailInventoryItem: InventoryItem | null = null
	let itemDetailManModSubtitle: string = ''
	let bootstrapBreakpoint: Breakpoints | 'xxl' = getBootstrapBreakpoint() //it can't actually be xxl class, but because of the typing of the getBootstrapBreakpoint fn, we need to allow xxl
	let isPhoneSize: boolean = false
	let showItemDetailModal: boolean = false
	let itemDetailAdditionalInfoType: AdditionalInfoType = 'Basic Info'
	let inventoryTotalAllocationsMap: Map<number, number> = new Map()

	function computeItemDetailManModSubtitle(item: InventoryItem | null): string {
		if (item) {
			const { manufacturer, model, vehicleMake, vehicleModel, vehicleYear } = item
			if (manufacturer && model) {
				return [manufacturer.name, model.name].filter(v => v).join(' > ')
			} else if (vehicleMake && vehicleModel) {
				return [vehicleYear, vehicleMake, vehicleModel].filter(v => v).join(' > ')
			}
		}

		return ''
	}

	async function showDetailPane(item: InventoryItem, additionalInfoType: AdditionalInfoType) {
		if (item.id === itemDetailInventoryItem?.id && additionalInfoType === itemDetailAdditionalInfoType) {
			//They clicked the same button, so just close the sidebar
			itemDetailInventoryItem = null
			additionalInfoType = 'Basic Info'
			return
		}

		itemDetailInventoryItem = item
		if (isPhoneSize) {
			showItemDetailModal = true
		}
		await tick()
		if (additionalInfoType) {
			itemDetailAdditionalInfoType = additionalInfoType
		}
	}

	function computeTableColumns(showThumbnails: boolean, typeLabelColumns: Column[] = []) {
		let columns: Column[] = [
			{ property: 'attentionIcons', name: '', icon: 'circle-info', sortType: false, minWidth: '150px' },
			{ property: 'storeId', name: 'Store' },
			{ property: 'tagNumber', name: 'Tag #' },
			{ property: 'inventoryTypeName', name: 'Part Type' },
			{ property: 'manufacturerName', name: 'Part Manufacturer' },
			{ property: 'modelName', name: 'Part Model' },
			{ property: 'category', name: 'Category' },
			{ property: 'vehicleYear', name: 'Year' },
			{ property: 'vehicleMake', name: 'Make' },
			{ property: 'vehicleModel', name: 'Model' },
			{ property: 'bodyStyle', name: 'Body Style' },
			{ property: 'side', name: 'Side' },
			{ property: 'oemNumber', name: 'OEM #' },
			{ property: 'dateEntered', name: 'Date Entered' },
			{ property: 'status', name: 'Status' },
			{ property: 'userStatus', name: 'User Status' },
			{ property: 'quantity', name: 'Qty' },
			{ property: 'wholesalePrice', name: 'Wholesale' },
			{ property: 'retailCorePrice', name: 'Core' },
			{ property: 'retailPrice', name: 'Retail' },
		]

		columns = [...columns, ...typeLabelColumns]

		if (showThumbnails) {
			const attentionColumnIndex = columns.findIndex(column => (column.property = 'attentionIcons'))
			if (attentionColumnIndex > -1) {
				columns.splice(attentionColumnIndex + 1, 0, { property: 'imageThumbnail', icon: 'photo-video', name: '' })
			}
		}

		return columns
	}

	$: itemDetailManModSubtitle = computeItemDetailManModSubtitle(itemDetailInventoryItem)
	$: isPhoneSize = bootstrapBreakpoint === 'xs'
	$: typeLabelColumns = computeTypeLabelColumns(
		inventoryTypeId,
		inventories.items.map(item => item.inventoryType),
	)
	$: resultsColumns = computeTableColumns($showThumbnails, typeLabelColumns)

	onMount(async () => {
		if (inventories.items.length) {
			const allocationsResponse = await inventoryAllocationsQuery.fetch({ variables: { filter: { ids: inventories.items.map(item => item.id) } } })
			if (allocationsResponse.data?.inventories.items) {
				for (const item of allocationsResponse.data?.inventories.items) {
					inventoryTotalAllocationsMap.set(item.id, item.allocations.jobParts.length + item.allocations.salesOrderLines.items.length + item.allocations.transferOrderLines.length)
				}
				inventoryTotalAllocationsMap = inventoryTotalAllocationsMap
			} else {
				inventoryTotalAllocationsMap = new Map()
			}
		}
	})
</script>

<svelte:window
	on:resize={() => {
		bootstrapBreakpoint = getBootstrapBreakpoint()
		if (!isPhoneSize && showItemDetailModal) {
			showItemDetailModal = false
		} else if (isPhoneSize && itemDetailInventoryItem) {
			showItemDetailModal = true
		}
	}}
/>

<div class="card-body">
	<div class="form-row justify-content-between align-items-end">
		<div class="col-auto">
			<Checkbox
				label="Show Image Thumbnails"
				bind:checked={$showThumbnails}
				inline
			/>
		</div>
		<div class="col-auto">
			{inventories.totalItems} Inventory Items Found
		</div>
		<div class="col-auto">
			<Select
				label="Results Per Page"
				showEmptyOption={false}
				bind:value={perPageCount}
				on:change={() => {
					localStorage.setItem('numPartRows', perPageCount)
					asr.go(null, { perPageCount, pageNumber: 1 }, { inherit: true })
				}}
			>
				{#each perPageCounts as perPageCount}
					<option value={perPageCount}>{perPageCount}</option>
				{/each}
			</Select>
		</div>
		{#if itemDetailInventoryItem && !isPhoneSize}
			<div class="col-md-6 col-lg-4 col-xl-3"></div>
		{/if}
	</div>

	<div class="form-row flex-nowrap">
		<div class={itemDetailInventoryItem && !isPhoneSize ? 'col-md-6 col-lg-8' : 'col-auto'}>
			<Table
				parentStyle="overflow-y: auto; max-height: 85vh;"
				class="table-sm border-0"
				responsive
				columns={resultsColumns}
				rows={inventories.items}
				currentPageNumber={pageNumber}
				totalItemsCount={inventories.totalItems}
				selectedRowIds={[selectedInventoryId]}
				idProp="id"
				perPageCount={parseInt(perPageCount, 10)}
				stickyHeader
				columnHidingEnabled
				{previousSortDirection}
				{sortDirection}
				{sortColumn}
				pageChange={({ pageNumber }) => asr.go(null, { pageNumber: pageNumber }, { inherit: true })}
				columnClickedMethod={async (column, direction) => {
					previousSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC'
					asr.go(null, { sortColumn: column.property, sortDirection: direction, pageNumber: 1 }, { inherit: true })
				}}
			>
				{#snippet children({ row: inventoryItem })}
					{@const hasPartManModel = inventoryItem.manufacturer?.name && inventoryItem.model?.name}
					<tr
						class="cursor-pointer"
						class:table-primary={selectedInventoryId === inventoryItem.id}
						on:click={() => asr.go('app.part', { inventoryId: inventoryItem.id })}
					>
						<Td
							property="attentionIcons"
							stopPropagation
						>
							<Button
								size="xs"
								color="dark"
								outline={!(itemDetailInventoryItem?.id === inventoryItem.id && itemDetailAdditionalInfoType === 'Basic Info')}
								iconClass="circle-info"
								title="View Basic Item Details"
								on:click={() => {
									showDetailPane(inventoryItem, 'Basic Info')
								}}
							/>
							{#if inventoryItem.messageCount}
								<Button
									size="xs"
									color="dark"
									outline={!(itemDetailInventoryItem?.id === inventoryItem.id && itemDetailAdditionalInfoType === 'Messages')}
									iconClass="message-lines"
									title="View Part Messages"
									on:click={() => {
										showDetailPane(inventoryItem, 'Messages')
									}}>{inventoryItem.messageCount}</Button
								>
							{/if}
							<Button
								size="xs"
								color="dark"
								style="width: 46px;"
								outline={!(itemDetailInventoryItem?.id === inventoryItem.id && itemDetailAdditionalInfoType === 'Allocations')}
								iconClass="files"
								title={inventoryTotalAllocationsMap.get(inventoryItem.id) ? 'View Part Allocations' : 'No Allocations for This Part'}
								colorGreyDisabled={false}
								disabled={$inventoryAllocationsQuery.fetching || !inventoryTotalAllocationsMap.has(inventoryItem.id) || !inventoryTotalAllocationsMap.get(inventoryItem.id)}
								on:click={() => {
									showDetailPane(inventoryItem, 'Allocations')
								}}>{$inventoryAllocationsQuery.fetching ? '' : (inventoryTotalAllocationsMap.get(inventoryItem.id) ?? '0')}</Button
							>
							{#if inventoryItem.attachments.length}
								<Button
									size="xs"
									color="dark"
									outline={!(itemDetailInventoryItem?.id === inventoryItem.id && itemDetailAdditionalInfoType === 'Attachments')}
									iconClass="paperclip"
									title="View Part Attachments"
									on:click={() => {
										showDetailPane(inventoryItem, 'Attachments')
									}}>{inventoryItem.attachments.length}</Button
								>
							{/if}
							{#if inventoryItem.vehicle?.id}
								<Button
									size="xs"
									color="dark"
									outline={!(itemDetailInventoryItem?.id === inventoryItem.id && itemDetailAdditionalInfoType === 'Vehicle Info')}
									iconClass="truck"
									title="View Vehicle Info"
									on:click={() => {
										showDetailPane(inventoryItem, 'Vehicle Info')
									}}
								/>
							{/if}
						</Td>
						{#if $showThumbnails}
							<Td
								property="imageThumbnail"
								stopPropagation
							>
								<ImageThumbnail
									thumbnailFile={{ path: inventoryItem?.attachments?.[0]?.file?.path || '' }}
									showThumbnail
									showImageCount
									noImagePath="images/noimage.jpg"
									context={inventoryItem.attachments}
									fileCount={inventoryItem.attachments.filter(attachment => attachment.file.mimetype.startsWith('image/')).length}
									on:click={() => showDetailPane(inventoryItem, 'Attachments')}
								/>
							</Td>
						{/if}
						<Td property="storeId">{inventoryItem.storeId}</Td>
						<Td property="tagNumber">{inventoryItem.tagNumber}</Td>
						<Td property="inventoryTypeName">{inventoryItem.inventoryType?.name ?? ''}</Td>
						<Td
							property="manufacturerName"
							title={hasPartManModel ? 'Part Manufacturer' : inventoryItem.parentManufacturer ? 'Assembly Manufacturer' : ''}
							>{hasPartManModel ? inventoryItem.manufacturer?.name : (inventoryItem.parentManufacturer?.name ?? '')}</Td
						>
						<Td
							property="modelName"
							title={hasPartManModel ? 'Part Model' : inventoryItem.parentModel ? 'Assembly Model' : ''}>{hasPartManModel ? inventoryItem.model?.name : (inventoryItem.parentModel?.name ?? '')}</Td
						>
						<Td property="category">{inventoryItem.category?.name ?? ''}</Td>
						<Td property="vehicleYear">{inventoryItem.vehicleYear}</Td>
						<Td property="vehicleMake">{inventoryItem.vehicleMake}</Td>
						<Td property="vehicleModel">{inventoryItem.vehicleModel}</Td>
						<Td property="bodyStyle">{inventoryItem.bodyStyle}</Td>
						<Td property="side">{inventoryItem.side}</Td>
						<Td property="oemNumber">{inventoryItem.oemNumber}</Td>
						<Td property="dateEntered">{inventoryItem.dateEntered ? inventoryItem.dateEntered.toLocaleDateString() : ''}</Td>
						<Td property="status">{inventoryItem.status}</Td>
						<Td property="userStatus">{inventoryItem.userStatus}</Td>
						<Td property="quantity">{parseFloat(inventoryItem.quantity).toString()}</Td>
						<Td property="wholesalePrice">{formatCurrency(inventoryItem.wholesalePrice)}</Td>
						<Td property="retailCorePrice">{formatCurrency(inventoryItem.retailCorePrice)}</Td>
						<Td property="retailPrice">{formatCurrency(inventoryItem.retailPrice)}</Td>
						{#each typeLabelColumns as column}
							<Td property={column.property}>
								{#if !inventoryTypeId && inventoryItem[column.property]?.data}
									<span
										class="font-weight-bold"
										style="white-space: nowrap;">{inventoryItem[column.property.replace('Label', 'Field')]?.label ?? ''}:</span
									>
								{/if}
								{inventoryItem[column.property]?.data ?? ''}
							</Td>
						{/each}
					</tr>
				{/snippet}
				{#snippet noRows({ visibleColumnsCount })}
					<tr>
						<td colspan={visibleColumnsCount}> No matching parts found.</td>
					</tr>
				{/snippet}
			</Table>
		</div>
		{#if itemDetailInventoryItem && !isPhoneSize}
			<div class="col-md-6 col-lg-4">
				<div
					transition:slide={{ axis: 'x', duration: 200 }}
					class="card"
				>
					<div class="card-header">
						<div class="d-flex justify-content-between align-items-center flex-nowrap">
							<h5 class="w-100 text-truncate">
								{#if itemDetailInventoryItem}
									Tag #: {itemDetailInventoryItem?.tagNumber ?? ''}
									<br />
									<small>
										<span class="font-weight-bold">{itemDetailInventoryItem?.inventoryType?.name}</span>
										{itemDetailManModSubtitle}
									</small>
								{/if}
							</h5>
							<Button
								size="sm"
								iconClass="caret-right"
								outline
								title="Collapse Item Details Pane"
								on:click={() => (itemDetailInventoryItem = null)}
							/>
						</div>
					</div>
					<div
						class="card-body"
						style="overflow-y: auto; max-height: 80vh;"
					>
						<ItemDetail
							item={itemDetailInventoryItem}
							bind:additionalInfoType={itemDetailAdditionalInfoType}
							{asr}
							on:imageThumbnailClick={e => {
								const inventoryItem = inventories.items.find(item => item.id === e.detail)
								if (inventoryItem) {
									showDetailPane(inventoryItem, 'Attachments')
								}
							}}
						/>
					</div>
				</div>
			</div>
		{/if}
	</div>
</div>

<Modal
	title={itemDetailInventoryItem?.inventoryType?.name ?? 'Item Details'}
	subtitle={itemDetailManModSubtitle}
	confirmShown={false}
	show={!!(itemDetailInventoryItem && showItemDetailModal)}
	on:close={() => {
		showItemDetailModal = false
		itemDetailInventoryItem = null
	}}
	>{#if itemDetailInventoryItem}
		<ItemDetail
			item={itemDetailInventoryItem}
			bind:additionalInfoType={itemDetailAdditionalInfoType}
			{asr}
		/>
	{/if}</Modal
>
