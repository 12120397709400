<script lang="ts">
	import type { AbstractStateRouter } from 'abstract-state-router'
	import type { Mediator, Template, DomApi, SvelteAsr } from 'types/common'

	import Sidebar, { Activity, type ActivityInput } from '@isoftdata/svelte-sidebar'
	import Alert from '@isoftdata/svelte-alert'
	import { getContext, onDestroy, type ComponentProps } from 'svelte'
	import { getObject } from '@isoftdata/utility-storage'
	import makeOnBarcodeScan from '@isoftdata/scanner-support'
	import { getBarcodeType } from 'utility/barcode-helper'
	import session from 'stores/session'
	import { checkSessionPermission } from 'utility/permission'
	import type { Colors } from '@isoftdata/utility-bootstrap'
	import toTitleCase from 'to-title-case'
	import userLocalWritable from 'stores/user-local-writable'
	import { graphql, type InventoryBySkuAndStore$result } from '$houdini'
	import { type ScannerOptions, standardProfile } from '@isoftdata/svelte-scanner-configuration'

	export let asr: SvelteAsr
	const sidebarExpanded = userLocalWritable($session.id, 'sidebarExpanded', true)
	const recentActivity = userLocalWritable<Array<Activity>>($session.id, 'recentActivity', [])

	// Since we just switched from Ractive -> Svelte sidebar and some prop names changed, delete bad recent activity if it exists
	if ($recentActivity.length && !$recentActivity[0].route) {
		recentActivity.delete()
	}

	const stateRouter = getContext<AbstractStateRouter<Template, DomApi>>('stateRouter')
	const mediator = getContext<Mediator>('mediator')

	let alertIsShown = false
	let stateIsChanging = false
	let destinationStateName = ''
	let alertComponent: Alert
	let sidebar: Sidebar

	$: activeState = getActiveState(asr.getActiveState())
	function getActiveState(state: ReturnType<typeof asr.getActiveState>) {
		return {
			name: state.name,
			route: state.name,
			parameters: state.parameters as Record<string, string | undefined>,
		}
	}

	const states: ComponentProps<Sidebar>['items'] = [
		{
			type: 'CUSTOMER',
			name: 'Customer',
			route: 'app.customer-search',
			icon: 'user',
			additionalActiveRoutes: [{ route: 'app.customer' }],
			quickAction: { icon: 'circle-plus', route: 'app.customer' },
		},
		{
			type: 'DASHBOARD',
			name: 'Dashboard',
			route: 'app.dashboard',
			icon: 'chart-line',
			hidden: !$session.sessionPermissions?.DASHBOARD_REPORT_VIEW, // TODO: Permission, hide in 'teardown mode'?
		},
		{
			type: 'PART',
			name: 'Part',
			route: 'app.part-search',
			icon: 'engine',
			hidden: !checkSessionPermission('PARTS_CAN_VIEW_PARTS'),
			additionalActiveRoutes: [{ route: 'app.part' }],
			quickAction: { icon: 'circle-plus', route: 'app.part' },
		},
		{
			type: 'REPORT_VIEWER',
			name: 'Report Viewer',
			route: 'app.report-viewer',
			icon: 'file-chart-column',
		},
		{
			type: 'VEHICLE',
			name: 'Vehicle',
			route: checkSessionPermission('SEARCH_CAN_SEARCH_VEHICLES') ? 'app.vehicle-search' : 'app.vehicle',
			icon: 'truck',
			additionalActiveRoutes: [{ route: 'app.vehicle' }],
			quickAction: { icon: 'circle-plus', route: 'app.vehicle' },
		},
		{
			type: 'VENDOR',
			name: 'Vendor',
			route: 'app.vendor-search', //checkSessionPermission('SEARCH_CAN_SEARCH_VEHICLES') ? 'app.vehicle-search' : 'app.vehicle',
			icon: 'user',
			additionalActiveRoutes: [{ route: 'app.vendor' }],
			quickAction: { icon: 'circle-plus', route: 'app.vendor' },
		},
	]

	const onBarcodeScan = makeOnBarcodeScan()

	//#region StateRouter events
	stateRouter.on('routeNotFound', function (route, parameters) {
		stateRouter.go('not-found', {
			route,
			parameters,
		})
	})

	stateRouter.on('stateChangeAttempt', () => {
		if (!$session) {
			return stateRouter.go('login')
		}
	})

	stateRouter.on('stateChangeStart', (state, parameters) => {
		destinationStateName = state.name
		stateIsChanging = true
	})

	stateRouter.on('stateChangeError', err => {
		const stateName = destinationStateName
		stateIsChanging = false
		destinationStateName = ''
		mediator.publish('showMessage', {
			type: 'danger',
			time: false,
			heading: `Error: State change to ${stateName} failed.`,
			message: err.message,
		})
	})

	stateRouter.on('stateChangeEnd', (state, _parameters, _states) => {
		let routeName = state.name

		if (routeName.startsWith('app.')) {
			routeName = routeName.substring(4)
		}

		const stateNameArray = routeName.split('.')
		let stateName = ''
		stateNameArray.forEach(state => {
			stateName += ` > ${toTitleCase(state)}`
		})

		document.title = `ITrack Enterprise ${stateName}`

		destinationStateName = ''
		stateIsChanging = false

		async function processBarcode(barcodeInput: any) {
			const barcode = getBarcodeType(barcodeInput)
			const storeId = $session.currentStore

			if (barcode.type === 'VIN') {
				const vin = barcode.value
				const { data } = await vehicleLookup.fetch({
					variables: {
						filter: {
							vin,
						},
					},
				})

				const vehicles = data?.vehicles.items ?? []

				if (vehicles.length > 0) {
					if (vehicles.length == 1) {
						stateRouter.go('app.vehicle', { vehicleId: vehicles[0].vehicleId })
					} else {
						stateRouter.go('app.vehicle-search', { searchQuery: vin })
					}
				} else {
					mediator.publish('showMessage', {
						type: 'danger',
						time: 10,
						heading: `Scan Error: VIN Not Found`,
						message: `No vecicle found with VIN: ${vin}`,
					})
				}
			} else if (barcode.type === 'SERIAL_ID') {
				const inventorySerialId = parseInt(barcode.value, 10)
				// const results = await mediator.publish('graphqlFetch', lookupBySerialQuery, { inventorySerialId })
				const { data: results } = await inventorySerialLookup.fetch({ variables: { inventorySerialId } })

				if (results?.inventorySerial) {
					if (results.inventorySerial.inventory.storeId === storeId) {
						stateRouter.go('app.part', { inventoryId: results.inventorySerial.inventory.id })
					} else {
						mediator.publish('showMessage', {
							type: 'danger',
							time: 10,
							heading: 'Scan Error',
							message: `Serial Number "${results.inventorySerial.number}" not found at store: ${storeId}`,
						})
					}
				} else {
					mediator.publish('showMessage', {
						type: 'danger',
						time: 10,
						heading: `Scan Error: Not Found`,
						message: `Scanned Value: ${inventorySerialId}  Guessed Type: ${barcode.type}`,
					})
				}
			} else if (barcode.type === 'INVENTORY_ID' || barcode.type === 'UPC' || barcode.type === 'OTHER') {
				let skuResults: InventoryBySkuAndStore$result['inventoryBySkuAndStore'] | undefined = undefined
				try {
					if (barcode.type === 'INVENTORY_ID') {
						const { data } = await inventorySkuStoreLookup.fetch({
							variables: {
								sku: parseInt(barcode.value, 10),
								storeId,
							},
						})

						skuResults = data?.inventoryBySkuAndStore
					}
				} catch (e) {
					console.error(e)
					mediator.publish('showMessage', {
						type: 'danger',
						time: 10,
						heading: `Scan Error: Not Found`,
						message: `Scanned Value: ${barcode.value}  Guessed Type: ${barcode.type}`,
					})
				}
				if (skuResults) {
					if (skuResults.inventoryType.vehicle) {
						stateRouter.go('app.vehicle', { vehicleId: skuResults.vehicleId })
					} else {
						stateRouter.go('app.part', { inventoryId: skuResults.id })
					}
				} else {
					const lookup = barcode.value

					const { data } = await inventorySkuLookup.fetch({
						variables: {
							input: {
								lookup,
							},
						},
					})

					const results = data?.skuLookup ?? []

					if (results.length > 1) {
						mediator.publish('showMessage', {
							type: 'danger',
							time: 10,
							heading: `Scan Error: Multiple Found`,
							message: `Scanned Value: ${lookup}  Guessed Type: ${barcode.type}`,
						})
					} else if (results.length == 1) {
						const inventoryItem = results[0].inventories.find(inventoryItem => inventoryItem?.storeId === storeId)

						if (inventoryItem) {
							if (inventoryItem.inventoryType.vehicle) {
								stateRouter.go('app.vehicle', { vehicleId: inventoryItem.vehicleId })
							} else {
								stateRouter.go('app.part', { inventoryId: inventoryItem.id })
							}
						} else {
							mediator.publish('showMessage', {
								type: 'danger',
								time: 10,
								heading: `Scan Error: Not at Store: ${storeId}`,
								message: `Scanned Value: ${lookup}  Guessed Type: ${barcode.type}`,
							})
						}
					} else {
						mediator.publish('showMessage', {
							type: 'danger',
							time: 10,
							heading: `Scan Error: Not Found`,
							message: `Scanned Value: ${lookup}  Guessed Type: ${barcode.type}`,
						})
					}
				}
			} else {
				mediator.publish('showMessage', {
					type: 'danger',
					time: 10,
					heading: `Scan Error: Not Implemented`,
					message: `Scanned Value: ${barcode.value}  Guessed Type: ${barcode.type}`,
				})
			}
		}

		// @ts-expect-error
		window.processBarcode = processBarcode

		//TODO: need to barcodeoptions from the server
		const companyDefaultBarcodeScannerOptions = standardProfile
		const deviceBarcodeScannerOptions = getObject<ScannerOptions>(localStorage, 'barcodeScannerOptions')

		onBarcodeScan(processBarcode, {
			preamble: deviceBarcodeScannerOptions?.preamble || companyDefaultBarcodeScannerOptions?.preamble,
			postamble: deviceBarcodeScannerOptions?.postamble || companyDefaultBarcodeScannerOptions?.postamble,
			enableHoneywell: deviceBarcodeScannerOptions?.enableHoneywell,
			stop: state.name.startsWith('app.configuration.scanner'),
		})
	})
	// #endregion

	const vehicleLookup = graphql(`
		query LIB_vehicleinventory($filter: VehicleFilter) {
			vehicles(filter: $filter) {
				items {
					vehicleId: id
				}
			}
		}
	`)

	const inventorySerialLookup = graphql(`
		query Inventory($inventorySerialId: Int!) {
			inventorySerial(id: $inventorySerialId) {
				number
				inventory {
					id
					storeId
				}
			}
		}
	`)

	const inventorySkuStoreLookup = graphql(`
		query InventoryBySkuAndStore($sku: Int!, $storeId: Int!) {
			inventoryBySkuAndStore(sku: $sku, storeId: $storeId) {
				id
				vehicleId
				inventoryType {
					vehicle
				}
			}
		}
	`)

	const inventorySkuLookup = graphql(`
		query SkuLookup($input: SkuLookupResolverInput!) {
			skuLookup(input: $input) {
				useInScanner
				relationshipType
				packageQuantity
				sku: inventoryId
				inventories {
					id
					storeId
					vehicleId
					inventoryType {
						vehicle
					}
				}
			}
		}
	`)

	const removeActivityProvider = mediator.provide('activity', (activity: ActivityInput) => {
		try {
			if (!$recentActivity?.length) {
				$recentActivity = []
			}
			// The store's serializer chokes on the Activity class for some reason, so spread it into a plain object
			$recentActivity = sidebar.newActivity(activity).map(a => ({ ...a }))
		} catch (e) {
			// This should only happen if the value is messed up somehow, so reset it
			console.error('Error adding activity to recentActivity store', e)
			$recentActivity = []
		}
	})

	const removeRemoveActivityProvider = mediator.provide('removeActivity', (activityTitle: string) => {
		$recentActivity = $recentActivity.filter(activity => activity.title !== activityTitle)
	})

	const removeHideMessageProvider = mediator.provide('hideMessage', () => {
		alertComponent.hide()
	})

	const removeShowMessageProvider = mediator.provide(
		'showMessage',
		(
			options: {
				type?: Colors
				color?: Colors
				time?: false | number
				heading: string
				message?: string
				dismissable?: boolean
			} = { heading: '' },
		) => {
			const { type, color, time, dismissable, ...otherOptions } = options
			// ractive alert called it "type" but now it's "color" so we should support both. Same with "time" being false or a number
			alertComponent?.show({
				color: color || type || 'secondary',
				time: time === false ? 0 : time,
				...otherOptions,
				dismissable: dismissable !== false, //if they don't pass a value for dismissable, make sure we default it to true
			})
		},
	)

	onDestroy(() => {
		removeShowMessageProvider()
		removeHideMessageProvider()
		removeActivityProvider()
		removeRemoveActivityProvider()
	})
</script>

<Sidebar
	bind:this={sidebar}
	{activeState}
	buildRoute={asr.makePath}
	items={states}
	logoImageUrl="images/EE-Logo-Horizontal.svg"
	collapsedLogoImageUrl="images/RedNut.svg"
	configurationItem={{
		name: 'Configuration',
		icon: 'gear',
		route: 'app.configuration',
		parameters: {},
	}}
	userAccountItem={{
		route: 'app.configuration.my-account',
		parameters: {},
		userName: $session.username,
		email: $session.email,
		firstName: $session.firstName ?? undefined,
		lastName: $session.lastName ?? undefined,
	}}
	bind:expanded={$sidebarExpanded}
	recentActivity={$recentActivity}
	on:logout={() => {
		asr.go('login')
	}}
>
	<uiView
		id="main-app"
		role="main"
		class="pl-0 pr-0"
	></uiView>
</Sidebar>

<div class="d-flex justify-content-center">
	<Alert
		bind:this={alertComponent}
		bind:shown={alertIsShown}
		style="margin-bottom: .5rem; position:fixed; bottom: 0px; left: .5rem; box-shadow: 0 20px 40px -6px grey; z-index: {alertIsShown ? '1100' : '-1'};"
		class="mr-2"
		let:message
	>
		<div class="alert-padding">
			<!-- 			{#if message === 'RELOAD_BUTTON'}
				<Button
					outline
					color="secondary"
					style="overflow-wrap: break-word;"
					class="w-100"
					on:click={() => window.location.reload()}>Click Here to Update</Button
				>
			{:else} -->
			{#each message.split('\n') as line}
				<p>{line}</p>
			{/each}
			<!-- {/if} -->
		</div>
	</Alert>
</div>

{#if stateIsChanging}
	<div class="spinner"><i class="fa fa-spinner fa-spin fa-10x"></i></div>
{/if}
