export default {
    "name": "UpdateUserAccount",
    "kind": "HoudiniMutation",
    "hash": "d6f9f7bc5ae31fce91e6efee4b7a05ffa2cb8eebc08d35f526c74db1576e99be",

    "raw": `mutation UpdateUserAccount($input: UserAccountUpdate!) {
  updateUserAccount(input: $input) {
    ...UserAccountFields
    id
  }
}

fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastAccessDate: lastAccess
  workEmail
  recoveryEmail
  lockNotes
  lastPasswordResetDate
  groups {
    id
  }
  authorizedStores {
    id
  }
  userPermissions {
    id
    value: level
  }
  wageRate {
    id
  }
  salesPerson
  driver
  worker
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateUserAccount": {
                "type": "UserAccount",
                "keyRaw": "updateUserAccount(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastAccessDate": {
                            "type": "DateTime",
                            "keyRaw": "lastAccessDate",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "String",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "lockNotes": {
                            "type": "String",
                            "keyRaw": "lockNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "lastPasswordResetDate": {
                            "type": "DateTime",
                            "keyRaw": "lastPasswordResetDate",
                            "nullable": true,
                            "visible": true
                        },

                        "groups": {
                            "type": "Group",
                            "keyRaw": "groups",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "authorizedStores": {
                            "type": "Store",
                            "keyRaw": "authorizedStores",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userPermissions": {
                            "type": "UserAccountPermission",
                            "keyRaw": "userPermissions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionLevel",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "wageRate": {
                            "type": "WageRate",
                            "keyRaw": "wageRate",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "salesPerson": {
                            "type": "Boolean",
                            "keyRaw": "salesPerson",
                            "visible": true
                        },

                        "driver": {
                            "type": "Boolean",
                            "keyRaw": "driver",
                            "visible": true
                        },

                        "worker": {
                            "type": "Boolean",
                            "keyRaw": "worker",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "UserAccountFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "UserAccountUpdate"
        },

        "types": {
            "UserPermission": {
                "id": "UInt",
                "level": "PermissionLevel"
            },

            "UserAccountUpdate": {
                "apiToken": "String",
                "authorizedStoreIds": "Int",
                "firstName": "String",
                "id": "Int",
                "isDriver": "Boolean",
                "isSalesPerson": "Boolean",
                "isWorker": "Boolean",
                "lastName": "String",
                "lockNotes": "String",
                "name": "String",
                "newPassword": "String",
                "sendPasswordRecoveryToken": "Boolean",
                "status": "UserAccountStatusEnum",
                "userGroupIds": "Int",
                "userPermissions": "UserPermission",
                "wageRateId": "Int",
                "workEmail": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a5fe2bb8d531597b4ceaeb67c209cd7e9ff5fbcbe724d5b00dbee7e50fbc1223";