import type { LIB_partStoreData$result, PartScreenStageOne$result } from '$houdini'
import type { AppContext } from 'types/common'
import type { WritableDeep } from 'type-fest'
import type { OptionValueMap } from 'utility/load-part'
import { getOptionValueMap, loadPart, computeTypeFields } from 'utility/load-part'
import pProps from 'p-props'
import component from './Part.svelte'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import loadModelsForManufacturer, { type PartModel } from 'utility/load-models-for-manufacturer'
import { klona } from 'klona'
import { stringToBoolean } from '@isoftdata/utility-string'
import ObjectMap from 'classes/ObjectMap'
import { getObject } from '@isoftdata/utility-storage'
import loadVehicleModelsForMake from 'utility/load-models-for-make'
import makeCallbackWritable from 'stores/callback-writable'

export default function createState({ mediator, stateRouter, checkSessionPermission }: AppContext) {
	const stateName = 'app.part'

	stateRouter.addState({
		name: stateName,
		route: 'part/:storeId/:inventoryId',
		querystringParameters: ['inventoryId', 'storeId', 'lastSavedTime', 'lastResetTime', 'loadCachedPart'],
		defaultParameters: {
			inventoryId: null,
			storeId() {
				return getSession()?.currentStore?.toString() ?? null
			},
			tab: 'basic',
			lastResetTime: null, // only used to trigger a state reload
			loadCachedPart: 'false',
		},
		canLeaveState(domApi) {
			// @ts-expect-error todo fix asr types like presage
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
			return domApi.canLeaveState()
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(data, { inventoryId, tab, ...params }) {
			if (!checkSessionPermission('PARTS_CAN_VIEW_PARTS')) {
				alert('You do not have permission to view parts.')
				throw {
					redirectTo: {
						name: 'app',
					},
				}
			}
			const storeId = parseInt(params.storeId, 10)
			const loadCachedPart = stringToBoolean(params.loadCachedPart)
			const hasCachedPart = !!getObject(localStorage, 'cachedPart') || false
			// if we don't have a cached part, but we're trying to load one, don't
			if (loadCachedPart && !hasCachedPart) {
				localStorage.removeItem('cachedPart')
				localStorage.removeItem('cachedOptionValues')
				localStorage.removeItem('cachedPartAttachments')
				void mediator.publish('removeActivity', 'Unsaved Part')
				throw {
					redirectTo: {
						name: null,
						params: {
							inventoryId,
							storeId,
							loadCachedPart: false,
						},
					},
				}
			}
			const innodbInventoryid = parseInt(inventoryId, 10) || null

			const { data: settingValuesData } = await settingValuesQuery.fetch({ policy: 'CacheOrNetwork' })

			if (!settingValuesData) {
				throw new Error('No data returned from settingValuesQuery')
			}

			const { settingValues, userSettings } = settingValuesData

			const { part, attachments } = await loadPart(innodbInventoryid, loadCachedPart, settingValues, userSettings, storeId)

			const { data: stageOneData }: { data: WritableDeep<PartScreenStageOne$result> | null } = await stageOneQuery.fetch({ policy: 'CacheOrNetwork' })

			if (!stageOneData) {
				throw new Error('No data returned from stageOneQuery')
			}

			const stageTwoLoads: {
				partStoreData: ReturnType<typeof partStoreDataQuery.fetch>
				trueModels?: Promise<Array<PartModel>>
				assyModels?: Promise<Array<PartModel>>
				vehicleModels?: Promise<Array<string>>
			} = {
				// locations at the store the part is at. Used when adding a new location to the part
				partStoreData: partStoreDataQuery.fetch({
					variables: {
						storeId: part.storeId,
					},
					policy: 'CacheOrNetwork',
				}),
			}

			if (part.manufacturer && part.inventoryTypeId) {
				stageTwoLoads.trueModels = loadModelsForManufacturer(part.manufacturer.id, part.inventoryTypeId)
			}

			if (part.parentManufacturer && part.inventoryType?.setId) {
				stageTwoLoads.assyModels = loadModelsForManufacturer(part.parentManufacturer.id, part.inventoryType.setId)
			}

			if (part.vehicleMake) {
				stageTwoLoads.vehicleModels = loadVehicleModelsForMake(part.vehicleMake)
			}

			const { partStoreData, trueModels, assyModels } = (await pProps(stageTwoLoads)) as {
				partStoreData: { data: LIB_partStoreData$result }
				trueModels?: Array<PartModel>
				assyModels?: Array<PartModel>
				vehicleModels?: Array<string>
			}

			// It's technically possible to have a cached part and not a cached optionValueMap
			const cachedOptionValueMap = getObject<{
				keyParts: Array<'optionId' | 'serialId' | 'serialUuid'>
				entries: ReturnType<OptionValueMap['entries']>
			}>(localStorage, 'cachedOptionValues')
			const optionValueMap = loadCachedPart && cachedOptionValueMap ? new ObjectMap(cachedOptionValueMap.keyParts, cachedOptionValueMap.entries) : getOptionValueMap(part)

			part.glCategory = stageOneData.glCategories.find(cat => cat.id === part.glCategory?.id) ?? null
			part.saleClass = stageOneData.saleClasses.find(saleClass => saleClass.code === part.saleClass?.code) ?? part.saleClass

			part.userStatus ||= null
			part.coreClass ||= null

			const res = {
				clearScreenOnSave: makeCallbackWritable(settingValues.inventory.clearScreenOnSave, async clearScreenOnSave => {
					try {
						await setUserSetting.mutate({ input: { parts: { clearScreenOnSave } } })
					} catch (err) {
						console.error('Error setting user setting', err)
					}
				}),
				settingValues,
				userSettings,
				partStoreLocations: partStoreData.data?.locations ?? [],
				clearLocationsOnCopy: partStoreData.data?.store?.settingValues.parts.clearLocationsOnCopy ?? true,
				origPart: klona(part),
				part,
				attachments,
				optionValueMap,
				loadCachedPart,
				partChanged: loadCachedPart,
				sellPriceClasses: stageOneData.sellPriceClasses.sort((a, b) => {
					const aDisplayName = a.parent?.name ? `${a.parent.name} - ${a.name}` : a.name
					const bDisplayName = b.parent?.name ? `${b.parent.name} - ${b.name}` : b.name
					return aDisplayName.localeCompare(bDisplayName)
				}),
				trueModels: trueModels ?? [],
				assyModels: assyModels ?? [],
				storeId,
				tab,
				typeFields: computeTypeFields(part, settingValues),
				glCategories: stageOneData.glCategories,
				inventoryConditions: stageOneData.inventoryConditions.filter(Boolean),
				inventoryTypeList: stageOneData.inventoryTypeList.map(type => ({
					...type,
					manufacturerList: type?.partManufacturers.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
				})),
				saleClasses: stageOneData.saleClasses,
				userPartStatusList: stageOneData.userPartStatusList,
				vehicleMakes: stageOneData.vehicleMakes,
				// maybe we could filter this better on the server, idk
				vendorList: stageOneData.vendors.items.filter(vendor => vendor?.code ?? vendor?.companyName ?? vendor?.contactName) ?? [],
			}
			return res
		},
	})
}

graphql(`
	fragment FieldFlags on InventoryField {
		editable
		global
		required: requiredToSave
		visible
		logChanges
	}
`)

graphql(`
	fragment InventorySettings on InventorySettingValues {
		clearScreenOnSave
		defaultDaysToReturn
		defaultDaysToReturnCore
		defaultDaysToReturnCoreToVendor
		defaultDaysToReturnToVendor
		defaultLocationName
		defaultNonReplenishablePartsArePublic
		defaultQuantityForMisc
		defaultQuantityForReplenishable
		defaultQuantityForStandard
		defaultReplenishablePartsArePublic
		defaultReturnable
		defaultGlCategoryId
		defaultNonReplenishablePartsArePublic
		defaultReturnableToVendor
		varianceLocationName # todo get for part store
		fields {
			averageCost {...FieldFlags}
			averageCoreCost {...FieldFlags}
			bodyStyle {...FieldFlags}
			buyPackage {...FieldFlags}
			category {...FieldFlags}
			condition {...FieldFlags}
			coreClass {...FieldFlags}
			coreCost {...FieldFlags}
			coreRequired {...FieldFlags}
			coreRequiredToVendor {...FieldFlags}
			cost {...FieldFlags}
			defaultVendor {...FieldFlags}
			deplete {...FieldFlags}
			description {...FieldFlags}
			distributorCorePrice {...FieldFlags}
			distributorPrice {...FieldFlags}
			freezeDate {...FieldFlags}
			glCategory {...FieldFlags}
			interchangeNumber {...FieldFlags}
			internalNotes {...FieldFlags}
			inventoryType {...FieldFlags}
			jobberCorePrice {...FieldFlags}
			jobberPrice {...FieldFlags}
			listPrice {...FieldFlags}
			manufacturer {...FieldFlags}
			maxQuantity {...FieldFlags}
			minQuantity {...FieldFlags}
			model {...FieldFlags}
			oemNumber {...FieldFlags}
			parentManufacturer {...FieldFlags}
			parentModel {...FieldFlags}
			partNumber {...FieldFlags}
			popularityCode {...FieldFlags}
			printTag {...FieldFlags}
			public {...FieldFlags}
			purchaseFactor {...FieldFlags}
			replenish {...FieldFlags}
			retailPrice {...FieldFlags}
			retailCorePrice {...FieldFlags}
			returnable {...FieldFlags}
			returnableToVendor {...FieldFlags}
			saleClassCode {...FieldFlags}
			sellPriceClass {...FieldFlags}
			serialized {...FieldFlags}
			safetyStockPercent {...FieldFlags}
			seasonal {...FieldFlags}
			sellPackage {...FieldFlags}
			shippingHeight {...FieldFlags}
			shippingLength {...FieldFlags}
			shippingMeasurementUnit {...FieldFlags}
			shippingWidth {...FieldFlags}
			side {...FieldFlags}
			singleQuantity {...FieldFlags}
			stockMethod {...FieldFlags}
			stockType {...FieldFlags}
			stockingDays {...FieldFlags}
			tagNumber {...FieldFlags}
			taxable {...FieldFlags}
			typeData1 {...FieldFlags}
			typeData2 {...FieldFlags}
			typeData3 {...FieldFlags}
			typeData4 {...FieldFlags}
			userStatus {...FieldFlags}
			vehicleMake {...FieldFlags}
			vehicleModel {...FieldFlags}
			vendorProductCode {...FieldFlags}
			vendorPopularityCode {...FieldFlags}
			vin {...FieldFlags}
			weight {...FieldFlags}
			weightUnit {...FieldFlags}
			wholesalePrice {...FieldFlags}
			wholesaleCorePrice {...FieldFlags}
			year {...FieldFlags}
		}
	}
`)

const settingValuesQuery = graphql(`
	query LIB_settingValues {
		userSettings {
			parts {
				defaultNewInventoryPrintTags
				showSkuField
				showTagNumberWhenCreating
			}
		}
		settingValues {
			inventory {
				...InventorySettings
			}
			location {
				enforceLocationHierarchy
			}
			accounting {
				useGlAccounting
			}
		}
	}
`)

const stageOneQuery = graphql(`
	query PartScreenStageOne {
		inventoryConditions
		userPartStatusList: userStatuses(type: INVENTORY) {
			type
			status
			trigger
			partStatus
			description
		}
		userVehicleStatusList: userStatuses(type: VEHICLE) {
			type
			status
			trigger
			partStatus
			description
		}
		inventoryTypeList: inventoryTypes(filter: { active: true }) {
			inventoryTypeId: id
			typeSetId: setId
			name
			vehicle
			categories {
				id
				name
				description
			}
			partManufacturers: manufacturers {
				id
				name
				code
			}
			requireSerialization
		}
		glCategories {
			id
			name
		}
		vehicleMakes
		vendors(pagination: { pageSize: 0 }) {
			items {
				id
				# active
				code
				companyName
				contactName
				# stockVendor
			}
		}
		sellPriceClasses {
			id
			name
			parent {
				id
				name
			}
		}
		saleClasses {
			code
			name
		}
	}
`)

const partStoreDataQuery = graphql(`
	query LIB_partStoreData($storeId: Int!) {
		locations(filter: { allowInventory: true, storeIds: [$storeId] }) {
			id
			name
			description
		}
		store(id: $storeId) {
			settingValues {
				parts {
					clearLocationsOnCopy
				}
			}
		}
	}
`)

const setUserSetting = graphql(`
	mutation Parts_SetUserSetting($input: SetUserSettingInput!) {
		setUserSetting(input: $input)
	}
`)
