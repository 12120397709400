<script lang="ts">
	import type { Column } from '@isoftdata/svelte-table'
	import type { PartForClient, SettingValues } from 'utility/load-part'

	import session from 'stores/session'
	import { onMount, tick } from 'svelte'
	import { format as formatCurrency } from '@isoftdata/utility-currency'

	import Table, { Td } from '@isoftdata/svelte-table'
	import { graphql } from '$houdini'

	export let part: PartForClient

	const perPageCount = 10
	const changelogColumns: Array<Column> = [
		{
			property: 'property',
			name: 'Property',
		},
		{
			property: 'previousValue',
			name: 'Old Value',
			sortType: false,
		},
		{
			property: 'newValue',
			name: 'New Value',
			sortType: false,
		},
		{
			property: 'comment',
			name: 'Comment',
			minWidth: '200px',
			sortType: false,
		},
		{
			property: 'user',
			name: 'User',
			sortType: false,
		},
		{
			property: 'date',
			name: 'Date',
			sortType: false,
			defaultSortColumn: true,
			defaultSortDirection: 'DESC',
		},
		{
			property: 'quantity',
			name: 'Quantity',
			numeric: true,
			sortType: false,
		},
		{
			property: 'glCategory',
			name: 'GL Category',
			sortType: false,
		},
		{
			property: 'averageCost',
			name: 'Average Cost',
			numeric: true,
			sortType: false,
		},
		{
			property: 'averageCoreCost',
			name: 'Average Core Cost',
			numeric: true,
			sortType: false,
		},
	]

	type ChangelogRow = {
		id: number | null
		property: string
		changeDate: Date | null
		userName: string
		previousValue: string | null
		newValue: string | null
		comment: string | null
		quantity: number
		averageCost: string
		averageCoreCost: string
		glCategory: string
	}

	let changelogRows: Array<ChangelogRow> = []
	let totalItemsCount = 0
	let currentPageNumber = 1
	let isLoading = false

	async function loadChangeLogHistory(pageNumber = 1) {
		if (!part.innodbInventoryid) {
			return []
		}
		isLoading = true
		const { data } = await changeLogQuery.fetch({
			variables: {
				inventoryId: part.innodbInventoryid,
				pagination: {
					pageNumber,
					pageSize: perPageCount,
				},
			},
		})

		currentPageNumber = pageNumber
		totalItemsCount = data?.inventory.changeLog.totalItems ?? 0
		changelogRows =
			data?.inventory.changeLog.items.map(item => ({
				id: item.id,
				property: item.inventoryField.displayName,
				averageCoreCost: item.averageCoreCost,
				averageCost: item.averageCost,
				comment: item.comment,
				changeDate: new Date(item.timeChanged),
				newValue: item.newValue,
				previousValue: item.previousValue,
				quantity: Number(item.quantity),
				userName: item.user.name,
				glCategory: item.glCategory?.name ?? '',
			})) ?? []
		isLoading = false
	}

	const changeLogQuery = graphql(`
		query InventoryChangeLog($inventoryId: UInt!, $pagination: PaginationOptions) {
			inventory(id: $inventoryId) {
				innodbInventoryId: id
				tagNumber
				changeLog(pagination: $pagination) {
					totalItems
					items {
						id
						averageCoreCost
						averageCost
						comment
						newValue
						previousValue
						quantity
						timeChanged
						glCategory {
							id
							name
						}
						user {
							id
							name
						}
						inventoryField {
							displayName
						}
					}
				}
			}
		}
	`)

	onMount(() => {
		loadChangeLogHistory()
	})
</script>

{#if changelogRows.length}
	<Table
		responsive
		rows={changelogRows}
		columns={changelogColumns}
		{perPageCount}
		{currentPageNumber}
		{totalItemsCount}
		pageChange={({ pageNumber }) => loadChangeLogHistory(pageNumber)}
	>
		{#snippet body({ visibleColumnsCount })}
			{#each changelogRows as row}
				<tr>
					<Td
						class="text-nowrap"
						property="property">{row.property}</Td
					>
					<Td
						class="text-nowrap"
						property="previousValue">{row.previousValue}</Td
					>
					<Td
						class="text-nowrap"
						property="newValue">{row.newValue}</Td
					>
					<Td property="comment">
						{row.comment}
					</Td>
					<Td
						class="text-nowrap"
						property="user">{row.userName}</Td
					>
					<Td
						class="text-nowrap"
						property="date">{row.changeDate?.toLocaleString() ?? 'Now'}</Td
					>
					<Td
						class="text-nowrap"
						property="quantity">{row.quantity}</Td
					>
					<Td
						class="text-nowrap"
						property="glCategory">{row.glCategory}</Td
					>
					<Td
						class="text-nowrap"
						property="averageCost">{formatCurrency(row.averageCost)}</Td
					>
					<Td
						class="text-nowrap"
						property="averageCoreCost">{formatCurrency(row.averageCoreCost)}</Td
					>
				</tr>
			{:else}
				{#if isLoading}
					<tr>
						<td
							colspan={visibleColumnsCount}
							class="text-center"><i class="fas-fa-spinner fa-spin mr-1"></i>Loading...</td
						>
					</tr>
				{:else}
					<tr>
						<td
							colspan={visibleColumnsCount}
							class="text-center">No change log history found</td
						>
					</tr>
				{/if}
			{/each}
		{/snippet}
	</Table>
{/if}
