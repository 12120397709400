import type { AppContext } from 'types/common'
import { type Customer, type CustomerAttachment, defaultCustomer } from 'utility/customer-helper'

import component from './Customer.svelte'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import { v4 as uuid } from '@lukeed/uuid'
import { klona } from 'klona'

export default function createState({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.customer',
		route: 'customer/:customerId',
		querystringParameters: ['customerId', 'lastResetTime'],
		defaultParameters: {
			customerId: null,
			tab: 'address',
			lastResetTime: null,
		},
		canLeaveState(domApi) {
			// @ts-expect-error it's fine
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			if (!domApi?.canLeaveState()) {
				// \n\nYour changes have been saved for later, click "Unsaved Customer" in "Recent Activity" to resume your work.
				return confirm('You have unsaved changes. Are you sure you want to leave?')
			}
			return true
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters) {
			const session = getSession()
			const customerId = parseInt(parameters.customerId, 10) || null

			const { data: initialPageData } = await initialPageLoadQuery.fetch({
				variables: {
					filter: {
						isSalesPerson: true,
					},
				},
				policy: 'CacheOrNetwork',
			})

			if (!initialPageData) {
				throw new Error('Failed to load initial page data')
			}

			let customer: Customer
			let attachments: Array<CustomerAttachment> = []

			if (customerId) {
				const { data } = await customerQuery.fetch({
					variables: {
						customerId,
					},
				})

				if (!data) {
					throw new Error('Customer not found')
				}

				customer = {
					...data.customer,
					taxExemptions: data.customer.taxExemptions.map(exemption => ({
						...exemption,
						uuid: uuid(),
					})),
					balanceStatus: (() => {
						const customerBalance = Number(data.customer.balance)
						const customerAccountLimit = Number(data.customer.accountLimit)

						if (customerBalance > 0 && customerBalance <= customerAccountLimit) {
							return 'OWE_AND_UNDER_LIMIT'
						} else if (customerBalance > 0 && customerBalance >= customerAccountLimit) {
							return 'OWE_AND_OVER_LIMIT'
						} else if (customerBalance === 0) {
							return 'ZERO_BALANCE'
						} else if (customerBalance < 0) {
							return 'CREDIT_BALANCE'
						} else if (!data.customer.active) {
							return 'INACTIVE'
						} else {
							return 'NO_STATUS'
						}
					})(),
					billingAddressValidated: !!data.customer.billingAddress.lastValidated,
					salesOrders: [],
				}
				attachments = data.customer.attachments.map(attachment => ({
					...attachment.file,
					fileId: attachment.file.id,
					customerFileId: attachment.id,
					public: attachment.public,
					mimeType: attachment.file.mimetype,
					rank: attachment.rank ?? 0,
					createdDate: attachment.file.created.toISOString(),
					uuid: uuid(),
				}))
			} else {
				const newCustomer = klona(defaultCustomer)
				const { data } = await customerCustomFieldsQuery.fetch()

				if (!data) {
					throw new Error('Failed to load customer custom fields')
				}

				customer = {
					...newCustomer,
					optionValues: data.customerOptions.map(option => ({
						option,
						value: '',
					})),
				}
			}

			// create new customer
			if (!customerId) {
				customer.store = initialPageData.stores.find(store => store.id === session.currentStore) ?? null
				customer.salesPerson = session.salesPerson ? initialPageData.userAccounts.find(user => user.id === session.id) ?? null : null
			}

			return {
				customer,
				tab: parameters.tab,
				attachments,
				paymentMethods: initialPageData.paymentMethods,
				states: initialPageData.states,
				salesPersonList: initialPageData.userAccounts,
				taxItems: initialPageData.taxItems,
				salesOrderTerms: initialPageData.salesOrderTerms,
				customerTypeList: initialPageData.customerTypes,
				stores: initialPageData.stores,
			}
		},
	})
}

const initialPageLoadQuery = graphql(`
	query InitialCustomerPageLoad($filter: UserAccountFilter) {
		states {
			country
			stateAbbreviation
			stateCode
			stateName
		}
		paymentMethods {
			id
			name
		}
		userAccounts(filter: $filter) {
			id
			name
		}
		taxItems {
			id
			name
		}
		salesOrderTerms {
			id
			name
			code
			customerMemo
		}
		stores {
			id
			name
		}
		customerTypes
	}
`)

const customerQuery = graphql(`
	query CustomerData($customerId: UInt!) {
		customer(id: $customerId) {
			addresses {
				id
				city
				companyName
				contactName
				country
				salesPerson {
					id
					name
				}
				store {
					id
					name
				}
				department
				email
				faxNumber
				primary
				label
				lastValidated
				mobilePhoneNumber
				notes
				phoneNumber
				state
				street
				zip
			}
			contactName
			companyName
			balance
			billingAddress {
				lastValidated
				address1
				address2
				city
				country
				county
				email
				phone
				state
				zip
			}
			email
			faxNumber
			id
			mobilePhoneNumber
			phoneNumber
			salesPerson {
				id
				name
			}
			store {
				id
				name
			}
			lastBillDate
			billDeliveryMethod
			active
			type
			driverLicenseNumber
			webAddress
			dateEntered
			notes
			optionValues {
				option {
					id
					label
					showInCustomerList
					dataType
				}
				value
			}
			attachments {
				id
				public
				rank
				file {
					id
					created
					extension
					mimetype
					name
					hash
					type
					updated
					path
					size
					host
					url
				}
			}
			acceptsBackOrders
			acceptsPartialShipments
			accountLimit
			aging {
				zeroToThirtyDays
				thirtyOneToSixtyDays
				sixtyOneToNinetyDays
				ninetyOneToOneHundredTwentyDays
				overOneHundredTwentyDays
			}
			allowedPaymentMethods {
				id
				name
			}
			cashOnly
			customLaborRate
			laborRateType
			defaultPaymentMethod {
				id
				name
			}
			defaultPricePercentage
			defaultPriceType
			defaultTaxItem {
				id
				name
			}
			defaultTerm {
				id
				name
				code
				customerMemo
			}
			requirePurchaseOrderNumber
			taxExemptions {
				id
				expirationDate
				notes
				number
				state
			}
			blanketPurchaseOrderNumber
			blanketPurchaseOrderNumberExpiration
			salesRegion
			storeRegion
		}
	}
`)

const customerCustomFieldsQuery = graphql(`
	query CustomerCustomFields {
		customerOptions {
			id
			label
			dataType
			showInCustomerList
		}
	}
`)
