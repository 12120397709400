<script lang="ts">
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import { getContext } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import { hasPermission } from 'utility/permission'

	import { type Store, type Customer, type SalesPerson, type State, billDeliveryMethodOptions, type CustomerAlternateAddress } from 'utility/customer-helper'

	import BillingAndAlternateAddress, { type BillingAddress, type AlternateAddress } from 'components/BillingAndAlternateAddress.svelte'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import { getEventValue } from '@isoftdata/browser-event'

	export let customer: Customer
	export let states: State[] = []
	export let stores: Store[] = []
	export let salesPersonList: SalesPerson[] = []
	export let customerChanged: boolean
	export let viewOnlyMode: boolean = false // the permission to edit a customer overall

	let alternateAddressCrudStore = getContext<CrudStore<CustomerAlternateAddress, 'uuid'>>('alternateAddressCrudStore')
	let billingAddress: BillingAddress = formatCustomerBillingAddress(customer)
	let alternateAddresses: AlternateAddress[] = formatCustomerAlternateAddress(customer.addresses)
	let selectedAlternateAddress: CustomerAlternateAddress
	let selectedCustomerAlternateAddress: Customer['addresses'][number] | null

	let customerCanEditBillingAddress = hasPermission('CUSTOMERS_CAN_EDIT_BILLING_ADDRESS', customer.store?.id)
	let customerCanEditAlternateAddress = hasPermission('CUSTOMERS_CAN_EDIT_ADDRESSES', customer.store?.id)

	$: selectedCustomerAlternateAddress = customer.addresses.find(address => address.id === selectedAlternateAddress?.id) ?? null

	function formatCustomerBillingAddress(customer: Customer): BillingAddress {
		return {
			companyName: customer.companyName,
			contactName: customer.contactName,
			street: customer.billingAddress.address1,
			city: customer.billingAddress.city,
			state: customer.billingAddress.state,
			zip: customer.billingAddress.zip,
			country: customer.billingAddress.country,
			email: customer.email,
			faxNumber: customer.faxNumber,
			phoneNumber: customer.phoneNumber,
			mobilePhoneNumber: customer.mobilePhoneNumber,
			validated: customer.billingAddressValidated,
		}
	}

	function formatCustomerAlternateAddress(address: Customer['addresses']): AlternateAddress[] {
		return address.map(address => {
			return {
				id: address.id,
				uuid: uuid(),
				label: address.label,
				primary: address.primary,
				addressData: {
					companyName: address.companyName,
					contactName: address.contactName,
					street: address.street,
					city: address.city,
					state: address.state,
					zip: address.zip,
					country: address.country,
					email: address.email,
					faxNumber: address.faxNumber,
					phoneNumber: address.phoneNumber,
					mobilePhoneNumber: address.mobilePhoneNumber,
					department: address.department,
					notes: address.notes,
					validated: !!address.lastValidated,
				},
			}
		})
	}

	function updateCustomerBillingAddress(updatedBillingAddress: BillingAddress) {
		customer.companyName = updatedBillingAddress.companyName
		customer.contactName = updatedBillingAddress.contactName
		customer.billingAddress = {
			...customer.billingAddress,
			address1: updatedBillingAddress.street,
			city: updatedBillingAddress.city,
			state: updatedBillingAddress.state,
			zip: updatedBillingAddress.zip,
			country: updatedBillingAddress.country,
		}
		customer.faxNumber = updatedBillingAddress.faxNumber
		customer.mobilePhoneNumber = updatedBillingAddress.mobilePhoneNumber
		customer.billingAddressValidated = updatedBillingAddress.validated
		customerChanged = true
	}
</script>

<BillingAndAlternateAddress
	{states}
	{viewOnlyMode}
	canEditBillingAddress={customerCanEditBillingAddress}
	canEditAlternateAddress={customerCanEditAlternateAddress}
	bind:billingAddress
	bind:alternateAddresses
	bind:selectedAlternateAddress
	bind:alternateAddressCrudStore
	bind:hasChanges={customerChanged}
	billingAddressChange={billingAddress => updateCustomerBillingAddress(billingAddress)}
>
	<svelte:fragment slot="billingTopFields">
		<div class="col-12 col-md-6">
			<Select
				required
				label="Bill Delivery Method"
				showEmptyOption={false}
				bind:value={customer.billDeliveryMethod}
				disabled={viewOnlyMode || !customerCanEditBillingAddress}
				on:change={() => {
					customerChanged = true
				}}
			>
				{#each billDeliveryMethodOptions as option}
					<option value={option.value}>{option.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12 col-md-6">
			<Input
				label="Last Bill Date"
				value={customer.lastBillDate ? customer.lastBillDate.toLocaleString() : null}
				disabled
			/>
		</div>
	</svelte:fragment>
	<svelte:fragment slot="billingAdditionalFields">
		<div class="col-12 col-md-6">
			<Input
				readonly
				label="Store Region"
				value={customer.storeRegion ?? ''}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				readonly
				label="Sales Region"
				value={customer.salesRegion ?? ''}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Select
				required
				label="Store"
				showEmptyOption={false}
				value={customer.store?.id ?? null}
				disabled={viewOnlyMode || !customerCanEditBillingAddress}
				on:change={event => {
					const value = getEventValue(event)
					if (value) {
						const id = parseInt(value, 10)
						customer.store = stores.find(store => store.id === id) ?? null
					}
					customerChanged = true
				}}
			>
				{#each stores as store}
					<option value={store.id}>{store.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12 col-md-6">
			<Select
				required
				label="Salesperson"
				showEmptyOption={false}
				value={customer.salesPerson?.id ?? null}
				disabled={viewOnlyMode || !customerCanEditBillingAddress}
				on:change={event => {
					const value = getEventValue(event)
					if (value) {
						const id = parseInt(value, 10)
						customer.salesPerson = salesPersonList.find(salesPerson => salesPerson.id === id) ?? null
					}
					customerChanged = true
				}}
			>
				{#each salesPersonList as salesPerson}
					<option value={salesPerson.id}>{salesPerson.name}</option>
				{/each}
			</Select>
		</div>
	</svelte:fragment>
	<svelte:fragment slot="alternateAdditionalFields">
		<div class="col-12 col-md-6">
			<Select
				showEmptyOption
				label="Store"
				emptyText="Use Billing Default"
				value={selectedCustomerAlternateAddress?.store?.id ?? null}
				disabled={viewOnlyMode || !customerCanEditAlternateAddress}
				on:change={event => {
					const value = getEventValue(event)
					if (value && selectedCustomerAlternateAddress) {
						const id = parseInt(value, 10)
						selectedCustomerAlternateAddress.store = stores.find(store => store.id === id) ?? null
						const alternateAddressForUpdate = {
							...selectedAlternateAddress,
							storeId: selectedCustomerAlternateAddress.store?.id ?? null,
							salesPersonId: selectedCustomerAlternateAddress.salesPerson?.id ?? null,
						}
						alternateAddressCrudStore.update(alternateAddressForUpdate)
					}
					customerChanged = true
				}}
			>
				{#each stores as store}
					<option value={store.id}>{store.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12 col-md-6">
			<Select
				showEmptyOption
				label="Salesperson"
				emptyText="Use Billing Default"
				value={selectedCustomerAlternateAddress?.salesPerson?.id ?? null}
				disabled={viewOnlyMode || !customerCanEditAlternateAddress}
				on:change={event => {
					const value = getEventValue(event)
					if (value && selectedCustomerAlternateAddress) {
						const id = parseInt(value, 10)
						selectedCustomerAlternateAddress.salesPerson = salesPersonList.find(salesPerson => salesPerson.id === id) ?? null
						const alternateAddressForUpdate = {
							...selectedAlternateAddress,
							storeId: selectedCustomerAlternateAddress.store?.id ?? null,
							salesPersonId: selectedCustomerAlternateAddress.salesPerson?.id ?? null,
						}
						alternateAddressCrudStore.update(alternateAddressForUpdate)
					}
					customerChanged = true
				}}
			>
				{#each salesPersonList as salesPerson}
					<option value={salesPerson.id}>{salesPerson.name}</option>
				{/each}
			</Select>
		</div>
	</svelte:fragment>
</BillingAndAlternateAddress>
