<script
	lang="ts"
	context="module"
>
	import type { AddressData } from './AddressFields.svelte'
	import type { Merge } from 'type-fest'

	export type BillingAddress = AddressData

	export type AlternateAddress = {
		id: number | null
		uuid: string
		label: string
		primary: boolean
		addressData: Merge<AddressData, { department: string; notes: string }>
	}

	//end of module section
</script>

<script lang="ts">
	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { tick } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import { getEventChecked } from '@isoftdata/browser-event'

	import AddressValidatorModal from './AddressValidatorModal.svelte'
	import AddressFields, { type State } from './AddressFields.svelte'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import TextArea from '@isoftdata/svelte-textarea'

	const newAlternateAddressData: AlternateAddress['addressData'] = Object.freeze({
		companyName: '',
		contactName: '',
		street: '',
		city: '',
		state: null,
		zip: '',
		country: '',
		email: '',
		faxNumber: '',
		phoneNumber: '',
		mobilePhoneNumber: '',
		department: '',
		notes: '',
		validated: false,
	})

	export let billingAddress: BillingAddress
	export let alternateAddresses: AlternateAddress[]
	export let selectedAlternateAddress: AlternateAddress = alternateAddresses[0]
	export let alternateAddressCrudStore = makeCrudStore<AlternateAddress, 'uuid'>('uuid')
	export let billingAddressChange: ((billingAddress: BillingAddress) => void) | undefined
	export let states: State[] = []
	export let hasChanges: boolean
	export let viewOnlyMode: boolean = false
	export let canEditBillingAddress: boolean = true
	export let canEditAlternateAddress: boolean = true

	let addressLabelInput: HTMLInputElement | undefined
	let addressValidatorModalComponent: AddressValidatorModal
	let editAddressLabel: boolean = false
	let addNewAlternateAddress: boolean = false
	let showNotesField: boolean = false
	let matchBillingAddress: boolean = false
	let selectedAlternateAddressHasChanged: boolean = false
	let selectedAlternateAddressUuid: string
	let alternateAddressDefaultStateMap: Map<string, AlternateAddress> = new Map()
	let validateAddressType: 'BILLING' | 'ALTERNATE' = 'ALTERNATE'

	if (alternateAddresses.length === 0) {
		alternateAddresses.push({
			id: null,
			uuid: uuid(),
			label: '',
			primary: false,
			addressData: {
				...klona(newAlternateAddressData),
				department: '',
				notes: '',
			},
		})
		addNewAlternateAddress = true
		alternateAddressCrudStore.create(alternateAddresses[0])
		selectedAlternateAddress = alternateAddresses[0]
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		saveSelectedAlternateAddressDefaultState()
	} else {
		selectedAlternateAddress = alternateAddresses.find(address => address.primary) || alternateAddresses[0]
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		saveSelectedAlternateAddressDefaultState()
	}

	$: alternateAddressGroupByPrimary = alternateAddresses.reduce(
		(acc: { primary: AlternateAddress[]; other: AlternateAddress[] }, address) => {
			if (address.primary) {
				acc.primary.push(address)
			} else {
				acc.other.push(address)
			}
			return acc
		},
		{ primary: [], other: [] },
	)
	$: showNotesField = !!selectedAlternateAddress.addressData.notes
	$: addressTitleRequired = selectedAlternateAddress.id === null ? !dequal(selectedAlternateAddress.addressData, newAlternateAddressData) : true

	function addressIsReadyForValidation(addressData: AddressData): boolean {
		return !!addressData.street && !!addressData.city && !!addressData.state && !!addressData.zip && !!addressData.country
	}

	function saveSelectedAlternateAddressDefaultState() {
		const existingDefaultState = alternateAddressDefaultStateMap.get(selectedAlternateAddress.uuid)
		if (existingDefaultState) {
			return
		}
		alternateAddressDefaultStateMap.set(selectedAlternateAddress.uuid, klona(selectedAlternateAddress))
	}

	function checkIfSelectedAlternateAddressHasChanged(alteranteAddress: AlternateAddress) {
		const foundAddressInCrudStore =
			alternateAddressCrudStore.createdValues.find(address => address.uuid === alteranteAddress.uuid) || alternateAddressCrudStore.updatedValues.find(address => address.uuid === alteranteAddress.uuid)
		selectedAlternateAddressHasChanged = !!foundAddressInCrudStore
	}

	function resetSelectedAlternateAddress() {
		// No id means that the address has not been saved yet, so we can just remove it from the list
		if (!selectedAlternateAddress.id) {
			if (confirm(`The address ${selectedAlternateAddress.label} has not been saved yet, reseting it will mean deleting it.\nDo you want to proceed?`)) {
				// remove it from the crud store
				// the alternate address will now be removed from the crudstore.createdValues list but it will now be added to the crudstore.deletedValues list
				// make sure to filter out the crudstore.deletedValues to exclude the addresses with null id when sending the list to the API
				deleteAlternateAddress()
				return
			}
		}
		const defaultState = alternateAddressDefaultStateMap.get(selectedAlternateAddress.uuid)
		if (!defaultState) {
			return
		}
		selectedAlternateAddress = klona(defaultState)
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		const selectedAlternateAddressIndex = alternateAddresses.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
		alternateAddresses[selectedAlternateAddressIndex] = selectedAlternateAddress
		selectedAlternateAddressHasChanged = false
	}

	async function newAlternateAddress() {
		addNewAlternateAddress = true
		selectedAlternateAddress = {
			id: null,
			uuid: uuid(),
			label: '',
			primary: false,
			addressData: {
				...klona(newAlternateAddressData),
				department: '',
				notes: '',
			},
		}
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		alternateAddresses.push(selectedAlternateAddress)
		alternateAddresses = alternateAddresses
		alternateAddressCrudStore.create(selectedAlternateAddress)
		await tick()
		addressLabelInput?.select()
	}

	function deleteAlternateAddress() {
		alternateAddressCrudStore.delete(selectedAlternateAddress)
		alternateAddresses = alternateAddresses.filter(address => address.uuid !== selectedAlternateAddress.uuid)
		if (alternateAddresses.length === 0) {
			newAlternateAddress()
			selectedAlternateAddressHasChanged = false
		} else {
			selectedAlternateAddress = alternateAddresses[0]
			selectedAlternateAddressUuid = selectedAlternateAddress.uuid
			addNewAlternateAddress = false
			checkIfSelectedAlternateAddressHasChanged(selectedAlternateAddress)
		}
	}

	async function makeAddressPrimary(event: Event) {
		const isPrimary = getEventChecked(event)
		// if the value is set to false, just set the primary address to false and return
		if (!isPrimary) {
			/*
			 * There is a visual bug on updating the option group on the select when we dont have a primary address after the change
			 * We suspect that it is due to that split second where the DOM could not find the selected uuid in the option when we "move" the selected address from the primary group to the other group
			 * To work around this, we set the selectedAlternateAddressUuid to an empty string first, then update the primary address, then set the selectedAlternateAddressUuid back, which is still the same
			 */
			selectedAlternateAddress.primary = false
			selectedAlternateAddressUuid = ''

			const selectedAlternateAddressIndex = alternateAddresses.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
			alternateAddresses[selectedAlternateAddressIndex].primary = selectedAlternateAddress.primary
			alternateAddressCrudStore.update(selectedAlternateAddress)

			selectedAlternateAddressHasChanged = true
			hasChanges = true

			await tick()
			selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		} else {
			// find the previous primary address
			const previousPrimaryAddress = alternateAddresses.find(address => address.primary)

			// confirm if the user wants to change the primary address
			if (previousPrimaryAddress) {
				if (confirm(`The current primary address is ${previousPrimaryAddress.label}.\nDo you want to make ${selectedAlternateAddress.label} the primary address?`)) {
					previousPrimaryAddress.primary = false
					selectedAlternateAddress.primary = true

					const previousPrimaryAddressIndex = alternateAddresses.findIndex(address => address.uuid === previousPrimaryAddress.uuid)
					const selectedAlternateAddressIndex = alternateAddresses.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
					alternateAddresses[previousPrimaryAddressIndex] = previousPrimaryAddress
					alternateAddresses[selectedAlternateAddressIndex] = selectedAlternateAddress

					alternateAddressCrudStore.update(previousPrimaryAddress)
					alternateAddressCrudStore.update(selectedAlternateAddress)
					selectedAlternateAddressHasChanged = true
					hasChanges = true
				} else {
					selectedAlternateAddress.primary = false
				}
			} else {
				if (confirm(`Do you want to make ${selectedAlternateAddress.label} the primary address?`)) {
					selectedAlternateAddress.primary = true

					const selectedAlternateAddressIndex = alternateAddresses.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
					alternateAddresses[selectedAlternateAddressIndex] = selectedAlternateAddress

					alternateAddressCrudStore.update(selectedAlternateAddress)
					selectedAlternateAddressHasChanged = true
					hasChanges = true
				} else {
					selectedAlternateAddress.primary = false
				}
			}
		}
	}
</script>

<div class="card-deck">
	<div class="card">
		<div class="card-header d-flex justify-content-between align-items-center">
			<h5 class="mb-0">Billing Address</h5>
			{#if !billingAddress.companyName || !billingAddress.contactName}
				<small>* Company Name or Contact Name is required</small>
			{/if}
		</div>
		<div class="card-body">
			<div class="form-row">
				<slot name="billingTopFields" />
				<hr class="w-100 mb-1" />
			</div>
			<AddressFields
				{states}
				disableAllFields={viewOnlyMode || !canEditBillingAddress}
				bind:addressData={billingAddress}
				addressChange={() => {
					billingAddress.validated = false
					billingAddressChange?.(billingAddress)
					hasChanges = true
				}}
			>
				<svelte:fragment slot="additionalFields">
					<slot name="billingAdditionalFields" />
				</svelte:fragment>
			</AddressFields>
		</div>
		<div class="card-footer text-right">
			<Button
				outline
				size="sm"
				iconClass={billingAddress.validated ? 'check' : 'magnifying-glass'}
				disabled={viewOnlyMode || billingAddress.validated || matchBillingAddress || !addressIsReadyForValidation(billingAddress)}
				on:click={() => {
					validateAddressType = 'BILLING'
					addressValidatorModalComponent.open(billingAddress)
				}}
			>
				{billingAddress.validated ? 'Validated' : 'Validate...'}
			</Button>
		</div>
	</div>
	<div class="card">
		<div class="card-header">
			<div class="d-flex justify-content-between align-items-center">
				<h5 class="mb-0">Alternate Address</h5>
				<Checkbox
					inline
					label="Match Billing"
					disabled={viewOnlyMode || !canEditAlternateAddress}
					bind:checked={matchBillingAddress}
					on:change={() => {
						if (matchBillingAddress) {
							selectedAlternateAddress = {
								...selectedAlternateAddress,
								addressData: {
									...billingAddress,
									department: '',
									notes: '',
								},
							}
							const selectedAlternateAddressIndex = alternateAddresses.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
							alternateAddresses[selectedAlternateAddressIndex] = selectedAlternateAddress
							alternateAddressCrudStore.update(selectedAlternateAddress)
							hasChanges = true
						}
					}}
				/>
			</div>
		</div>
		<div class="card-body">
			<div class="form-row">
				<div class="col-12 col-md-6">
					{#if editAddressLabel || addNewAlternateAddress}
						<Input
							required={addressTitleRequired}
							id="addressLabelInput"
							label="Address Title"
							validation={{
								value: selectedAlternateAddress.label,
							}}
							disabled={viewOnlyMode || !canEditAlternateAddress}
							bind:value={selectedAlternateAddress.label}
							bind:input={addressLabelInput}
							on:blur={() => {
								if (selectedAlternateAddress.label) {
									alternateAddressCrudStore.update(selectedAlternateAddress)
									addNewAlternateAddress = false
									editAddressLabel = false
									hasChanges = true
									selectedAlternateAddressHasChanged = true
								}
							}}
						/>
					{:else}
						<Select
							hintClickable
							hint="Edit Title"
							label="Alternate Address"
							showEmptyOption={false}
							hintClass={canEditAlternateAddress && !viewOnlyMode ? '' : 'd-none'}
							bind:value={selectedAlternateAddressUuid}
							on:hintClick={async () => {
								editAddressLabel = true
								await tick()
								addressLabelInput?.select()
							}}
							on:change={() => {
								const selectedAddress = alternateAddresses.find(address => address.uuid === selectedAlternateAddressUuid)
								if (selectedAddress) {
									selectedAlternateAddress = selectedAddress
									checkIfSelectedAlternateAddressHasChanged(selectedAlternateAddress)
									// This means that the size of the Map could be as big as the number of alternate addresses, not sure if this is a good idea
									saveSelectedAlternateAddressDefaultState()
								}
							}}
						>
							{#each Object.entries(alternateAddressGroupByPrimary) as [key, value]}
								<optgroup label={key === 'primary' ? 'Primary Address' : 'Other Addresses'}>
									{#each value as address (address.uuid)}
										<option value={address.uuid}>{address.label}</option>
									{:else}
										<option disabled>No Address</option>
									{/each}
								</optgroup>
							{/each}
						</Select>
					{/if}
				</div>
				<div class="col-auto d-flex align-items-end">
					<Checkbox
						label="Primary Address"
						checked={selectedAlternateAddress.primary}
						disabled={viewOnlyMode || !selectedAlternateAddress.label || !canEditAlternateAddress}
						on:change={makeAddressPrimary}
					/>
				</div>
			</div>
			<hr class="w-100 mb-1" />
			<AddressFields
				{states}
				addressType="ALTERNATE"
				disableAllFields={matchBillingAddress || viewOnlyMode || !canEditAlternateAddress}
				bind:addressData={selectedAlternateAddress.addressData}
				addressChange={() => {
					alternateAddressCrudStore.update(selectedAlternateAddress)
					selectedAlternateAddressHasChanged = true
					hasChanges = true
				}}
			>
				<svelte:fragment slot="additionalFields">
					<div class="col-12">
						<Input
							label="Department"
							bind:value={selectedAlternateAddress.addressData.department}
							disabled={matchBillingAddress || viewOnlyMode || !canEditAlternateAddress}
							on:change={() => {
								alternateAddressCrudStore.update(selectedAlternateAddress)
								selectedAlternateAddressHasChanged = true
								hasChanges = true
							}}
						/>
					</div>
					<slot name="alternateAdditionalFields" />
					{#if showNotesField}
						<div class="col-12">
							<TextArea
								label="Notes"
								bind:value={selectedAlternateAddress.addressData.notes}
								disabled={matchBillingAddress || viewOnlyMode || !canEditAlternateAddress}
								on:change={() => {
									alternateAddressCrudStore.update(selectedAlternateAddress)
									selectedAlternateAddressHasChanged = true
									hasChanges = true
								}}
							/>
						</div>
					{/if}
				</svelte:fragment>
			</AddressFields>
			{#if !showNotesField && !viewOnlyMode && canEditAlternateAddress}
				<Button
					size="sm"
					color="link"
					class="p-0"
					on:click={() => {
						showNotesField = true
					}}
				>
					Add Notes
				</Button>
			{/if}
		</div>
		<div class="card-footer d-flex justify-content-between">
			<div>
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					disabled={viewOnlyMode || !canEditAlternateAddress}
					on:click={newAlternateAddress}
				>
					New Address
				</Button>
				<Button
					outline
					size="sm"
					color="danger"
					iconClass="trash"
					disabled={viewOnlyMode || !canEditAlternateAddress}
					on:click={() => {
						if (confirm('Are you sure you want to delete this address?')) {
							deleteAlternateAddress()
							hasChanges = true
						}
					}}
				>
					Delete...
				</Button>
				{#if selectedAlternateAddressHasChanged && !viewOnlyMode}
					<Button
						outline
						color="secondary"
						size="sm"
						iconClass="broom"
						on:click={() => {
							resetSelectedAlternateAddress()
						}}
					>
						Reset...
					</Button>
				{/if}
			</div>
			<div>
				<Button
					outline
					color="primary"
					size="sm"
					iconClass={selectedAlternateAddress.addressData.validated ? 'check' : 'magnifying-glass'}
					disabled={viewOnlyMode ||
						!!selectedAlternateAddress.addressData.validated ||
						(selectedAlternateAddress.addressData.validated && !selectedAlternateAddressHasChanged) ||
						matchBillingAddress ||
						!canEditAlternateAddress ||
						!addressIsReadyForValidation(selectedAlternateAddress.addressData)}
					on:click={() => {
						validateAddressType = 'ALTERNATE'
						addressValidatorModalComponent.open(selectedAlternateAddress.addressData)
					}}
				>
					{selectedAlternateAddress.addressData.validated ? 'Validated' : 'Validate...'}
				</Button>
			</div>
		</div>
	</div>
</div>

<AddressValidatorModal
	{states}
	bind:this={addressValidatorModalComponent}
	selectedAddressData={addressData => {
		if (validateAddressType === 'BILLING') {
			billingAddress = addressData
			billingAddressChange?.(billingAddress)
		} else {
			selectedAlternateAddress.addressData = {
				...addressData,
				department: selectedAlternateAddress.addressData.department,
				notes: selectedAlternateAddress.addressData.notes,
			}
			alternateAddressCrudStore.update(selectedAlternateAddress)
			selectedAlternateAddressHasChanged = true
		}

		hasChanges = true
	}}
></AddressValidatorModal>
