import { storesForLoginQuery } from 'common/graphql-queries'
import Login from './Login.svelte'
import type { AppContext } from 'types/common'
import sessionStore, { getSession } from 'stores/session'
import { ServerInformationStore, graphql } from '$houdini'

const getServerInformationQuery: ServerInformationStore = graphql`
	query ServerInformation {
		serverInformation {
			schemaVersion
			releaseVersionNumber
		}
	}
`

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'login',
		route: 'login',
		querystringParameters: [],
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: Login, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		async resolve(_data, _parameters) {
			const oldSession = getSession()

			if (oldSession?.authToken) {
				try {
					//Do NOT clear out the session before this API call, or it will fail
					await mediator.publish('graphqlFetch', 'mutation CloseSessionMutation {closeSession}')
				} catch (e) {
					console.error('Failed to close session', e)
				}
			}
			//Alright, it's safe to clear the session out now
			sessionStore.set({})
			const lastUsername = localStorage.getItem('lastUsername')

			interface StoreForLogin {
				id: number
				code: string
				name: string
			}

			const { storesForLogin } = (await mediator.publish('graphqlFetch', storesForLoginQuery)) as Awaited<{
				storesForLogin: StoreForLogin[]
			}>

			const lastStoreId = parseInt(localStorage.getItem('lastStoreId') ?? '', 10) || storesForLogin[0]?.id

			async function getServerInformation() {
				const { data } = await getServerInformationQuery.fetch()

				return data?.serverInformation ?? { schemaVersion: 'unknown', releaseVersionNumber: 'unknown' }
			}

			const res = {
				storeList: storesForLogin || [],
				selectedStoreId: lastStoreId || storesForLogin[0].id,
				username: lastUsername ?? '',
				isLoading: false,
				serverInformation: getServerInformation(),
			}

			return res
		},
	})
}
