<script lang="ts">
	import type { InventoryLocations$result } from '$houdini'
	type InventoryLocation = InventoryLocations$result['inventory']['locations'][0]

	import { Table, Td } from '@isoftdata/svelte-table'
	import { graphql } from '$houdini'
	import Icon from '@isoftdata/svelte-icon'

	const locationsQuery = graphql(`
		query InventoryLocations($inventoryId: UInt!) {
			inventory(id: $inventoryId) {
				locations {
					location {
						name
					}
					quantity
				}
			}
		}
	`)

	export let inventoryId: number

	let locations: InventoryLocation[] | Promise<InventoryLocation[]> = []

	async function loadLocations(inventoryId: number) {
		return inventoryId ? ((await locationsQuery.fetch({ variables: { inventoryId }, policy: 'CacheOrNetwork' }))?.data?.inventory?.locations ?? []) : []
	}

	function loadAndSetLocations(inventoryId: number) {
		locations = loadLocations(inventoryId)
	}

	$: loadAndSetLocations(inventoryId)
</script>

{#await locations}
	<Icon
		icon="spinner"
		class="fa-xl fa-spin"
	/>
{:then loadedLocations}
	{@const flatInventoryLocations = loadedLocations.map(location => {
		return {
			name: location.location?.name ?? 'Unknown',
			quantity: location.quantity,
		}
	})}
	<Table
		rows={flatInventoryLocations}
		columns={[
			{ property: 'name', name: 'name' },
			{ property: 'quantity', name: 'Quantity' },
		]}
		responsive
	>
		{#snippet children({ row: inventoryLocation })}
			{@const { name, quantity } = inventoryLocation}
			<tr>
				<Td property="name">{name}</Td>
				<Td property="quantity">{parseFloat(quantity) ? parseFloat(quantity).toString() : '0'}</Td>
			</tr>
		{/snippet}
	</Table>
{:catch error}
	<p>Error loading part locations.</p>
{/await}
