import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/VendorCustomFields'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class VendorCustomFieldsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "VendorCustomFieldsStore",
			variables: false,
		})
	}
}

export async function load_VendorCustomFields(params) {
	await initClient()

	const store = new VendorCustomFieldsStore()

	await store.fetch(params)

	return {
		VendorCustomFields: store,
	}
}
