export default {
    "name": "MyAccountUpdateSessionUser",
    "kind": "HoudiniMutation",
    "hash": "5de05270c4a69e62838888ed7318e3e86a24a0c2f747402230cb957e4d138b88",

    "raw": `mutation MyAccountUpdateSessionUser($input: SessionUserUpdate!) {
  updateSessionUser(input: $input) {
    id
    name
    firstName
    lastName
    recoveryEmail
    workEmail
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateSessionUser": {
                "type": "UserAccount",
                "keyRaw": "updateSessionUser(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "String",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "SessionUserUpdate"
        },

        "types": {
            "SessionUserUpdate": {
                "currentPassword": "String",
                "firstName": "String",
                "lastName": "String",
                "newPassword": "String",
                "recoveryEmail": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a1af8e0d030b983f57a1bff2a3810342659e36238c3bbc45504639276c691254";